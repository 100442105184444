import { Chip } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

export const recipes = [
  // {
  //   title: 'Featured Recipe',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Gordon Ramsey',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Recipe title',
  //   date: 'Nov 11',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Featured Recipe',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Gordon Ramsey',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Recipe title',
  //   date: 'Nov 11',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Featured Recipe',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Gordon Ramsey',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Recipe title',
  //   date: 'Nov 11',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Featured Recipe',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Gordon Ramsey',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Recipe title',
  //   date: 'Nov 11',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
];
