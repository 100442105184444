import React, { FC, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';

import { Avatar, Box, Center, Container, Divider, List, Loader, Stack, useMantineTheme } from '@mantine/core';
import { IconUserCircle } from '@tabler/icons';
import { useStore } from '../../../../index';

export interface InfiniteListProps extends Omit<HTMLAttributes<HTMLUListElement>, 'css'> {
  /** Optional tag to render the component as a different HTML tag */
  tag?: any;
  id?: string;
  /* A callback function that will make an api call to load the next batch of items. */
  onLoad: () => void;
  /* Manages the visibility of the spinner when the API call is resolving. */
  isLoading: boolean;
  /* The elements to be shown in the list */
  items: ReactNode[];
}

export const InfiniteList: FC<InfiniteListProps> = (props) => {
  const theme = useMantineTheme();
  const { isLoading, onLoad, items } = props;
  const [currentTenant] = useStore((state) => [state.tenant]);
  const listEnd = useRef<HTMLDivElement>(null);
  const currentTopItemRef = useRef<HTMLLIElement>(null);
  const firstNew = useRef<HTMLLIElement>(null);
  const prevLength = useRef(items.length);
  const newLength = useRef(0);
  const onLoadRef = useRef(onLoad);

  onLoadRef.current = onLoad;

  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    firstNew.current?.scrollIntoView();
  }, [items.length]);

  const topObserver = new IntersectionObserver(
    (entries) => {
      const topEntry = entries[0];
      if (topEntry.isIntersecting) {
        onLoadRef.current();
      }
    },
    {
      threshold: 1,
    }
  );

  useEffect(() => {
    listEnd.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

    if (currentTopItemRef.current) {
      topObserver.observe(currentTopItemRef.current);
    }
    return () => {
      if (currentTopItemRef.current) {
        topObserver.unobserve(currentTopItemRef.current);
      }
    };
  }, []);

  if (items.length !== prevLength.current) {
    prevLength.current = newLength.current;
  }
  newLength.current = items.length;

  const getRef = (index: number) => {
    if (index === newLength.current - 1) {
      return newBottom;
    } else if (index === items.length - prevLength.current - 1 && isLoading && items.length !== prevLength.current) {
      return firstNew;
    } else {
      return null;
    }
  };

  const newBottom = useRef<HTMLLIElement>(null);
  let prevBottom: Element | null;

  const messageList = items.map((item: JSX.Element, i: number) => {
    return item.key.toString().startsWith('date') ? (
      // <img class="mantine-1trwvlz mantine-Avatar-image" src="https://www.courtney.cloud/tenants/03837405-48e7-49b9-b78d-5d0f96928e9a/59bee9aa-848e-4757-beef-b34a083c7e3e/20230208_WBqbgMhK)7"></img>
      <>
        <List.Item
          icon={<Divider w={'100%'} />}
          id={i.toString()}
          key={i}
          ref={i === 0 ? currentTopItemRef : getRef(i)}
          role="article"
          // sx={{ `li:nth-child(${i})`: {listStyleType: 'none'} }}
        >
          {''}
        </List.Item>
        <Center mb={theme.spacing.xl}>
          <Divider label={item} labelPosition="center" w={'100%'} />
        </Center>
      </>
    ) : (
      <List.Item
        icon={
          <Avatar
            component="a"
            href="#"
            src={`https://www.courtney.cloud/public/${currentTenant.tenantId}/${
              item.props.children.props.senderId.split('/').splice(-1)[0]
            }/profile`}
          />
        }
        id={i.toString()}
        key={i}
        ref={i === 0 ? currentTopItemRef : getRef(i)}
        role={`https://www.courtney.cloud/public/${currentTenant.tenantId}/${
          item.props.children.props.senderId.split('/').splice(-1)[0]
        }/profile`}
      >
        {item}
      </List.Item>
    );
  });

  const bottomObserver = new IntersectionObserver(
    (entries) => {
      const entry = entries[0];
      setAtBottom(entry.isIntersecting);
    },
    {
      threshold: 0,
    }
  );

  useEffect(() => {
    if (atBottom && listEnd.current) {
      // listEnd.current.scrollIntoView({ block: 'nearest' });
    }
    prevBottom = newBottom.current;

    if (prevBottom) {
      bottomObserver.unobserve(prevBottom);
    }

    if (newBottom.current) {
      bottomObserver.observe(newBottom.current);
      prevBottom = newBottom.current;
    }

    return () => {
      if (prevBottom) {
        bottomObserver.unobserve(prevBottom);
      }
    };
  }, [items.length]);

  return (
    <Box mb={theme.spacing.lg}>
      <List size="sm" spacing={theme.spacing.md}>
        {isLoading && (
          <List.Item>
            <Loader />
          </List.Item>
        )}
        {messageList}
        <div ref={listEnd} />
      </List>
    </Box>
  );
};

export default InfiniteList;
