import { useState, useMemo } from 'react';
import {
  createStyles,
  Box,
  Header,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Flex,
  Grid,
  Tabs,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { Footer } from '../../Base/Footer/Footer';

import {
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  ConfirmationDialog,
  CurrentTimeIndicator,
  DateNavigator,
  DayView,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import { appointments } from './appointments';

export default function CalendarWeekView() {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [appointmentState, setAppointmentState] = useState(appointments);

  const currentDateChange = (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let data = appointmentState;
    if (added) {
      const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
      data = [...data, { id: startingAddedId, ...added }];
    }
    if (changed) {
      data = data.map((appointment) =>
        changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment
      );
    }
    if (deleted !== undefined) {
      data = data.filter((appointment) => appointment.id !== deleted);
    }
    console.log(data);
    setAppointmentState(data);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box maw="100%" sx={{ position: 'absolute', overflow: 'auto' }}>
        <Container fluid maw="100%" p={0} m={0} sx={{ position: 'relative' }}>
          <Box maw="100%" h="100%" sx={{ overflow: 'auto' }}>
            <Scheduler data={appointmentState}>
              <ViewState currentDate={currentDate} onCurrentDateChange={currentDateChange} />
              <EditingState onCommitChanges={commitChanges} />
              <IntegratedEditing />
              <MonthView />
              <WeekView startDayHour={7} endDayHour={24} cellDuration={60} />
              <DayView startDayHour={0} endDayHour={24} cellDuration={60} />
              <Toolbar />
              <ViewSwitcher />
              <DateNavigator />
              <TodayButton />
              <ConfirmationDialog />
              <Appointments />
              <AppointmentTooltip showOpenButton showDeleteButton />
              <CurrentTimeIndicator shadePreviousCells={true} shadePreviousAppointments={true} updateInterval={10000} />
              <AppointmentForm />
            </Scheduler>
          </Box>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
}
