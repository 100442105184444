import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Menu,
  Card,
  Center,
  Grid,
  Group,
  Container,
  Image,
  Space,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus, IconHeart, IconShare } from '@tabler/icons';

interface FeaturedBookProps {
  cookbook: {
    date: string;
    description: string;
    image: string;
    imageLabel: string;
    title: string;
    author: string;
    avatar: any;
  };
}

export default function FeaturedCookbook({ cookbook }: FeaturedBookProps) {
  return (
    <Card withBorder shadow="xs" radius="md" mb={5}>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>{cookbook.title}</Text>
          <Group>
            <ActionIcon>
              <IconHeart />
            </ActionIcon>
            <ActionIcon>
              <IconShare />
            </ActionIcon>
          </Group>
        </Group>
      </Card.Section>

      <Card.Section mt={5}>
        <Grid grow gutter="xs">
          <Grid.Col md={7} xs={12} px="md">
            <Text lineClamp={5} my="sm" color="dimmed" size="sm">
              {cookbook.description}
            </Text>
            <Center sx={{ justifyContent: 'flex-start' }}>
              <Button size="xs" leftIcon={cookbook.avatar} radius="xl" variant="outline" color="blue.7">
                {cookbook.author}
              </Button>
              <Space w="sm" />
              <Text color="dimmed" size="sm">
                {cookbook.date}
              </Text>
            </Center>
          </Grid.Col>
          <Grid.Col md={5} xs={12} py={0}>
            <Image height={200} src={cookbook.image} alt={cookbook.imageLabel} />
          </Grid.Col>
        </Grid>
      </Card.Section>
      <Card.Section withBorder></Card.Section>
    </Card>
  );
}
