import { useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Paper,
  Space,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical, IconHelp, IconX } from '@tabler/icons';

export default function DetailsForm({ form }) {
  const theme = useMantineTheme();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const fields = form.values.info.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided) => (
        <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
          <Center>
            <Box {...provided.dragHandleProps} py="auto" sx={{ textAlign: 'end' }}>
              <IconGripVertical style={{ minHeight: xsBreak ? 24 : 18, minWidth: xsBreak ? 24 : 18 }} />
            </Box>
            <TextInput mr={15} placeholder="Key" {...form.getInputProps(`info.${index}.key`)} />
            <TextInput placeholder="Value" {...form.getInputProps(`info.${index}.value`)} />
            <ActionIcon onClick={() => form.removeListItem('info', index)}>
              <IconX size={18} />
            </ActionIcon>
          </Center>
        </Group>
      )}
    </Draggable>
  ));

  return (
    <>
      <Grid gutter={50}>
        <Grid.Col sm={5} xs={12}>
          <Center inline>
            <Title size="h3">Title</Title>
            <ActionIcon color={theme.colors.teal[6]} variant="transparent">
              <IconHelp size={20} />
            </ActionIcon>
          </Center>
          <TextInput mt={5} mb={10} placeholder="My Magical Recipe" {...form.getInputProps('title')} />
          <Center inline>
            <Title size="h3">Description</Title>
            <ActionIcon color={theme.colors.teal[6]} variant="transparent">
              <IconHelp size={20} />
            </ActionIcon>
          </Center>
          <Textarea
            mt={1}
            autosize
            minRows={2}
            maxRows={4}
            placeholder="Describe how magical this recipe is..."
            {...form.getInputProps('description')}
          />
        </Grid.Col>
        <Grid.Col span="auto">
          <Divider orientation="vertical" />
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <Center inline>
            <Title size="h3">Recipe Details</Title>
            <ActionIcon color={theme.colors.teal[6]} variant="transparent">
              <IconHelp size={20} />
            </ActionIcon>
          </Center>
          <Center>
            <DragDropContext
              onDragEnd={({ destination, source }) =>
                form.reorderListItem('info', { from: source.index, to: destination.index })
              }
            >
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Center>
          <Center>
            <Button
              size="xs"
              variant="outline"
              mt={15}
              onClick={() => form.insertListItem('info', { key: '', value: '' })}
            >
              add field
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
      <Box
        bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]}
        mt={25}
        mb={10}
        pb={15}
        sx={{ borderRadius: 10 }}
      >
        <Center>
          <Title color="dimmed" size="h4" mt={15}>
            Preview
          </Title>
        </Center>
        <Grid>
          <Grid.Col span={12}></Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <Container>
              <Paper shadow="md" p={15}>
                <Center>
                  <Title color="dimmed" size="h3">
                    {form.values.title ?? 'My Magical Recipe'}
                  </Title>
                </Center>
                <Space h="md" />
                <Text color="dimmed">{form.values.description ?? 'Describe how magical this recipe is...'}</Text>
              </Paper>
            </Container>
          </Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <Container fluid>
              <Card withBorder shadow="sm">
                <Grid p={5} mb={15} justify="space-around">
                  {form.values.info.map((item) => (
                    <Grid.Col span="content">
                      <Text fw={700} color="dimmed" sx={{ whiteSpace: 'nowrap' }}>
                        {item.key}
                      </Text>
                      <Divider color="dimmed" />
                      <Text color="dimmed" sx={{ whiteSpace: 'nowrap' }}>
                        {item.value}
                      </Text>
                    </Grid.Col>
                  ))}
                </Grid>
              </Card>
            </Container>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
