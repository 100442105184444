import React, { memo, ChangeEventHandler } from 'react';
import { Handle, Position } from 'reactflow';

interface NodeData {
  color: string;
}

interface VertexNodeProps {
  data: {
    sex: string;
    firstName: string;
    lastName: string;
    birth?: Date;
    death?: Date;
    color: string;
  };
}

const VertexNode = memo(({ data }: VertexNodeProps) => {
  return (
    <div style={{ borderRadius: '50%' }}>
      <Handle id="marriage" type="source" position={Position.Right} style={{ opacity: 0 }} />
      <span style={{ opacity: 0 }}></span>
      <Handle type="source" position={Position.Top} id="top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="left" style={{ opacity: 0 }} />
      {/* <Handle id="a" type="source" position={Position.Bottom} style={{ opacity: 0 }} /> */}
    </div>
  );
});

export default VertexNode;
