import { useState } from 'react';
import { Box, Center, Container, Grid, Skeleton, Space, createStyles, useMantineTheme } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { IconBold, IconItalic, IconUnderline, IconStrikethrough, IconHighlight, IconCode } from '@tabler/icons';

export default function PersonalizeForm({ form }) {
  const theme = useMantineTheme();
  const content = form.values.html;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    onUpdate: ({ editor }) => {
      form.setFieldValue('html', editor.getHTML());
    },
    content,
  });

  return (
    <>
      <Box my={25}>
        <Skeleton animate={false} height={50} width="50%" radius="sm" />
        <Skeleton animate={false} height={18} mt={15} width="75%" radius="sm" />
        <Skeleton animate={false} height={18} mt={2} width="75%" radius="sm" />
        <Skeleton animate={false} height={18} mt={2} width="33%" radius="sm" />
      </Box>
      <RichTextEditor editor={editor}>
        <RichTextEditor.Toolbar>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>

      <Box my={25}>
        <Grid gutter="xl">
          <Grid.Col sm={6} xs={12}>
            <Skeleton animate={false} height={50} mb={15} width="50%" radius="sm" />
            <Grid gutter="xs">
              <Grid.Col span={2}>
                <Skeleton animate={false} height={24} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton animate={false} height={24} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton animate={false} height={24} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
                <Skeleton animate={false} height={24} mt={5} radius="sm" />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <Skeleton animate={false} height={50} mb={15} width="50%" radius="sm" />
            <Skeleton animate={false} height={75} radius="sm" />

            <Skeleton animate={false} height={100} mt={2} radius="sm" />
            <Skeleton animate={false} height={75} mt={2} radius="sm" />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
