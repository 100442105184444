// import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useCallback } from 'react';
import { Box, Image, Paper, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Carousel, Embla } from '@mantine/carousel';

export default function PhotoThumbCarousel({ images }) {
  const theme = useMantineTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [emblaThumb, setEmblaThumb] = useState<Embla | null>(null);
  const slides = Array.from(Array(images.length).keys());
  const mdBreak = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const onThumbClick = useCallback(
    (index: number) => {
      if (!embla || !emblaThumb) {
        return;
      }
      if (emblaThumb.clickAllowed()) {
        embla.scrollTo(index);
      }
    },
    [embla, emblaThumb]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumb) return;
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumb.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumb, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
    embla.on('reInit', onSelect);
  }, [embla, onSelect]);

  const imageByIndex = (index) => {
    return images[index % images.length];
  };

  return (
    <Paper withBorder shadow="sm" bg={theme.colors.dark[1]}>
      <div style={{ width: '100%', display: 'block', position: 'relative', aspectRatio: '16 / 9', overflow: 'hidden' }}>
        <div style={{ position: 'absolute', top: 0, left: 0, minWidth: '100%', minHeight: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Carousel withControls={false} getEmblaApi={setEmbla} p={2}>
              {slides.map((index) => (
                <Carousel.Slide key={index} sx={{ flex: '0 0 100%', minWidth: 0, width: '100%' }}>
                  <Image
                    src={imageByIndex(index)}
                    sx={{
                      aspectRatio: '16 / 9',
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <Carousel
        withControls={false}
        dragFree={true}
        containScroll="keepSnaps"
        getEmblaApi={setEmblaThumb}
        bg={theme.colors.dark[2]}
      >
        {slides.map((index) => (
          <Carousel.Slide
            key={index}
            sx={{
              flex: '0 0 25%',
              minWidth: 0,
              position: 'relative',
              margin: 0,
              padding: 2,
              opacity: selectedIndex == index ? 1 : '50%',
              zIndex: 100,
              '&:hover': { cursor: 'pointer' },
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'block',
                position: 'relative',
                aspectRatio: '16 / 9',
                overflow: 'hidden',
              }}
            >
              <div style={{ position: 'absolute', top: 0, left: 0, minWidth: '100%', minHeight: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box bg={theme.colors.dark[8]}>
                    <button
                      onClick={() => onThumbClick(index)}
                      type="button"
                      style={{
                        border: 0,
                        width: '100%',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <Image
                        src={imageByIndex(index)}
                        alt="Your alt text"
                        sx={{
                          height: '100%',
                          width: '100%',
                          zIndex: 103,
                          '&:hover': { cursor: 'pointer' },
                          objectFit: 'cover',
                        }}
                      />
                    </button>
                  </Box>
                </div>
              </div>
            </div>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Paper>
  );
}
