import React, { useEffect } from 'react';

import { Authenticator, Heading, Theme, ThemeProvider, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import BaseContainer from '../../Base/Container/BaseContainer';
import {
  createStyles,
  Box,
  Button,
  Menu,
  Grid,
  Center,
  Checkbox,
  Container,
  Flex,
  Image,
  Text,
  Avatar,
  Space,
  Title,
  TextInput,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconCloud, IconAt, IconKey } from '@tabler/icons';

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'Login', // Button label
  'Sign in to your account': 'Welcome Back!',
  Password: 'Password', // Password label
  'Create Account': 'Register', // Tab header
  Email: 'Email',

  'Enter your Email': 'Enter your email',
  'Enter your email': 'Email',
  'Please confirm your Password': 'Confirm your password',
  'Enter your Password': 'Enter your password',
  'Send code': 'Reset my password',
  'Back to Sign In': 'Back to Login',
});

const LoginComponent: React.FC = () => {
  const theme = useMantineTheme();
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const { tokens } = useTheme();

  let from = location.state ? location.state.from.pathname : '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  const customTheme: Theme = {
    name: 'Custom Login Theme',
    tokens: {
      colors: {
        brand: {
          primary: tokens.colors.blue[80],
        },
      },
      components: {
        button: {
          primary: {
            backgroundColor: {
              value: theme.colors.blue[6],
            },
            _hover: {
              backgroundColor: {
                value: theme.colors.blue[4],
              },
            },
            _active: {
              backgroundColor: {
                value: theme.colors.blue[4],
              },
            },
          },
        },
        tabs: {
          item: {
            _focus: {
              color: {
                value: theme.colors.blue[6],
              },
            },
            _hover: {
              color: {
                value: theme.colors.blue[4],
              },
            },
            _active: {
              borderColor: {
                value: theme.colors.blue[6],
              },
              color: {
                value: theme.colors.blue[6],
              },
            },
          },
        },
      },
    },
  };

  const useStyles = createStyles((theme) => ({
    Button: {
      backgroundColor: theme.colors.dark[6],
    },
  }));

  const authComponents: DefaultComponents = {
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color="dimmed">&copy; All Rights Reserved</Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Box>
            <Space h="xl" />
            <Flex justify="center" align="center">
              <Avatar size="lg" radius="xl" color={theme.colors.teal[6]} sx={{ m: 15 }}>
                <IconCloud />
              </Avatar>
              <Space w="sm" />
              <Title>Login</Title>
            </Flex>
          </Box>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button onClick={toResetPassword} size="sm" variant="subtle">
              Forgot your password?
            </Button>
            <Space h="md" />
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Box>
            <Space h="xl" />
            <Flex justify="center" align="center">
              <Avatar size="lg" radius="xl" color={theme.colors.teal[6]} sx={{ m: 15 }}>
                <IconCloud />
              </Avatar>
              <Space w="sm" />
              <Title>Register</Title>
            </Flex>
          </Box>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button onClick={toSignIn} size="sm" variant="subtle">
              Back to Sign In
            </Button>
            <Space h="md" />
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Enter your email to reset your password
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            A password reset code has been sent to your email
          </Heading>
        );
      },
      Footer() {
        return <></>;
      },
    },
  };

  return (
    <Box mt="50px">
      <Flex align="center" justify="center" mih="100%">
        <ThemeProvider theme={customTheme}>
          <Authenticator
            loginMechanisms={['email']}
            signUpAttributes={['given_name', 'family_name']}
            components={authComponents}
            socialProviders={['amazon', 'facebook', 'google']}
          ></Authenticator>
        </ThemeProvider>
      </Flex>
    </Box>
  );
};

export default LoginComponent;
