import { Diagram, LayeredDigraphLayout, ObjectData, Node, Size, GraphObject, GraphLinksModel } from 'gojs';
import GenogramLayout from './GenogramLayout';

/* { key: 0, n: 'Aaron', s: 'M', m: -10, f: -11, ux: 1, a: ['C', 'F', 'K'] }, */

// interface CreateDiagramProps {
//   key: string;
//   firstName: string;
//   lastName: string;
//   sex: 'F' | 'M';
//   motherId: string;
//   fatherId: string;
//   wifeId: string;
//   husbandId: string;
//   attributes: string[];
// }

export interface CreateDiagramProps {
  key: string;
  name: string;
  sex: 'F' | 'M';
  mother: string;
  father: string;
  wife: string;
  husband: string;
  image: string;
}

export const createDiagram = (people: CreateDiagramProps[], direction = 90) => {
  const nodeWidth = 250;
  const nodeHeight = 200;
  const layerSpacing = 30;
  const columnSpacing = 10;

  const diagram = GraphObject.make(Diagram, {
    initialAutoScale: Diagram.Uniform,
    'undoManager.isEnabled': true,
    layout: GraphObject.make(GenogramLayout, {
      isInitial: false,
      isOngoing: false,
      direction,
      layerSpacing,
      columnSpacing,
    }),
  });

  diagram.nodeTemplate = GraphObject.make(Node, 'Auto', { desiredSize: new Size(nodeWidth, nodeHeight) });

  setupDiagram(diagram, people);
  diagram.layout.doLayout(diagram);
  diagram.layoutDiagram(true);
  return diagram;
};

// create and initialize the Diagram.model given an array of node data representing people
export const setupDiagram = (diagram, array) => {
  diagram.model = new GraphLinksModel({
    // declare support for link label nodes
    linkLabelKeysProperty: 'labelKeys',
    // this property determines which template is used
    nodeCategoryProperty: 's',
    // if a node data object is copied, copy its data.a Array
    copiesArrays: true,
    // create all of the nodes for people
    nodeDataArray: array,
  });
  setupMarriages(diagram);
  setupParents(diagram);
  diagram.layout.doLayout(diagram);
  // diagram.layoutDiagram(true);
};

const findMarriage = (diagram, a, b) => {
  // A and B are node keys
  const nodeA = diagram.findNodeForKey(a);
  const nodeB = diagram.findNodeForKey(b);
  if (nodeA !== null && nodeB !== null) {
    const it = nodeA.findLinksBetween(nodeB); // in either direction
    while (it.next()) {
      const link = it.value;
      // Link.data.category === "Marriage" means it's a marriage relationship
      if (link.data !== null && link.data.category === 'Marriage') return link;
    }
  }
  return null;
};

// now process the node data to determine marriages
const setupMarriages = (diagram) => {
  const model = diagram.model;
  const nodeDataArray = model.nodeDataArray;
  for (let i = 0; i < nodeDataArray.length; i++) {
    const data = nodeDataArray[i];
    const key = data.key;
    let wf = data.wife;
    if (wf !== undefined) {
      if (typeof wf === 'string') wf = [wf];
      for (let j = 0; j < wf.length; j++) {
        const wife = wf[j];
        const wdata = model.findNodeDataForKey(wife);
        if (key === wife || !wdata || wdata.sex !== 'F') {
          console.log('cannot create Marriage relationship with self or unknown person ' + wife);
          continue;
        }
        const link = findMarriage(diagram, key, wife);
        if (link === null) {
          // add a label node for the marriage link
          const mlab: ObjectData = { s: 'LinkLabel' };
          model.addNodeData(mlab);
          // add the marriage link itself, also referring to the label node
          const mdata = { from: key, to: wife, labelKeys: [mlab.key], category: 'Marriage' };
          model.addLinkData(mdata);
        }
      }
    }
    let hb = data.husband;
    if (hb !== undefined) {
      if (typeof hb === 'string') hb = [hb];
      for (let j = 0; j < hb.length; j++) {
        const husband = hb[j];
        const hdata = model.findNodeDataForKey(husband);
        if (key === husband || !hdata || hdata.sex !== 'M') {
          console.log('cannot create Marriage relationship with self or unknown person ' + husband);
          continue;
        }
        const link = findMarriage(diagram, key, husband);
        if (link === null) {
          // add a label node for the marriage link
          const mlab: ObjectData = { s: 'LinkLabel' };
          model.addNodeData(mlab);
          // add the marriage link itself, also referring to the label node
          const mdata = { from: key, to: husband, labelKeys: [mlab.key], category: 'Marriage' };
          model.addLinkData(mdata);
        }
      }
    }
  }
};

// process parent-child relationships once all marriages are known
const setupParents = (diagram) => {
  const model = diagram.model;
  const nodeDataArray = model.nodeDataArray;
  for (let i = 0; i < nodeDataArray.length; i++) {
    const data = nodeDataArray[i];
    const key = data.key;
    const mother = data.mother;
    const father = data.father;
    if (mother !== undefined && father !== undefined) {
      const link = findMarriage(diagram, mother, father);
      if (link === null) {
        // or warn no known mother or no known father or no known marriage between them
        console.log('unknown marriage: ' + mother + ' & ' + father);
        continue;
      }
      const mdata = link.data;
      if (mdata.labelKeys === undefined || mdata.labelKeys[0] === undefined) continue;
      const mlabkey = mdata.labelKeys[0];
      const cdata = { from: mlabkey, to: key };
      diagram.model.addLinkData(cdata);
    }
  }
};
