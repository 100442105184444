import {
  Badge,
  Container,
  createStyles,
  Group,
  Modal,
  Text,
  Title,
  TextInput,
  Button,
  Image,
  useMantineTheme,
} from '@mantine/core';
import { formatDate } from './formatDate';
import React from 'react';

const insertDateHeaders = (messageItems) => {
  const items = [...messageItems];
  const dateMap = {};
  let messageDate;
  let dateCount = 0;

  messageItems.forEach((m, i) => {
    if (!m || !m.content) {
      return; // not a message
    }
    if (i === 0) {
      items.splice(0, 0, <Badge key={`date${i.toString()}`}>{formatDate(m.createdTimestamp)}</Badge>);
      dateMap[new Date(m.createdTimestamp).toLocaleDateString()] = 1;
      dateCount++;
    } else if (
      new Date(m.createdTimestamp).toLocaleDateString() !== messageDate &&
      !dateMap[new Date(m.createdTimestamp).toLocaleDateString()]
    ) {
      items.splice(i + dateCount, 0, <Badge key={`date${i.toString()}`}>{formatDate(m.createdTimestamp)}</Badge>);
      messageDate = new Date(m.createdTimestamp).toLocaleDateString();
      dateMap[new Date(m.createdTimestamp).toLocaleDateString()] = 1;
      dateCount++;
    }
  });
  return items;
};

export default insertDateHeaders;
