import { useState, useMemo, useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, useLocation, Route, Routes } from 'react-router-dom';
import { useStore } from './index';
import { Authenticator } from '@aws-amplify/ui-react';
import { MantineThemeProvider } from 'Theme';
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';

import AuthGuard from 'Base/Auth/AuthGuard';
import LoginComponent from 'Views/Auth/Login';
import Welcome from 'Views/Auth/Welcome';

import BaseContainer from './Base/Container/BaseContainer';
import Home from './Views/Home/Home';
import Feed from './Views/Feed/Feed';
import Header from './Base/Header/Header';
import ContentContainer from './Base/Container/ContentContainer';
import Recipes from './Views/Recipes/Recipes';
import Photos from './Views/Photos/Photos';
import FamilyTree from './Views/Family/FamilyTree';
import Calendar from './Views/Calendar/Calendar';
import Explore from './Views/Recipes/Tabs/Explore/Explore';
import FoodSearch from 'Views/Recipes/Tabs/Nutrition/FoodSearch';
import MyRecipes from './Views/Recipes/Tabs/MyRecipes/MyRecipes';
import AddCookbook from './Views/Recipes/Views/AddCookbook';
import AddRecipe from './Views/Recipes/Views/AddRecipe';

import MessagingProvider from './Views/Chat/providers/MessagingProvider';
import { UserPermissionProvider } from './Views/Chat/providers/UserPermissionProvider';
import ChannelMessages from 'Views/Chat/Messages/ChannelMessages/ChannelMessages';
import DirectMessages from 'Views/Chat/Messages/DirectMessages/DirectMessages';

import { getUser } from './api/user-service';

const routingPrefix = process.env.REACT_APP_ROUTING_PREFIX || '/';

export default function Main() {
  const setUser = useStore((state) => state.setUser);

  getUser().then((data) => {
    console.log('data', data[0]);
    setUser(data[0]);
  });

  return (
    <BrowserRouter basename={routingPrefix}>
      <Authenticator.Provider>
        <MantineProvider>
          <MantineThemeProvider>
            <UserPermissionProvider>
              <MessagingProvider>
                <NotificationsProvider autoClose={5000}>
                  <BaseContainer>
                    <ModalsProvider>
                      <Header />
                      <ContentContainer>
                        <Routes>
                          <Route element={<AuthGuard />}>
                            <Route path="/" element={<Home />}>
                              <Route index element={<Navigate to="/feed" replace />} />
                              <Route path="feed" element={<Feed />} />
                              <Route path="recipes" element={<Recipes />}>
                                <Route index element={<Navigate to="explore" replace />} />
                                <Route path="explore" element={<Explore />} />
                                <Route path="nutrition" element={<FoodSearch />} />
                                <Route path="personal" element={<MyRecipes />} />
                              </Route>
                              <Route path="recipes/new" element={<AddRecipe />} />
                              <Route path="recipes/cookbook/new" element={<AddCookbook />} />
                              <Route path="photos" element={<Photos />} />
                              <Route path="family" element={<FamilyTree />} />
                              <Route path="calendar" element={<Calendar />} />
                              <Route path="/welcome" element={<Welcome />} />
                              <Route path="channel/:id" element={<ChannelMessages />} />
                              <Route path="conversation/:id" element={<DirectMessages />} />
                            </Route>
                            <Route path="*" element={<Navigate to="/" replace />} />
                          </Route>
                          // Auth Routes
                          <Route path="/login" element={<LoginComponent />} />
                        </Routes>
                      </ContentContainer>
                    </ModalsProvider>
                  </BaseContainer>
                </NotificationsProvider>
              </MessagingProvider>
            </UserPermissionProvider>
          </MantineThemeProvider>
        </MantineProvider>
      </Authenticator.Provider>
    </BrowserRouter>
  );
}
