import { useState } from 'react';
import {
  createStyles,
  Box,
  Button,
  Menu,
  Group,
  Card,
  Center,
  Container,
  Divider,
  Space,
  Title,
  Avatar,
  Paper,
  Text,
  Table,
  TextInput,
  UnstyledButton,
  useMantineTheme,
  Grid,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';

import { NutrientIds, formatNutrient } from './utils';
export const usdaURL = 'https://api.nal.usda.gov/fdc/v1';

export const FoodSearchResult = (props) => {
  return (
    <Card h="100%" key={props.searchResult.fdcId} sx={{ m: 1 }}>
      <Container p={15}>
        <Card.Section>
          <Center>
            <Title size="h3">{props.searchResult.description}</Title>
          </Center>
          {props.searchResult.ingredients && (
            <span>
              Ingredients: {props.searchResult.ingredients}
              <br />
            </span>
          )}

          <Space h="lg" />
          {props.searchResult.foodNutrients && (
            <Table sx={{ mt: 1 }}>
              <thead>
                <tr>
                  <td>Nutrient</td>
                  <td>Amount (per 100 grams)</td>
                </tr>
              </thead>
              <tbody>
                {props.searchResult.foodNutrients
                  .filter((foodNutrient) =>
                    [NutrientIds.ENERGY, NutrientIds.PROTEIN, NutrientIds.CARBOHYDRATE, NutrientIds.FAT].includes(
                      foodNutrient.nutrientId
                    )
                  )
                  .sort((a, b) => ('' + a.nutrientName).localeCompare(b.nutrientName))
                  .map((foodNutrient) => {
                    const nutrient = formatNutrient(foodNutrient);
                    console.log(nutrient);
                    return (
                      <tr key={nutrient.nutrientId}>
                        <td>{nutrient.nutrientName}</td>
                        <td>
                          {nutrient.value} {nutrient.unitName}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {props.showSelect && !props.fdcIds?.includes(props.searchResult.fdcId) && (
            <Button onClick={() => props.selectFood()}>Select food</Button>
          )}
          {props.showSelect && props.fdcIds?.includes(props.searchResult.fdcId) && (
            <span style={{ color: 'green' }}>Food selected</span>
          )}
        </Card.Section>
      </Container>
    </Card>
  );
};

export default function FoodSearch(props) {
  const [foodKeyword, setFoodKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageChangeError, setPageChangeError] = useState('');

  const handleSubmitSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSearchResults([]);
    await searchFood(foodKeyword);
    setLoading(false);
  };

  const handleSubmitPageChange = async (event) => {
    event.preventDefault();
    if (pageNumber > totalPages) {
      setPageChangeError('error! page ' + pageNumber + ' does not exist.');
      return;
    }
    setPageChangeError('');
    setLoading(true);
    setSearchResults([]);
    await searchFood(foodKeyword, 9, pageNumber);
    setLoading(false);
  };

  const searchFood = async (keyword, pageSize = 9, pageNumber = 1) => {
    const searchResponse = await searchUSDAFood(keyword, pageSize, pageNumber);
    // const searchResponse = hardcodedResponse;
    // const searchedFoods: any = searchResponse.foods;
    const searchedFoods = searchResponse?.foods ? searchResponse.foods : [];

    if (searchedFoods.length === 0) {
      setSearchResults([]);
      return;
    }

    setTotalPages(searchResponse.totalPages);
    setPageNumber(searchResponse.currentPage);

    setSearchResults(searchedFoods);
  };

  const searchUSDAFood = async (keyword, pageSize, pageNumber) => {
    const response = await fetch(usdaURL + '/foods/search?api_key=DEMO_KEY', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: keyword,
        pageSize: pageSize,
        pageNumber: pageNumber >= 0 ? pageNumber : 1,
        dataType: ['Foundation', 'SR Legacy'],
      }),
    });

    return await response.json();
  };

  const handleFoodKeywordChange = (event) => {
    setFoodKeyword(event.target.value);
  };

  const handlePageNumberChange = (event) => {
    setPageNumber(parseInt(event.target.value));
  };

  return (
    <Container size="lg">
      <Container size="sm" m="15">
        <Title size="h5">
          Use the search box below to lookup ingredients. We will get the most current nutrition data available for any
          ingredients or foods we find. Soon you'll be able to combine ingredient's nutritional data to generate
          Nutrition Fact Labels for your recipes!
        </Title>
      </Container>
      <Container fluid m="15">
        <Center my={15}>
          <TextInput
            id="food-query"
            size="lg"
            placeholder="Search for food or ingredients"
            value={foodKeyword}
            icon={<IconSearch />}
            onChange={handleFoodKeywordChange}
            sx={{ width: '50%' }}
          />

          <Button
            variant="outline"
            size="md"
            mx={15}
            onClick={() => {
              searchFood(foodKeyword);
            }}
          >
            Submit
          </Button>
        </Center>
      </Container>

      {searchResults && searchResults.length > 0 && (
        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          <Grid>
            {searchResults.map((searchResult) => (
              <Grid.Col xs={12} sm={6} md={4}>
                <FoodSearchResult
                  showSelect={props.showSelect}
                  selectFood={() => props.selectFood(searchResult)}
                  fdcIds={props.fdcIds}
                  searchResult={searchResult}
                />
              </Grid.Col>
            ))}
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
              <Box style={{ color: 'red' }}>{pageChangeError}</Box>
            </Box>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
