import {
  createStyles,
  ActionIcon,
  Box,
  Button,
  Menu,
  Card,
  Center,
  Grid,
  Group,
  Container,
  Image,
  Space,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconDots, IconSearch, IconFileZip, IconEye, IconTrash, IconPlus, IconHeart, IconShare } from '@tabler/icons';
interface RecipeCardProps {
  recipe: {
    description: string;
    image: string;
    imageLabel: string;
    title: string;
    author: string;
    avatar: any;
  };
}

export default function RecipeCard({ recipe }: RecipeCardProps) {
  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>{recipe.title}</Text>
          <Menu withinPortal position="bottom" shadow="sm">
            <Menu.Target>
              <ActionIcon>
                <IconDots size={16} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<IconFileZip size={14} />}>Download zip</Menu.Item>
              <Menu.Item icon={<IconEye size={14} />}>Preview</Menu.Item>
              <Menu.Item icon={<IconTrash size={14} />} color="red">
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Card.Section>

      <Text lineClamp={3} mt="sm" color="dimmed" size="sm">
        {recipe.description}
      </Text>

      <Card.Section mt="sm">
        <Image height={200} src={recipe.image} alt={recipe.imageLabel} />
      </Card.Section>

      <Card.Section inheritPadding mt="sm" pb="md">
        <Group position="apart">
          <ActionIcon>
            <IconHeart />
          </ActionIcon>
          <ActionIcon>
            <IconShare />
          </ActionIcon>
        </Group>
      </Card.Section>
    </Card>
  );
}
