import { Container, createStyles, Text, Title, TextInput, Button, Image } from '@mantine/core';
import { useStore } from '../../index';
// import { ChimeApi } from '../../api/chime-service';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xl * 2,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]}`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing.xl,
    },
  },

  image: {
    maxWidth: '40%',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  body: {
    paddingRight: theme.spacing.xl * 4,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: 'flex',
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: '100%',
    flex: '1',
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export default function Feed() {
  const { classes } = useStyles();
  const [currentTenant, user] = useStore((state) => [state.tenant, state.user]);
  // const chime = new ChimeApi(currentTenant.appInstanceArn, user.userId);

  // const handleCreateChannel = () => {
  //   const test = chime.createChannel({ name: 'test-channel' });
  //   console.log(test);
  // };

  // const handleListChannels = () => {
  //   const test = chime.listChannels();
  //   console.log(test);
  // };

  const handleTest = () => {};
  return (
    <Container size="xl">
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Title className={classes.title}>Wait a minute...</Title>
          <Text weight={500} size="lg" mb={5}>
            This app is still under construction!
          </Text>
          <Text size="sm" color="dimmed">
            Seeing as you've already created an account and logged in (without permission), I guess you might as well
            start poking around. I'm always in need of some Beta testers.
          </Text>
        </div>
        <Image src={process.env.PUBLIC_URL + '/office.svg'} className={classes.image} />
      </div>
      {/* <Button onClick={handleListChannels}>Test</Button> */}
    </Container>
  );
}
