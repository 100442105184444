import { useState, FormEvent, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  Collapse,
  Menu,
  Group,
  Image,
  Center,
  Grid,
  Chip,
  Container,
  Divider,
  Flex,
  Modal,
  Stack,
  Title,
  Avatar,
  Paper,
  Select,
  SimpleGrid,
  Text,
  ThemeIcon,
  TextInput,
  createStyles,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconUpload, IconPhoto, IconX, IconAlertCircle, IconCrop } from '@tabler/icons';
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from '@mantine/dropzone';
import { PhotoCrop } from '../Photos/Cropping/PhotoCrop';
import { useFileChange } from './utils/use-file-change';
import { uploadToS3 } from '../../api/upload-service';
import { useStore } from '../../index';

const CustomAlert = ({ files, setAlert }) => {
  const [opened, setOpened] = useState(false);
  let text;
  if (files[0].errors.some((e) => e.code == 'file-invalid-type')) {
    text = 'This is not a valid image. Look for files ending with ".png", ".gif",".jpeg", ".svg", or ".webp".';
  } else {
    text = JSON.stringify(files[0].errors);
  }
  return (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Whoops!"
      color="red"
      withCloseButton
      onClose={() => setAlert(<></>)}
    >
      {text}
      <Button variant="default" size="xs" onClick={() => setOpened((o) => !o)}>
        View error
      </Button>
      <Collapse in={opened}>{JSON.stringify(files, null, 2)}</Collapse>
    </Alert>
  );
};

interface selectedFile {
  name: string;
  image: string;
  croppedImage?: string;
  content: File;
}

export function UploadButton({ form, aspect = 1, shape = 'round', multi = false, profile = false }) {
  const theme = useMantineTheme();
  const currentTenant = useStore((state) => state.tenant);
  const { fileError, fileName, fileContents, fileType, fileDispatch, handleFileChange } = useFileChange();
  const [s3FileUrl, setS3FileUrl] = useState('');
  const [opened, setOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState({} as selectedFile);
  const [alert, setAlert] = useState(<></>);

  const previews = form.values.files.map((file, index) => {
    const name = file.name;
    const cropButton = () => {
      setSelectedFile(file);
      setOpened(true);
    };
    return (
      <Center>
        {shape == 'round' ? (
          <Avatar key={index} src={file.croppedImage ?? file.image} h="100px" w="100px" sx={{ borderRadius: '50%' }} />
        ) : (
          <Image key={index} mah="100px" maw="100px" src={file.croppedImage ?? file.image} />
        )}
        <Stack spacing={2} ml={5}>
          <ActionIcon variant="filled" color="blue" onClick={cropButton}>
            <IconCrop />
          </ActionIcon>
          <ActionIcon
            variant="outline"
            color="red.8"
            onClick={() => {
              removeFile(file);
            }}
          >
            <IconX />
          </ActionIcon>
        </Stack>
      </Center>
    );
  });

  const removeFile = (file) => {
    const filteredFiles = form.values.files.filter((f) => f.image != file.image);
    form.setFieldValue('files', filteredFiles);
  };

  const dropFiles = (uploadedFiles) => {
    uploadedFiles.map((file) => {
      const formatted = { name: file.name, image: URL.createObjectURL(file), content: file };
      if (multi) {
        form.insertListItem('files', formatted);
      } else {
        form.setFieldValue('files', [formatted]);
        setOpened(true);
        setSelectedFile(formatted);
      }
    });
  };

  const confirmFile = (croppedImage, name) => {
    Promise.all([croppedImage.blob, croppedImage.content]).then(([blob, content]) => {
      if (multi) {
        const formIndex = form.values.files.findIndex((file) => file.name == name);
        const oldImage = form.values.files[formIndex];
        if (formIndex > -1) {
          form.removeListItem('files', formIndex);
        }
        form.insertListItem(
          'files',
          { ...oldImage, name, croppedImage: blob, content },
          formIndex > -1 ? formIndex : 100
        );
      } else {
        form.setFieldValue('files', [{ ...form.values.files, name, croppedImage: blob, content }]);
      }
      setOpened(false);
    });
  };

  return (
    <Box>
      {fileError && (
        <Container size="sm">
          <Center>
            <Alert icon={<IconAlertCircle size={16} />} title="Bummer!" color="red">
              {fileError}
            </Alert>
          </Center>
        </Container>
      )}
      <Dropzone
        multiple={false}
        accept={IMAGE_MIME_TYPE}
        onDrop={dropFiles}
        onReject={(files) => {
          setAlert(<CustomAlert files={files} setAlert={setAlert} />);
        }}
        maxSize={10 * 1024 ** 2}
        sx={{ display: form.values.files.length > 0 ? 'none' : 'block' }}
      >
        <Group position="center" spacing="xs" style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={18}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={18} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size={18} stroke={1.5} />
          </Dropzone.Idle>

          <Box>
            <Text size="sm" inline>
              Upload image
            </Text>
          </Box>
        </Group>
      </Dropzone>

      <Center>{previews}</Center>

      <Modal opened={opened} size="lg" onClose={() => setOpened(false)} title="Resize photo">
        <Container size="xs">
          <Box h="500px" p={0} sx={{ position: 'relative' }}>
            {selectedFile && (
              <PhotoCrop
                handleImage={(image) => confirmFile(image, selectedFile.name)}
                aspect={shape == 'rect' ? aspect : 1}
                shape={shape}
                image={selectedFile}
              />
            )}
          </Box>
        </Container>
      </Modal>
    </Box>
  );
}
