import { useState, useContext, ReactElement } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  createStyles,
  Badge,
  Box,
  Button,
  Header,
  Menu,
  Group,
  Center,
  Divider,
  Burger,
  Container,
  Flex,
  Grid,
  Space,
  Title,
  Avatar,
  Paper,
  Progress,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useClickOutside } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import {
  IconChevronRight,
  IconChevronDown,
  IconCloud,
  IconSettings,
  IconCaretDown,
  IconSearch,
  IconPhoto,
  IconToolsKitchen2,
  IconFriends,
  IconCalendar,
  IconMessageCircle,
  IconTrash,
  IconArrowsLeftRight,
  IconBell,
} from '@tabler/icons';
import { useStore } from '../../index';
import UserButton from './UserButton';
import Sidebar from '../Sidebar/Sidebar';
import { useMediaQuery, upperFirst } from '@mantine/hooks';
import { deleteTenant } from 'api/tenant-service';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors[theme.primaryColor][theme.primaryShade[theme.colorScheme]],
    borderBottom: 0,
    paddingLeft: 0,
    width: '100%',
    overflow: 'hidden',
  },

  inner: {
    height: 56,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    marginLeft: '16px',
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  logo: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colors.gray[0],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.blue[5],
    },

    [theme.fn.smallerThan('sm')]: {
      color: theme.colors.gray[7],
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  dropdown: {
    position: 'absolute',
    top: 56,
    left: 0,
    right: '25%',
    zIndex: 1000,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
      right: '50%',
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}));

const deleteFamily = async (tenantId, setter) => {
  const user = await Auth.currentAuthenticatedUser();
  deleteTenant(tenantId);
  if (user.attributes['custom:primaryTenant'] === tenantId) {
    await Auth.deleteUserAttributes(user, ['custom:primaryTenant']);
  }
  setter(undefined);
  window.location.reload();
};

export default function BaseHeader() {
  const theme = useMantineTheme();
  // const currentTenant = useStore((state) => state.tenant);
  // const changeTenant = useStore((state) => state.setTenant());
  const [currentTenant, changeTenant] = useStore((state) => [state.tenant, state.setTenant]);
  // const { currentTenant, changeTenant } = useContext(TenantContext);
  const [opened, { toggle, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const hide = currentLocation.pathname == '/new' || currentLocation.pathname == '/login';
  const { classes, cx } = useStyles();
  const clickOutsideRef = useClickOutside(() => close());

  const openDeleteModal = (tenantId) =>
    openConfirmModal({
      title: 'Delete Family',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this family? No member will be able to access this space, and all data will be
          lost.
        </Text>
      ),
      labels: { confirm: 'Delete Family', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => deleteFamily(tenantId, changeTenant),
    });

  return (
    <Box sx={{ position: 'static', top: 0, left: 0 }}>
      <Header height={56} w="100vw" mr={0} className={classes.header} color={theme.primaryColor}>
        <Flex wrap="nowrap" justify="flex-start" align="center" direction="row" sx={{ width: '100%', height: 56 }}>
          <Group ref={clickOutsideRef} sx={{ gap: 0 }} ml="16px">
            {currentTenant.tenantId ? (
              <Menu width={'content'} shadow="xs">
                <Menu.Target>
                  <Box component="a" sx={{ '&:hover': { cursor: 'pointer' } }}>
                    <Flex align="center">
                      <IconCloud size={28} color={theme.white} />{' '}
                      <Title truncate size="h3" color={theme.white} className={classes.logo}>
                        {currentTenant?.tenantName ?? ''}
                        <IconChevronDown size={14} color={theme.white} />
                      </Title>
                    </Flex>
                  </Box>
                </Menu.Target>

                <Menu.Dropdown sx={{ position: 'absolute', left: '0 !important', top: '56px !important' }}>
                  <Container fluid py={theme.spacing.md}>
                    <Center>
                      {currentTenant.tenantName && (
                        <Avatar src={null} alt="M" color={currentTenant.color} radius="sm">
                          {currentTenant.tenantName.slice(0, 4) == 'The '
                            ? currentTenant.tenantName.replace(/ /g, '')[3] ?? 'F'
                            : currentTenant.tenantName.replace(/ /g, '')[0] ?? 'F'}
                        </Avatar>
                      )}
                      <Box px={theme.spacing.lg}>
                        <Title size="h5">{currentTenant.tenantName}</Title>
                        <Text size="xs" color="dimmed">
                          {currentTenant.tenantId}
                        </Text>
                      </Box>
                    </Center>
                    <Progress
                      mt="sm"
                      radius="xl"
                      size={16}
                      sections={[
                        { value: 33, color: 'blue', label: 'Documents', tooltip: 'Document – 33 Gb' },
                        { value: 28, color: 'teal', label: 'Apps', tooltip: 'Apps – 28 Gb' },
                        { value: 25, color: 'violet', label: 'Other', tooltip: 'Other – 25 Gb' },
                      ]}
                    />
                  </Container>
                  <Menu.Divider />
                  <Menu.Item disabled>Upgrade</Menu.Item>
                  <Menu.Item disabled>Invite people to {currentTenant.tenantName}</Menu.Item>
                  <Menu.Divider />
                  <Menu.Item>Family Settings</Menu.Item>
                  <Menu.Item>Manage Permissions</Menu.Item>
                  <Menu.Divider />
                  <Menu.Item disabled icon={<IconArrowsLeftRight size={14} />}>
                    Transfer my data
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    onClick={() => openDeleteModal(currentTenant.tenantId)}
                    icon={<IconTrash size={14} />}
                  >
                    Delete Family
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ) : (
              <Flex align="center">
                <IconCloud size={28} color={theme.white} ml={16} />{' '}
              </Flex>
            )}
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" color="#fff" />
            <Transition transition="pop-top-left" duration={200} mounted={opened}>
              {(styles) => (
                <Paper
                  className={classes.dropdown}
                  withBorder
                  style={{ ...styles, background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white }}
                >
                  <Sidebar handleClick={close} hidden={false} width={{ md: '100%' }} />
                </Paper>
              )}
            </Transition>

            <Space w="md" />

            {/* <IconCloud size={28} color={theme.colors.blue[0]} />{' '}
              <Title size="h3" color={theme.colors.blue[0]}>
                {currentTenant?.tenantName ?? ''}
              </Title> */}
          </Group>
          <Space w="md" />
          {!hide && (
            <>
              <UserButton />
            </>
          )}
        </Flex>
      </Header>
    </Box>
  );
}
