import { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Menu,
  Group,
  Center,
  Grid,
  Container,
  Divider,
  Space,
  Title,
  Image,
  Paper,
  Tabs,
  Select,
  Text,
  ThemeIcon,
  TextInput,
  createStyles,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus, IconPhone, IconAt, IconMapPin, IconExternalLink, IconWorldWww } from '@tabler/icons';
import { ComponentPropsToStylePropsMap, useAuthenticator } from '@aws-amplify/ui-react';
import { showNotification } from '@mantine/notifications';
import { Auth } from 'aws-amplify';
import { useForm } from '@mantine/form';
import { getUser, updateUser } from '../../../../api/user-service';

import { uploadToS3 } from '../../../../api/upload-service';
import { useStore } from '../../../../index';
import { shallow } from 'zustand/shallow';
import { UploadButton } from 'Base/Uploads/UploadButton';
import { prefixes, suffixes, states, countries } from '../../utils/data';

const useStyles = createStyles((theme) => ({
  responsiveHide: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  searchbar: {
    width: '75%',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

async function updateUserPhoto(picture) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    picture: picture,
  });
}

interface UserForm {
  photo: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  prefix: string | undefined;
  suffix: string | undefined;
  phone: number | null;
  address: {
    street: string | undefined;
    state: string | undefined;
    zip: number | undefined;
    country: string | undefined;
  };
  socialMedia: {
    facebook: string | undefined;
    github: string | undefined;
    google: string | undefined;
    instagram: string | undefined;
    linkedin: string | undefined;
    mastodon: string | undefined;
    pinterest: string | undefined;
    snapchat: string | undefined;
    spotify: string | undefined;
    telegram: string | undefined;
    tumblr: string | undefined;
    twitch: string | undefined;
    twitter: string | undefined;
    website: string | undefined;
    whatsapp: string | undefined;
    youtube: string | undefined;
  };
}

export default function Preferences() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const currentTenant = useStore((state) => state.tenant);
  const [user, setUser] = useStore((state) => [state.user, state.setUser], shallow);
  const [openSocial, setOpenSocial] = useState<boolean>(false);
  const [newPhoto, setNewPhoto] = useState<string>(user.picture);

  const form = useForm<UserForm>({
    initialValues: {
      photo: user.picture,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      prefix: user.prefix,
      suffix: user.suffix,
      phone: user.phone,
      address: {
        street: user.address?.street,
        state: user.address?.state,
        zip: user.address?.zip,
        country: user.address?.country,
      },
      socialMedia: {
        facebook: user.socialMedia?.facebook,
        github: user.socialMedia?.github,
        google: user.socialMedia?.google,
        instagram: user.socialMedia?.instagram,
        linkedin: user.socialMedia?.linkedin,
        mastodon: user.socialMedia?.mastodon,
        pinterest: user.socialMedia?.pinterest,
        snapchat: user.socialMedia?.snapchat,
        spotify: user.socialMedia?.spotify,
        telegram: user.socialMedia?.telegram,
        tumblr: user.socialMedia?.tumblr,
        twitch: user.socialMedia?.twitch,
        twitter: user.socialMedia?.twitter,
        website: user.socialMedia?.website,
        whatsapp: user.socialMedia?.whatsapp,
        youtube: user.socialMedia?.youtube,
      },
    },
  });

  const photoForm = useForm({
    initialValues: {
      files: [],
      fileUploadPaths: [],
    },
  });

  const handleSubmit = (values) => {
    updateUser({ body: values }).then((response) => {
      if (response.status == 'success') {
        setUser(values);
        console.log('here', response);
        showNotification({
          title: 'Success',
          message: 'User details updated',
          color: theme.other.secondaryColor,
        });
      } else {
        console.log(response);
        showNotification({
          title: response.response.data.name,
          message: response.response.data.message,
          color: 'red',
        });
      }
    });
  };

  console.log(form.isDirty());

  const handleUpload = async () => {
    try {
      photoForm.values.files.map((file) => {
        console.log(file);
        if (file.content && file.content.type) {
          console.log('uploading');
          uploadToS3({
            tenantId: currentTenant.tenantId,
            fileType: file.content.type,
            fileContents: file.content,
            profile: true,
          }).then((filePath) => {
            console.log('filePath', filePath);
            const photoUrl = `https://www.courtney.cloud/${filePath}`;
            setNewPhoto(photoUrl);
            updateUserPhoto(photoUrl);
          });
        }
      });
      form.setFieldValue('files', []);
    } catch (err) {
      console.log('error is', err);
    }
  };

  return (
    // <Tabs.Panel value="profile" pt="sm">
    <Container fluid m={0} p={0}>
      <Box>
        <Container fluid py={theme.spacing.xl}>
          <Grid justify="flex-start">
            <Grid.Col sm={12}>
              <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                Profile picture
              </Title>
              <Text>Show everyone that beautiful smile.</Text>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Center>
                <Avatar src={newPhoto} h="120px" w="120px" sx={{ borderRadius: '50%' }} />
                {photoForm.values.fileUploadPaths.map((file) => (
                  <Avatar mah="100px" maw="100px" src={file || ''} alt="" />
                ))}
                <Box ml={theme.spacing.md}>
                  <UploadButton shape="round" form={photoForm} multi={false} />
                </Box>
              </Center>
              {photoForm.values.files.length > 0 && (
                <Center>
                  <Button onClick={handleUpload} mt={theme.spacing.xl}>
                    {photoForm.values.files.length > 1
                      ? `Upload ${photoForm.values.files.length} Photos`
                      : `Upload 1 Photo`}
                  </Button>
                </Center>
              )}
            </Grid.Col>
          </Grid>
        </Container>
        <Divider />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Container fluid py={theme.spacing.xl}>
            <Grid justify="flex-start">
              <Grid.Col sm={12}>
                <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                  Personal details
                </Title>
                <Text>Information about yourself.</Text>
              </Grid.Col>
              <Grid.Col sm={12}>
                <Grid>
                  <Grid.Col xs={6} sm={4} md={3}>
                    <Select
                      searchable
                      creatable
                      clearable
                      label="Prefix"
                      placeholder=""
                      data={prefixes}
                      {...form.getInputProps('prefix')}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={8} md={9}>
                    <TextInput
                      required
                      {...form.getInputProps('firstName')}
                      placeholder="First name"
                      label="First name"
                    />
                  </Grid.Col>
                  <Grid.Col xs={0} sm={4} md={3}></Grid.Col>
                  <Grid.Col xs={12} sm={8} md={9}>
                    <TextInput placeholder="Middle name" label="Middle name" {...form.getInputProps('middleName')} />
                  </Grid.Col>
                  <Grid.Col xs={6} sm={4} md={3}>
                    <Select
                      searchable
                      creatable
                      clearable
                      label="Suffix"
                      placeholder=""
                      data={suffixes}
                      {...form.getInputProps('suffix')}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={8} md={9}>
                    <TextInput required placeholder="Last name" label="Last name" {...form.getInputProps('lastName')} />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Container>
          <Divider />
          <Container fluid py={theme.spacing.xl}>
            <Grid justify="flex-start">
              <Grid.Col sm={12}>
                <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                  Contact info
                </Title>
                <Text>Make sure your family can contact you.</Text>
              </Grid.Col>
              <Grid.Col sm={12}>
                <Grid>
                  <Grid.Col xs={12}>
                    <TextInput
                      disabled
                      type="email"
                      placeholder="Email"
                      label="Email"
                      value={user.email}
                      icon={<IconAt size={14} />}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      type="tel"
                      placeholder="Phone number"
                      label="Phone"
                      icon={<IconPhone size={14} />}
                      {...form.getInputProps('phone')}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <TextInput
                      type="address"
                      placeholder="Address"
                      label="Address"
                      icon={<IconMapPin size={14} />}
                      {...form.getInputProps('address.street')}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <Select
                      searchable
                      clearable
                      placeholder="State"
                      label="State"
                      data={states}
                      {...form.getInputProps('address.state')}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6}>
                    <TextInput
                      type="number"
                      label="Zip code"
                      placeholder="Zip code"
                      {...form.getInputProps('address.zip')}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12}>
                    <Select
                      searchable
                      clearable
                      placeholder="Country"
                      label="Country"
                      data={countries}
                      {...form.getInputProps('address.country')}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Container>
          <Divider />
          {openSocial ? (
            <Container fluid py={theme.spacing.xl}>
              <Center pb={theme.spacing.xl}>
                <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                  Add social media links
                </Title>
              </Center>

              <Grid justify="flex-start">
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Facebook"
                    label="Facebook"
                    icon={
                      <Box p={5}>
                        <Image src="/facebook-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.facebook')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Github"
                    label="Github"
                    icon={
                      <Box p={5}>
                        <Image src="/github-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.github')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Google"
                    label="Google"
                    icon={
                      <Box p={5}>
                        <Image src="/google-plus-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.google')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Instagram"
                    label="Instagram"
                    icon={
                      <Box p={5}>
                        <Image src="/instagram-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.instagram')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="LinkdIn"
                    label="LinkedIn"
                    icon={
                      <Box p={5}>
                        <Image src="/linkedin-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.linkedin')}
                  />
                </Grid.Col>

                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Mastodon"
                    label="Mastodon"
                    icon={
                      <Box p={5}>
                        <Image src="/mastodon-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.mastodon')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Pinterest"
                    label="Pinterest"
                    icon={
                      <Box p={5}>
                        <Image src="/pinterest-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.pinterest')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Snapchat"
                    label="Snapchat"
                    icon={
                      <Box p={5}>
                        <Image src="/snapchat-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.snapchat')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Spotify"
                    label="Spotify"
                    icon={
                      <Box p={5}>
                        <Image src="/spotify-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.spotify')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Telegram"
                    label="Telegram"
                    icon={
                      <Box p={5}>
                        <Image src="/telegram-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.telegram')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Tumblr"
                    label="Tumblr"
                    icon={
                      <Box p={5}>
                        <Image src="/tumblr-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.tumblr')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Twitch"
                    label="Twitch"
                    icon={
                      <Box p={5}>
                        <Image src="/twitch-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.twitch')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Twitter"
                    label="Twitter"
                    icon={
                      <Box p={5}>
                        <Image src="/twitter-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.twitter')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="Website"
                    label="Website"
                    icon={<IconWorldWww size={28} />}
                    {...form.getInputProps('socialMedia.website')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="WhatsApp"
                    label="WhatsApp"
                    icon={
                      <Box p={5}>
                        <Image src="/whatsapp-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.whatsapp')}
                  />
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                  <TextInput
                    placeholder="YouTube"
                    label="YouTube"
                    icon={
                      <Box p={5}>
                        <Image src="/youtube-icon.svg" />
                      </Box>
                    }
                    {...form.getInputProps('socialMedia.youtube')}
                  />
                </Grid.Col>
              </Grid>
            </Container>
          ) : (
            <Container fluid py={theme.spacing.xl}>
              <Grid justify="flex-start">
                <Grid.Col sm={12}>
                  <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                    Social media
                  </Title>
                  <Text>Connect with your family on social media.</Text>
                </Grid.Col>
                <Grid.Col sm={12}>
                  <Container>
                    <Group sx={{ justifyContent: 'flex-start' }}>
                      {Object.entries(user.socialMedia ?? {}).map(
                        ([key, value]) =>
                          value && (
                            <Button
                              component="a"
                              key={key}
                              variant="outline"
                              radius="xl"
                              href={value}
                              target="_blank"
                              leftIcon={<Avatar size={24} src={`/${key}-icon.svg`} />}
                              rightIcon={<IconExternalLink size={18} />}
                            >
                              <Text tt="capitalize">{key}</Text>
                            </Button>
                          )
                      )}
                    </Group>
                    <Space h={theme.spacing.xl} />
                    <Center>
                      <Button variant="default" size="xs" onClick={() => setOpenSocial(!openSocial)}>
                        Edit links
                      </Button>
                    </Center>
                  </Container>
                </Grid.Col>
              </Grid>
            </Container>
          )}

          <Container fluid py={theme.spacing.xl}>
            <Center>
              <Button type="submit" disabled={!form.isDirty()}>
                Save
              </Button>
            </Center>
          </Container>
        </form>
      </Box>
    </Container>
    // </Tabs.Panel>
  );
}
