import { useState, useEffect, SyntheticEvent } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Button,
  Box,
  CloseButton,
  Header,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Divider,
  Flex,
  Navbar,
  Tabs,
  Title,
  SegmentedControl,
  Space,
  Paper,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ProfileSettings from '../../Views/Preferences/Tabs/Profile/ProfileSettings';
import ThemeSettings from '../../Views/Preferences/Tabs/Theme/ThemeSettings';
import FamilySettings from '../../Views/Preferences/Tabs/Families/FamilySettings';

export function RightBar({ handleClose, content }) {
  const theme = useMantineTheme();
  const smBreak = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const rightContent =
    content == 'Theme' ? <ThemeSettings /> : content == 'Family' ? <FamilySettings /> : <ProfileSettings />;

  return (
    <>
      <Navbar withBorder={false}>
        <Box>
          <Navbar.Section p="xs">
            <Flex justify="space-between" align="center">
              <Title size="h3">{content}</Title>
              <CloseButton onClick={handleClose} title="Close popover" size="xl" iconSize={20} />
            </Flex>
          </Navbar.Section>
          <Divider />
          <Navbar.Section grow p="xs" pt={0}>
            {rightContent}
          </Navbar.Section>
        </Box>
      </Navbar>
      {/* <Container size="md">
        <Tabs
          defaultValue="profile"
          orientation={xsBreak ? 'horizontal' : 'vertical'}
          placement={xsBreak ? undefined : 'right'}
          aria-label="navigate actions"
        >
          <Box p={theme.spacing.md}>
            <Title size="h2" mb={theme.spacing.xs}>
              Preferences
            </Title>
            <Tabs.List>
              <Tabs.Tab value="profile" onClick={() => navigate('profile')}>
                Profile
              </Tabs.Tab>
              <Tabs.Tab value="theme" onClick={() => navigate('theme')}>
                Theme
              </Tabs.Tab>
              <Tabs.Tab value="privacy" onClick={() => {}}>
                Privacy
              </Tabs.Tab>
              <Tabs.Tab value="families" onClick={() => navigate('families')}>
                Families
              </Tabs.Tab>
            </Tabs.List>
          </Box>
        </Tabs>
        <Space h="xl" />
      </Container>
      <Footer /> */}
    </>
  );
}
