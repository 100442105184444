import { useLocation } from 'react-router-dom';
import { createStyles, Container, Center, Group, Title, Anchor } from '@mantine/core';
import {
  IconChevronRight,
  IconChevronDown,
  IconCloud,
  IconSettings,
  IconSearch,
  IconPhoto,
  IconToolsKitchen2,
  IconFriends,
  IconCalendar,
  IconMessageCircle,
  IconTrash,
  IconArrowsLeftRight,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 120,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    zIndex: 0,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    zIndex: 0,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

interface FooterSimpleProps {
  label: string;
  location: string;
}

const pages: FooterSimpleProps[] = [
  {
    location: '#',
    label: 'Contact',
  },
  {
    location: '#',
    label: 'Privacy',
  },
  {
    location: '#',
    label: 'Blog',
  },
  {
    location: '#',
    label: 'Contribute',
  },
];

export const Footer = () => {
  const { classes } = useStyles();
  const currentLocation = useLocation();

  const items = pages.map((page) => (
    <Anchor<'a'>
      color="dimmed"
      key={page.label}
      href={page.location}
      onClick={(event) => event.preventDefault()}
      size="sm"
    >
      {page.label}
    </Anchor>
  ));

  return currentLocation.pathname == '/family' ? (
    <></>
  ) : (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Center sx={{ display: 'flex', textDecoration: 'none', color: 'inherit' }}>
          <IconCloud size={28} /> <Title size="h3">Courtney</Title>
        </Center>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
};
