import React, { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useAuthenticator, Loader } from '@aws-amplify/ui-react';
import { useStore } from '../../../index';
// import { useAuthContext } from '../AuthProvider';
import { useUserPermission } from '../providers/UserPermissionProvider';
import MessagingService from '../services/MessagingService';
import { mergeArrayOfObjects } from '../utilities/mergeArrays';
// import { ChimeApi } from '../../../api/chime-service';

const ChatMessagingState = createContext({} as any);
const ChatChannelState = createContext({} as any);

const MessagingProvider = ({ children }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [currentTenant] = useStore((state) => [state.tenant]);
  const [user, chime] = useStore((state) => [state.user, state.chime]);
  const userPermission = useUserPermission();
  const [messagingService] = useState(() => new MessagingService(chime));
  console.log('appInstanceArn', chime.appInstanceArn);

  // Channel related
  const [activeView, setActiveView] = useState('User');
  const [activeChannel, setActiveChannel] = useState({} as any);
  const [activeChannelMemberships, setActiveChannelMemberships] = useState([]);
  const activeChannelRef = useRef(activeChannel.ChannelArn);
  const [activeChannelFlow, setActiveChannelFlow] = useState({});
  const [activeChannelModerators, setActiveChannelModerators] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [unreadChannels, setUnreadChannels] = useState([]);
  const unreadChannelsListRef = useRef(unreadChannels);
  const [typingIndicator, setTypingIndicator] = useState(null);
  const [channelListModerator, setChannelListModerator] = useState([]);
  const [activeChannelMembershipsWithPresence, setActiveChannelMembershipsWithPresence] = useState([]);
  const hasMembership = activeChannelMemberships.map((m) => m.Member.Arn).indexOf(chime.memberArn) > -1;

  // Messages
  const [messages, setMessages] = useState([]);
  const messagesRef = useRef(messages);
  const channelListRef = useRef(channelList);
  const [moderatedChannel, setModeratedChannel] = useState('');
  const channelListModeratorRef = useRef(channelListModerator);
  const activeChannelMembershipsRef = useRef(activeChannelMemberships);
  const activeChannelMembershipsWithPresenceRef = useRef(activeChannelMembershipsWithPresence);
  const [channelMessageToken, setChannelMessageToken] = useState('');
  const channelMessageTokenRef = useRef(channelMessageToken);

  // Meeting
  const [meetingInfo, setMeetingInfo] = useState('');

  useEffect(() => {
    messagesRef.current = messages;
    activeChannelRef.current = activeChannel;
    channelListRef.current = channelList;
    channelListModeratorRef.current = channelListModerator;
    unreadChannelsListRef.current = unreadChannels;
    activeChannelMembershipsRef.current = activeChannelMemberships;
    activeChannelMembershipsWithPresenceRef.current = activeChannelMembershipsWithPresence;
    channelMessageTokenRef.current = channelMessageToken;
  });

  // Messaging service initiator
  useEffect(() => {
    if (authStatus !== 'authenticated') {
      console.log('user not logged in!');
      return;
    }

    // Start messaging service
    console.log('starting messaging service');
    messagingService.connect(chime.memberArn);

    return () => {
      messagingService.close();
    };
  }, [authStatus]);

  const processChannelMessage = async (message) => {
    console.log('process channel message');
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    let isDuplicate = false;

    messagesRef.current.forEach((m, i, self) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        console.log('Duplicate message found', newMessage);
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    let newMessages = [...messagesRef.current];

    if (!isDuplicate && newMessage.Persistence === 'PERSISTENT') {
      newMessages = [...newMessages, newMessage];
    }

    setMessages(newMessages);
  };

  const messagesProcessor = async (message) => {
    const messageType = message?.headers['x-amz-chime-event-type'];
    const record = JSON.parse(message?.payload);
    console.log('Incoming Message', message);
    switch (messageType) {
      // Channel Messages
      case 'CREATE_CHANNEL_MESSAGE':
      case 'REDACT_CHANNEL_MESSAGE':
      case 'UPDATE_CHANNEL_MESSAGE':
      case 'DELETE_CHANNEL_MESSAGE':
      case 'DENIED_CREATE_CHANNEL_MESSAGE':
      case 'FAILED_CREATE_CHANNEL_MESSAGE':
      case 'DENIED_UPDATE_CHANNEL_MESSAGE':
      case 'FAILED_UPDATE_CHANNEL_MESSAGE':
      case 'PENDING_CREATE_CHANNEL_MESSAGE':
      case 'PENDING_UPDATE_CHANNEL_MESSAGE':
        // Process ChannelMessage
        if (record.Metadata) {
          const metadata = JSON.parse(record.Metadata);
          if (metadata.isMeetingInfo && record.Sender.Arn !== chime.memberArn) {
            const meetingInfo = JSON.parse(record.Content);
            setMeetingInfo(meetingInfo);
            break;
          }
        }
        // Process typing indicator control message
        if (record.Content && record.Content.match(/Typing/)) {
          if (record.Sender.Arn !== chime.memberArn) {
            if (activeChannelRef.current.ChannelArn === record?.ChannelArn) {
              const indicator = {
                SenderName: record.Sender.Name,
                LastUpdatedTimestamp: record.LastUpdatedTimestamp,
              };
              setTypingIndicator(indicator);
            }
            break;
          }
        }

        // Process channel presence status control message
        // if (!!record.Content?.match(PRESENCE_REGEX)) {
        //   if (record.Sender.Arn !== chime.memberArn) {
        //     console.log('presence info accepted. content: ' + JSON.stringify(record));
        //     if (activeChannelRef.current.ChannelArn === record?.ChannelArn) {
        //       const updatedMemberships = activeChannelMembershipsWithPresenceRef.current.map((m) => {
        //         if (m.Member.Arn === record.Sender.Arn) {
        //           const content = record.Content;
        //           const isAutomatic = content.startsWith(`${PRESENCE_PREFIX}${PresenceStatusPrefix.Auto}`);
        //           const status = content.substr(content.lastIndexOf(PRESENCE_PREFIX_SEPARATOR) + 1);
        //           const statusExpired = isAutomaticStatusExpired(record.LastUpdatedTimestamp);
        //           m.Member.Presence = {
        //             IsAutomatic: isAutomatic,
        //             Status: isAutomatic
        //               ? !statusExpired
        //                 ? status
        //                 : PresenceAutoStatus.Offline
        //               : status || PresenceAutoStatus.Offline,
        //             LastUpdatedTimestamp: record.LastUpdatedTimestamp,
        //           };
        //         }
        //         return m;
        //       });
        //       setActiveChannelMembershipsWithPresence(updatedMemberships);
        //     }
        //   }
        //   break;
        // }

        // Process channel message
        if (activeChannelRef.current.ChannelArn === record?.ChannelArn) {
          processChannelMessage(record);
        } else {
          const findMatch = unreadChannelsListRef.current.find((chArn) => chArn === record.ChannelArn);
          if (findMatch) return;
          const newUnreads = [...unreadChannelsListRef.current, record.ChannelArn];
          setUnreadChannels(newUnreads);
        }

        break;
      // Channels actions
      case 'CREATE_CHANNEL':
      case 'UPDATE_CHANNEL':
        {
          const newChannelArn = record.ChannelArn;
          const updatedChannelList = channelListRef.current.map((c) => {
            if (c.ChannelArn !== newChannelArn) {
              return c;
            }
            return record;
          });
          setChannelList(updatedChannelList);
          setActiveChannel(record);
        }
        break;
      case 'DELETE_CHANNEL': {
        setChannelList(channelListRef.current.filter((chRef) => chRef.ChannelArn !== record.ChannelArn));
        if (activeChannelRef.current.ChannelArn === record.ChannelArn) {
          setActiveChannel({});
        }
        break;
      }
      // Channel Memberships
      case 'CREATE_CHANNEL_MEMBERSHIP':
        {
          var newChannel = await chime.describeChannel(record.ChannelArn);

          if (newChannel.Metadata) {
            let metadata = JSON.parse(newChannel.Metadata);
            if (metadata.isHidden) return;
          }
          var newChannelList = [];
          const channelType = JSON.parse(activeChannelRef.current.Metadata || '{}').ChannelType;
          if (activeChannelRef.current.ElasticChannelConfiguration || channelType != 'PUBLIC_ELASTIC') {
            newChannelList = mergeArrayOfObjects([newChannel], channelListRef.current, 'ChannelArn');
          } else {
            newChannelList = mergeArrayOfObjects(channelListRef.current, [newChannel], 'ChannelArn');
            if (record.ChannelArn == activeChannelRef.current.ChannelArn && channelType == 'PUBLIC_ELASTIC') {
              const newMessages = await chime.listChannelMessages(newChannel.ChannelArn);
              setMessages(newMessages.Messages);
              setChannelMessageToken(newMessages.NextToken);
              setActiveChannel(newChannel);
            }
          }
          setChannelList(newChannelList);

          // If channel uses persistent presence, save status for the user
          // await initChannelPresence(newChannel);
        }
        break;
      case 'UPDATE_CHANNEL_MEMBERSHIP':
        if (chime.memberArn !== record?.InvitedBy.Arn) {
          const channel = await chime.describeChannel(record?.ChannelArn);
          const newChannelList = mergeArrayOfObjects(channelListRef.current, [channel], 'ChannelArn');
          setChannelList(newChannelList);
        }
        break;
      case 'DELETE_CHANNEL_MEMBERSHIP':
        // You are removed
        if (record.Member.Arn.includes(user.userId)) {
          setChannelList(channelListRef.current.filter((chRef) => chRef.ChannelArn !== record.ChannelArn));
          if (activeChannelRef.current.ChannelArn === record.ChannelArn) {
            setActiveChannel({});
          }
        } else {
          // Someone else is removed
          const updatedMemberships = activeChannelMembershipsRef.current.filter(
            (m) => m.Member.Arn !== record.Member.Arn
          );
          setActiveChannelMemberships(updatedMemberships);
        }
        break;
      default:
        console.log(`Unexpected message type! ${messageType}`);
    }
  };

  const channelIdChangeHandler = async (channel) => {
    if (activeChannel.ChannelArn === channel.ChannelArn) return;
    let mods = [];
    setActiveChannelModerators([]);

    var isModerator = false;
    const channelType = JSON.parse(channel.Metadata || '{}').ChannelType;
    // Moderator is for channel only, not subChannel
    if (!channel.SubChannelId) {
      try {
        mods = await chime.listChannelModerators(channel.ChannelArn);
        setActiveChannelModerators(mods);
      } catch (err) {
        if (channel.Privacy != 'PUBLIC') console.error('ERROR', err);
      }

      isModerator = mods?.find((moderator) => moderator.Moderator.Arn === chime.memberArn) || false;
    }
    // Assessing user role for given channel
    userPermission.setRole(isModerator ? 'moderator' : 'user');

    const newChannel = await chime.describeChannel(channel.ChannelArn);

    // listChannelMessages is available to regular channels and subChannels
    const newMessages = await chime.listChannelMessages(channel.ChannelArn);
    setMessages(newMessages.Messages);
    setChannelMessageToken(newMessages.NextToken);

    setActiveChannel(newChannel);

    await chime.updateChannelReadMarker({ channelArn: channel.ChannelArn });
    const channelIndex = channelList.findIndex((c) => c.ChannelArn === channel.ChannelArn);
    if (channelIndex !== -1) {
      // channel is in List and needs replaced with updated read marker
      const updateChannel = await chime.describeChannelMembershipsForAppInstanceUser({
        channelArn: channel.ChannelArn,
      });

      console.log('here', updateChannel, channelList);
      const newChannelList = channelList.map((c) =>
        c.ChannelArn === channel.ChannelArn
          ? {
              ...updateChannel.ChannelSummary,
              ReadMarkerTimestamp: updateChannel.AppInstanceUserMembershipSummary.ReadMarkerTimestamp,
            }
          : c
      );
      console.log('UPDATE CHANNEL LIST ', newChannelList);
      setChannelList(newChannelList);
    }

    // await loadChannelFlow(channel);
    setUnreadChannels(unreadChannels.filter((c) => c !== channel.ChannelArn));
  };

  // Subscribe to MessagingService for updates
  useEffect(() => {
    if (authStatus !== 'authenticated') {
      console.log('user not logged in!');
      return;
    }

    messagingService.subscribeToMessageUpdate(messagesProcessor);
    return () => {
      messagingService.unsubscribeFromMessageUpdate(messagesProcessor);
    };
  }, [messagingService, authStatus]);

  // Providers values
  const messageStateValue = {
    chime,
    messages,
    messagesRef,
    setMessages,
  };
  const channelStateValue = {
    chime,
    channelIdChangeHandler,
    channelList,
    channelListModerator,
    activeChannel,
    activeView,
    activeChannelFlow,
    activeChannelRef,
    channelListRef,
    channelListModeratorRef,
    unreadChannels,
    activeChannelMemberships,
    // activeChannelMembershipsWithPresence,
    hasMembership,
    channelMessageToken,
    channelMessageTokenRef,
    meetingInfo,
    setActiveChannel,
    setActiveView,
    setActiveChannelFlow,
    setActiveChannelMemberships,
    // setActiveChannelMembershipsWithPresence,
    setChannelMessageToken,
    setChannelList,
    setChannelListModerator,
    setUnreadChannels,
    setMeetingInfo,
    moderatedChannel,
    setModeratedChannel,
    typingIndicator,
    setTypingIndicator,
  };
  return (
    // <ChatMessagingServiceContext.Provider value={messagingService}>
    <ChatChannelState.Provider value={channelStateValue}>
      <ChatMessagingState.Provider value={messageStateValue}>{children}</ChatMessagingState.Provider>
    </ChatChannelState.Provider>
    // </ChatMessagingServiceContext.Provider>
  );
};

// const useChatMessagingService = () => {
//   const context = useContext(ChatMessagingServiceContext);

//   if (!context) {
//     throw new Error('useChatMessagingService must be used within ChatMessagingServiceContext');
//   }

//   return context;
// };

const useChatMessagingState = () => {
  const context = useContext(ChatMessagingState);

  if (!context) {
    throw new Error('useChatMessagingState must be used within ChatMessagingState');
  }

  return context;
};

const useChatChannelState = () => {
  const context = useContext(ChatChannelState);

  if (!context) {
    throw new Error('useChatChannelState must be used within ChatChannelState');
  }

  return context;
};

export default MessagingProvider;
export { /*MessagingProvider,*/ useChatChannelState, useChatMessagingState /*useChatMessagingService,*/ };
