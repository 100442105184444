import { useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  Center,
  Card,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  List,
  NumberInput,
  Paper,
  Select,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical, IconHelp, IconX } from '@tabler/icons';

const defaultMeasures = [
  { value: 'mL', label: 'mL' },
  { value: 'L', label: 'L' },
  { value: 'dL', label: 'dL' },
  { value: 'tsp', label: 'tsp' },
  { value: 'Tbsp', label: 'Tbsp' },
  { value: 'fl oz', label: 'fl oz' },
  { value: 'cup', label: 'cup' },
  { value: 'pint', label: 'pint' },
  { value: 'quart', label: 'quart' },
  { value: 'gallon', label: 'gallon' },
  { value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
  { value: 'kg', label: 'kg' },
  { value: 'lbs', label: 'lbs' },
  { value: 'oz', label: 'oz' },
  { value: 'slice', label: 'slice' },
  { value: 'bunch', label: 'bunch' },
  { value: 'can', label: 'can' },
  { value: 'bag', label: 'bag' },
  { value: 'pinch', label: 'pinch' },
  { value: 'dash', label: 'dash' },
  { value: 'pluck', label: 'pluck' },
  { value: 'handful', label: 'handful' },
];

export default function IngredientsForm({ form }) {
  const theme = useMantineTheme();
  const [measures, setMeasures] = useState(defaultMeasures);
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const chapterFields = form.values.chapters.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided) => (
        <>
          <Stack ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
            <Group sx={{ display: 'inline' }}>
              <Center>
                <Box {...provided.dragHandleProps} py="auto" sx={{ textAlign: 'end' }}>
                  <IconGripVertical style={{ minHeight: xsBreak ? 24 : 18, minWidth: xsBreak ? 24 : 18 }} />
                </Box>
                <TextInput
                  placeholder="Preheat the oven..."
                  autosize
                  minRows={2}
                  maxRows={4}
                  {...form.getInputProps(`chapters.${index}.value`)}
                  icon={
                    <>
                      <Text mr={8}>{index + 1}</Text>
                      <Divider orientation="vertical" />
                    </>
                  }
                  sx={{ flexBasis: '100%' }}
                />
                <ActionIcon onClick={() => form.removeListItem('chapters', index)}>
                  <IconX size={18} />
                </ActionIcon>
              </Center>
            </Group>
          </Stack>
        </>
      )}
    </Draggable>
  ));

  return (
    <>
      <Container size="sm">
        <Grid>
          <Grid.Col xs={12}>
            <Center inline>
              <Title size="h3">Chapters</Title>
              <ActionIcon color={theme.colors.teal[6]} variant="transparent">
                <IconHelp size={20} />
              </ActionIcon>
            </Center>
            <Box>
              <DragDropContext
                onDragEnd={({ destination, source }) =>
                  form.reorderListItem('chapters', { from: source.index, to: destination.index })
                }
              >
                <Droppable droppableId="dnd-list" chapter="vertical">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {chapterFields}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            <Center>
              <Button
                size="xs"
                variant="outline"
                mt={15}
                onClick={() =>
                  form.insertListItem('chapters', {
                    step: form.values.chapters.length.toString() + 1,
                    value: 'test',
                  })
                }
              >
                add field
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
        <Box
          bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]}
          mt={25}
          mb={10}
          pb={15}
          sx={{ borderRadius: 10 }}
        >
          <Center>
            <Title color="dimmed" size="h3" mt={15}>
              Preview{' '}
            </Title>
          </Center>
          <Grid>
            <Grid.Col xs={12}>
              <Container>
                <Paper shadow="xs" radius="md" p={15}>
                  <Title size="h4" mb={theme.spacing.xs} color="dimmed">
                    Chapters
                  </Title>
                  <List type="ordered">
                    {form.values.chapters.map((item) => (
                      <Text color="dimmed">
                        <List.Item>{item.value}</List.Item>
                      </Text>
                    ))}
                  </List>
                </Paper>
              </Container>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
