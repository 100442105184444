import { Outlet, useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Menu,
  Group,
  Center,
  Grid,
  Chip,
  Container,
  Divider,
  Flex,
  Space,
  Title,
  Avatar,
  Paper,
  Select,
  Text,
  ThemeIcon,
  TextInput,
  createStyles,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus, IconPhone, IconAt, IconMapPin } from '@tabler/icons';
import { Footer } from '../../Base/Footer/Footer';

export default function Home() {
  return <Outlet />;
}
