import { useState } from 'react';

import {
  ActionIcon,
  AspectRatio,
  Blockquote,
  Box,
  Button,
  Card,
  Center,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  List,
  NumberInput,
  Paper,
  Select,
  Space,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import PhotoCarousel from '../../../Components/Carousel/PhotoCarousel';
import { IconGripVertical, IconHelp } from '@tabler/icons';

export default function PreviewForm({ form }) {
  const theme = useMantineTheme();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  return (
    <Container fluid p={xsBreak ? 5 : 25}>
      <Title size="h1">{form.values.title ?? 'My Magical Recipe'}</Title>
      <Space h="md" />
      <Text>{form.values.description ?? 'Describe how magical this recipe is...'}</Text>
      <Space h="md" />
      <Container fluid={xsBreak} my={15} size="sm">
        <PhotoCarousel images={form.values.files.map((file) => file.croppedImage ?? file.image)} />
      </Container>
      <Container fluid={xsBreak} size="xs">
        <Card withBorder shadow="sm" bg={theme.colors.yellow[1]}>
          <Grid p={5} mb={15} justify="space-around">
            {form.values.info.map((item) => (
              <Grid.Col span="content">
                <Text fw={700} color="black" sx={{ whiteSpace: 'nowrap' }}>
                  {item.key}
                </Text>
                <Divider color="black" />
                <Text color="black" sx={{ whiteSpace: 'nowrap' }}>
                  {item.value}
                </Text>
              </Grid.Col>
            ))}
          </Grid>
          <Divider />
          <Center>
            <Text mt={10} color="black" td="underline" component="a" href="#">
              Go to nutrition facts
            </Text>
          </Center>
        </Card>
      </Container>
      <div dangerouslySetInnerHTML={{ __html: form.values.html }} />
      <Divider />
      <Grid>
        <Grid.Col span={12}></Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <Title size="h1" mb={theme.spacing.xs}>
            Ingredients
          </Title>
          <Flex p={5} justify="space-between">
            <Card withBorder shadow="sm" p={2} maw="95%" miw="70%">
              <Table striped withColumnBorders horizontalSpacing="sm" verticalSpacing="md" captionSide="bottom">
                <caption>These are the ingredients</caption>
                <tbody>
                  {form.values.ingredients.map((value, index) => (
                    <tr key={index.name}>
                      <td
                        style={{
                          whiteSpace: 'nowrap',
                          width: '1%',
                        }}
                      >
                        <Text>
                          {value.number} {value.measure}
                        </Text>
                      </td>
                      <td>
                        <Text>{value.ingredient}</Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <Divider orientation="vertical" />
          </Flex>
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <Title size="h1" mb={theme.spacing.xs}>
            Directions
          </Title>
          {form.values.directions.map((item, index) => (
            <>
              <Blockquote icon={<Title size="h2">{index + 1}.</Title>}>
                <Text>{item.value}</Text>
              </Blockquote>
              <Divider />
            </>
          ))}
        </Grid.Col>
      </Grid>
    </Container>
  );
}
