import { useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  Center,
  Card,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  List,
  NumberInput,
  Paper,
  Select,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical, IconHelp, IconX } from '@tabler/icons';

const defaultMeasures = [
  { value: 'mL', label: 'mL' },
  { value: 'L', label: 'L' },
  { value: 'dL', label: 'dL' },
  { value: 'tsp', label: 'tsp' },
  { value: 'Tbsp', label: 'Tbsp' },
  { value: 'fl oz', label: 'fl oz' },
  { value: 'cup', label: 'cup' },
  { value: 'pint', label: 'pint' },
  { value: 'quart', label: 'quart' },
  { value: 'gallon', label: 'gallon' },
  { value: 'mg', label: 'mg' },
  { value: 'g', label: 'g' },
  { value: 'kg', label: 'kg' },
  { value: 'lbs', label: 'lbs' },
  { value: 'oz', label: 'oz' },
  { value: 'slice', label: 'slice' },
  { value: 'bunch', label: 'bunch' },
  { value: 'can', label: 'can' },
  { value: 'bag', label: 'bag' },
  { value: 'pinch', label: 'pinch' },
  { value: 'dash', label: 'dash' },
  { value: 'pluck', label: 'pluck' },
  { value: 'handful', label: 'handful' },
];

export default function IngredientsForm({ form }) {
  const theme = useMantineTheme();
  const [measures, setMeasures] = useState(defaultMeasures);
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const ingredientFields = form.values.ingredients.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided) => (
        <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
          <Center>
            <Box {...provided.dragHandleProps} sx={{ textAlign: 'center' }}>
              <IconGripVertical
                // size={xsBreak ? 24 : 18}
                style={{ minHeight: xsBreak ? 24 : 18, minWidth: xsBreak ? 24 : 18 }}
              />
            </Box>
            <NumberInput
              w={75}
              mr={5}
              precision={3}
              min={0}
              placeholder="1"
              removeTrailingZeros={true}
              hideControls
              {...form.getInputProps(`ingredients.${index}.number`)}
            />
            <Select
              w={100}
              mr={15}
              data={measures}
              placeholder="dash"
              nothingFound="Nothing found"
              searchable
              creatable
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => {
                const item = { value: query, label: query };
                setMeasures((current) => [...current, item]);
                return item;
              }}
              {...form.getInputProps(`ingredients.${index}.measure`)}
            />
            <TextInput placeholder="ingredient" {...form.getInputProps(`ingredients.${index}.ingredient`)} />
            <ActionIcon onClick={() => form.removeListItem('ingredients', index)}>
              <IconX size={18} />
            </ActionIcon>
          </Center>
        </Group>
      )}
    </Draggable>
  ));

  const directionFields = form.values.directions.map((_, index) => (
    <Draggable key={index} index={index} draggableId={index.toString()}>
      {(provided) => (
        <>
          <Stack ref={provided.innerRef} mt="xs" {...provided.draggableProps}>
            <Group sx={{ display: 'inline' }}>
              <Center>
                <Box {...provided.dragHandleProps} py="auto" sx={{ textAlign: 'end' }}>
                  <IconGripVertical style={{ minHeight: xsBreak ? 24 : 18, minWidth: xsBreak ? 24 : 18 }} />
                </Box>
                <Textarea
                  placeholder="Preheat the oven..."
                  autosize
                  minRows={2}
                  maxRows={4}
                  {...form.getInputProps(`directions.${index}.value`)}
                  icon={
                    <>
                      <Text mr={8}>{index + 1}</Text>
                      <Divider orientation="vertical" />
                    </>
                  }
                  sx={{ flexBasis: '100%' }}
                />
                <ActionIcon onClick={() => form.removeListItem('directions', index)}>
                  <IconX size={18} />
                </ActionIcon>
              </Center>
            </Group>
          </Stack>
        </>
      )}
    </Draggable>
  ));

  return (
    <>
      <Grid>
        <Grid.Col sm={6} xs={12}>
          <Center inline>
            <Title size="h3">Ingredients</Title>
            <ActionIcon color={theme.colors.teal[6]} variant="transparent">
              <IconHelp size={20} />
            </ActionIcon>
          </Center>
          <Center>
            <DragDropContext
              onDragStart={() => {
                window.navigator.vibrate && window.navigator.vibrate(100);
              }}
              onDragEnd={({ destination, source }) =>
                form.reorderListItem('ingredients', { from: source.index, to: destination.index })
              }
            >
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {ingredientFields}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Center>
          <Center>
            <Button
              size="xs"
              variant="outline"
              mt={15}
              onClick={() => form.insertListItem('ingredients', { number: 1, measure: 'dash', ingredient: 'magic' })}
            >
              add field
            </Button>
          </Center>
        </Grid.Col>

        <Grid.Col sm={6} xs={12}>
          <Center inline>
            <Title size="h3">Directions</Title>
            <ActionIcon color={theme.colors.teal[6]} variant="transparent">
              <IconHelp size={20} />
            </ActionIcon>
          </Center>
          <Box>
            <DragDropContext
              onDragEnd={({ destination, source }) =>
                form.reorderListItem('directions', { from: source.index, to: destination.index })
              }
            >
              <Droppable droppableId="dnd-list" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {directionFields}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Center>
            <Button
              size="xs"
              variant="outline"
              mt={15}
              onClick={() =>
                form.insertListItem('directions', { step: form.values.directions.length.toString() + 1, value: 'test' })
              }
            >
              add field
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
      <Box
        bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]}
        mt={25}
        mb={10}
        pb={15}
        sx={{ borderRadius: 10 }}
      >
        <Center>
          <Title color="dimmed" size="h3" mt={15}>
            Preview{' '}
          </Title>
        </Center>
        <Grid>
          <Grid.Col span={12}></Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <Container>
              <Paper shadow="xs" radius="md" p={10}>
                <Title size="h3" mb={theme.spacing.xs} color="dimmed">
                  Ingredients
                </Title>
                <Container fluid>
                  <Card withBorder shadow="sm" p={2} maw="95%" miw="70%">
                    <Table striped withColumnBorders horizontalSpacing="sm" verticalSpacing="md" captionSide="bottom">
                      <caption>These are the ingredients</caption>
                      <tbody>
                        {form.values.ingredients.map((value, index) => (
                          <tr key={index.name}>
                            <td
                              style={{
                                whiteSpace: 'nowrap',
                                width: '1%',
                              }}
                            >
                              <Text>
                                {value.number} {value.measure}
                              </Text>
                            </td>
                            <td>
                              <Text>{value.ingredient}</Text>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                </Container>
              </Paper>
            </Container>
          </Grid.Col>
          <Grid.Col sm={6} xs={12}>
            <Container>
              <Paper shadow="xs" radius="md" p={15}>
                <Title size="h4" mb={theme.spacing.xs} color="dimmed">
                  Directions
                </Title>
                <List type="ordered">
                  {form.values.directions.map((item) => (
                    <Text color="dimmed">
                      <List.Item>{item.value}</List.Item>
                    </Text>
                  ))}
                </List>
              </Paper>
            </Container>
          </Grid.Col>
        </Grid>
      </Box>

      {/* <Box my={theme.spacing.xl} sx={{ display: 'inline-block' }}>
        <Title size="h3" pr={15}>
          Ingredients
        </Title>
        <DragDropContext
          onDragEnd={({ destination, source }) =>
            form.reorderListItem('ingredients', { from: source.index, to: destination.index })
          }
        >
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Center>
          <Button
            size="xs"
            variant="outline"
            p={10}
            py={0}
            mt={15}
            mr={5}
            onClick={() => form.insertListItem('ingredients', { number: 1, measure: 'dash', ingredient: 'magic' })}
          >
            add ingredient
          </Button>
          <Button disabled size="xs" variant="outline" p={10} py={0} mt={15}>
            add category
          </Button>
        </Center>
        <Title size="h4" my={15}>
          Preview
        </Title>
        <Grid bg={theme.colors.gray[1]} mb={5}>
          <Grid.Col span="content">
            {form.values.ingredients.map((value, index) => (
              <Text>
                {value.number} {value.measure}
              </Text>
            ))}
          </Grid.Col>
          <Grid.Col span="auto">
            {form.values.ingredients.map((value, index) => (
              <Group>
                <Divider orientation="vertical" />
                <Text>{value.ingredient}</Text>
              </Group>
            ))}
          </Grid.Col>
        </Grid>
      </Box> */}
    </>
  );
}
