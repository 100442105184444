export const people = [
  {
    key: '0',
    name: 'Aaron',
    sex: 'M',
    mother: '-10',
    father: '-11',
    wife: '1',
    image: 'https://source.unsplash.com/icu-D9rYM4w',
  },
  {
    key: '1',
    name: 'Alice',
    sex: 'F',
    mother: '-12',
    father: '-13',
    husbane: '0',
    image: 'https://source.unsplash.com/z6SqzV2GWEs',
  },
  {
    key: '2',
    name: 'Bob',
    sex: 'M',
    mother: '1',
    father: '0',
    wife: '3',
    image: 'https://source.unsplash.com/rJcW1cmHg3E',
  },
  { key: '3', name: 'Barbara', sex: 'F', image: 'https://source.unsplash.com/n-hot-6qkZQ' },
  {
    key: '4',
    name: 'Bill',
    sex: 'M',
    mother: '1',
    father: '0',
    wife: '5',
    image: 'https://source.unsplash.com/CdcSrW0IxwE',
  },
  { key: '5', name: 'Brooke', sex: 'F', image: 'https://source.unsplash.com/O-FR79xcGh8' },
  {
    key: '6',
    name: 'Claire',
    sex: 'F',
    mother: '1',
    father: '0',
    image: 'https://source.unsplash.com/1FEjer4C7sI',
  },
  {
    key: '7',
    name: 'Carol',
    sex: 'F',
    mother: '1',
    father: '0',
    image: 'https://source.unsplash.com/eKf09DD_pdA',
  },
  {
    key: '8',
    name: 'Chloe',
    sex: 'F',
    mother: '1',
    father: '0',
    husband: '9',
    image: 'https://source.unsplash.com/YjdX3K8M0Yw',
  },
  { key: '9', name: 'Chris', sex: 'M', image: 'https://source.unsplash.com/_4gaNP2KI84' },
  {
    key: '10',
    name: 'Ellie',
    sex: 'F',
    mother: '3',
    father: '2',
    image: 'https://source.unsplash.com/k1Ps7mqEs_o',
  },
  {
    key: '11',
    name: 'Dan',
    sex: 'M',
    mother: '3',
    father: '2',
    image: 'https://source.unsplash.com/FooM1XbbufI',
  },
  {
    key: '12',
    name: 'Elizabeth',
    sex: 'F',
    husband: '13',
    image: 'https://source.unsplash.com/ZT6P9rZ2M98',
  },
  {
    key: '13',
    name: 'David',
    sex: 'M',
    mother: '5',
    father: '4',
    image: 'https://source.unsplash.com/lAbXOA6JXhE',
  },
  {
    key: '14',
    name: 'Emma',
    sex: 'F',
    mother: '5',
    father: '4',
    image: 'https://source.unsplash.com/xQckUF7O9n4',
  },
  {
    key: '15',
    name: 'Evan',
    sex: 'M',
    mother: '8',
    father: '9',
    image: 'https://source.unsplash.com/siInUYw-7no',
  },
  {
    key: '16',
    name: 'Ethan',
    sex: 'M',
    mother: '8',
    father: '9',
    image: 'https://source.unsplash.com/6CfWrV2qLN4',
  },
  {
    key: '17',
    name: 'Eve',
    sex: 'F',
    husband: '16',
    image: 'https://source.unsplash.com/gqlVdNQx2M4',
  },
  { key: '18', name: 'Emily', sex: 'F', mother: '8', father: '9', image: 'https://source.unsplash.com/LDz8N24yD9k' },
  {
    key: '19',
    name: 'Fred',
    sex: 'M',
    mother: '17',
    father: '16',
    image: 'https://source.unsplash.com/JQ2D4I-2eyw',
  },
  {
    key: '20',
    name: 'Faith',
    sex: 'F',
    mother: '17',
    father: '16',
    image: 'https://source.unsplash.com/bPeVIJBddg8',
  },
  {
    key: '21',
    name: 'Felicia',
    sex: 'F',
    mother: '12',
    father: '13',
    image: 'https://source.unsplash.com/wpR7xoq6VBQ',
  },
  {
    key: '22',
    name: 'Frank',
    sex: 'M',
    mother: '12',
    father: '13',
    image: 'https://source.unsplash.com/pp76Y6Fq6xw',
  },

  // "Aaron"'s ancestors
  {
    key: '-10',
    name: 'Garth',
    sex: 'M',
    mother: '-33',
    father: '-32',
    wife: '-11',
    image: 'https://source.unsplash.com/o-ioeoATbIM',
  },
  {
    key: '-11',
    name: 'Pattie',
    sex: 'F',
    image: 'https://source.unsplash.com/VMGAbeeJTKo',
  },
  {
    key: '-32',
    name: 'Paternal Great',
    sex: 'M',
    wife: '-33',
    image: 'https://source.unsplash.com/FzECg2eNhj4',
  },
  {
    key: '-33',
    name: 'Betty',
    sex: 'F',
    image: 'https://source.unsplash.com/QQsRTGAZp9o',
  },
  {
    key: '-40',
    name: 'Great Uncle',
    sex: 'M',
    mother: '-33',
    father: '-32',
    image: 'https://source.unsplash.com/08yfmU-PQUU',
  },
  {
    key: '-41',
    name: 'Great Aunt',
    sex: 'F',
    mother: '-33',
    father: '-32',
    image: 'https://source.unsplash.com/IL6M6cmhEpM',
  },
  {
    key: '-20',
    name: 'Uncle',
    sex: 'M',
    mother: '-11',
    father: '-10',
    image: 'https://source.unsplash.com/LPIAsTzeWck',
  },

  // "Alice"'s ancestors
  {
    key: '-12',
    name: 'Maternal Grandfather',
    sex: 'M',
    wife: '-13',
    image: 'https://source.unsplash.com/u8mWh3Midos',
  },
  {
    key: '-13',
    name: 'Maternal Grandmother',
    sex: 'F',
    mother: '-31',
    father: '-30',
    image: 'https://source.unsplash.com/Cah6BLepSQ0',
  },
  {
    key: '-21',
    name: 'Michelle',
    sex: 'F',
    mother: '-13',
    father: '-12',
    image: 'https://source.unsplash.com/wV1-SWB4n8',
  },
  { key: '-22', name: 'Uncle', sex: 'M', wife: '-21', image: 'https://source.unsplash.com/oRsSeYqJUgY' },
  {
    key: '-23',
    name: 'Cousin',
    sex: 'M',
    mother: '-21',
    father: '-22',
    image: 'https://source.unsplash.com/_wYQnEAMAQk',
  },
  {
    key: '-30',
    name: 'Maternal Great',
    sex: 'M',
    wife: '-31',
    image: 'https://source.unsplash.com/cSjrxoz4qW8',
  },
  {
    key: '-31',
    name: 'Moira',
    sex: 'F',
    mother: '-50',
    father: '-51',
    image: 'https://source.unsplash.com/eY1_nQs9aNI',
  },
  {
    key: '-42',
    name: 'Great Uncle',
    sex: 'M',
    mother: '-31',
    father: '-30',
    image: 'https://source.unsplash.com/LkQHGSVzfsk',
  },
  {
    key: '-43',
    name: 'Cher',
    sex: 'F',
    mother: '-31',
    father: '-30',
    image: 'https://source.unsplash.com/MMhazsT2wtM',
  },
  {
    key: '-50',
    name: 'Greta',
    sex: 'F',
    husband: '-51',
    image: 'https://source.unsplash.com/l9I93gZKTG4',
  },
  {
    key: '-51',
    name: 'Maternal Great Great',
    sex: 'M',
    image: 'https://source.unsplash.com/IeJfztZX9QM',
  },
];
