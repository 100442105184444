import ReactDOM from 'react-dom/client';
import Main from './Main';
import { Auth, Amplify } from 'aws-amplify';
import amplifyConfig from './amplifyConfig';
import { getTenant } from 'api/tenant-service';
import { create } from 'zustand';
import { ChimeApi } from 'api/chime-service';

Amplify.configure(amplifyConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const attributes = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(() =>
      Auth.currentCredentials().then((creds) => console.log(creds))
    );
    const attributes = await user.attributes;
    const email = attributes['email'];
    const userId = attributes['sub'];
    const picture = attributes['picture'];
    const darkMode = attributes['custom:darkMode'];
    const primaryColor = attributes['custom:primaryColor'];
    const secondaryColor = attributes['custom:secondaryColor'];
    const currentTenant = localStorage.getItem('CurrentTenantId') ?? attributes['custom:primaryTenant'];
    let tenant = await getTenant(currentTenant);
    console.log(tenant);
    if (!tenant.tenantId) {
      tenant = attributes['custom:primaryTenant'];
    }

    return {
      user: { email, userId, picture, currentTenant },
      tenant: tenant,
      chime: new ChimeApi(tenant.appInstanceArn ?? undefined, userId),
      theme: { darkMode, colors: { primaryColor, secondaryColor } },
    };
  } catch {
    return {
      user: { email: '', userId: '' },
      tenant: { tenantId: '', appInstanceId: '' },
      theme: { darkMode: 'light', colors: { primaryColor: 'blue', secondaryColor: 'teal' } },
    };
  }
};

// Global store
export const useStore = create<State>()((set) => ({
  rightbar: { opened: false, content: undefined },
  user: {},
  tenant: {},
  chime: {},
  theme: {},
  setRightbar: ({ opened, content }: Rightbar) =>
    set((state) => ({
      rightbar: { ...state.rightbar, opened, ...(content && { content }) },
    })),
  setTenant: (tenant: string | any) => {
    if (tenant.appInstanceArn) {
      set((state) => ({ chime: new ChimeApi(tenant.appInstanceArn, state.user.userId) }));
    }
    set(() => ({ tenant: tenant }));
  },
  setChime: (appInstanceArn: string, userId: string) => set(() => ({ chime: new ChimeApi(appInstanceArn, userId) })),
  setUser: (data: any) => set((state) => ({ user: { ...state.user, ...data } })),
}));

attributes().then((attributes) => {
  useStore.setState({ ...useStore.getState(), ...attributes });
  root.render(
    // <React.StrictMode>
    <Main />
    // </React.StrictMode>
  );
});

interface State {
  rightbar: Rightbar;
  user: User;
  tenant: Tenant;
  chime: any;
  theme: Theme;
  setTenant: Function;
  setChime: Function;
  setUser: Function;
}

interface UserAddress {
  street?: string;
  state?: string;
  zip?: number;
  country?: string;
}

interface UserSocial {
  facebook?: string;
  github?: string;
  google?: string;
  instagram?: string;
  linkedin?: string;
  mastodon?: string;
  pinterest?: string;
  snapchat?: string;
  spotify?: string;
  telegram?: string;
  tumblr?: string;
  twitch?: string;
  twitter?: string;
  website?: string;
  whatsapp?: string;
  youtube?: string;
}

interface User {
  joinedDate?: number;
  primaryTenant?: string;
  userId?: string;
  email?: string;
  fullName?: string;
  picture?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  prefix?: string;
  suffix?: string;
  phone?: number;
  address?: UserAddress;
  socialMedia?: UserSocial;
}

interface Theme {
  darkMode?: string;
  colors?: {
    primaryColor?: string;
    secondaryColor?: string;
  };
}

interface Tenant {
  tenantId?: string;
  tenantName?: string;
  color?: string;
  appInstanceArn?: string;
}

interface Rightbar {
  opened: boolean;
  content?: any;
}
