import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Group,
  NumberInput,
  Paper,
  Select,
  Stepper,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';

import { Footer } from '../../../Base/Footer/Footer';
import IngredientsForm from './Forms/Ingredients/IngredientsForm';
import PersonalizeForm from './Forms/Personalize/PersonalizeForm';
import DetailsForm from './Forms/Details/DetailsForm';
import PhotosForm from './Forms/Photos/PhotosForm';
import PreviewForm from './Forms/Preview/RecipePreview';

export default function AddRecipe() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const form = useForm({
    initialValues: {
      title: undefined,
      description: undefined,
      files: [],
      info: [
        { key: 'Cook Time', value: 'Quick' },
        { key: 'Total Time', value: 'Fast' },
        { key: 'Yield', value: '10 Hugs' },
        { key: 'Servings', value: '2-4' },
      ],
      ingredients: [
        { number: 1, measure: 'pinch', ingredient: 'love' },
        { number: 1, measure: 'dash', ingredient: 'magic' },
        { number: 1, measure: 'pluck', ingredient: 'courage' },
      ],
      directions: [
        { step: 1, value: 'First, call all your friends and family and share this recipe.' },
        { step: 2, value: 'Preheat the oven...' },
      ],
      html: '<h1 style="text-align: center;">Personalize your recipe!</h1><p>Use the Rich Text Editor to stylize your recipe. This content will appear between the Recipe\'s <b>Details</b> (1) and the Recipe\'s <b>Ingredients/Directions</b> (2). You can refer to the skeleton segments above and below this editor to get a feel of where this text will fit on the recipe.</p><h3>Customize</h3><p>This is the perfect place to add additional content to a recipe, variations, history/background of the dish, Anything you want.</p><h3>Formatting</h3><p>The following text formatting options are available:</p><ul><li>General text formatting: <strong>bold</strong>, <em>italic</em>, <u>underline</u>, <s>strike-through</s> </li><li>Headings (h1-h6)</li><li>Sub and super scripts (<sup>&lt;sup /&gt;</sup> and <sub>&lt;sub /&gt;</sub> tags)</li><li>Ordered and bullet lists</li><li>Text align&nbsp;</li><li><a href="" target="_blank" rel="noopener noreferrer">Links</a></li></ul>',
    },
  });

  return (
    <>
      <Container size="lg" mt={15}>
        <Stepper active={active} size="sm" onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Details">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <DetailsForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Ingredients/Directions">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <IngredientsForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Photos">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <PhotosForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Personalize">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <PersonalizeForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Review">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <PreviewForm form={form} />
            </Paper>
          </Stepper.Step>
          {/* <Stepper.Completed>Completed, click back button to get to previous step</Stepper.Completed> */}
        </Stepper>

        <Group position="center" mt="xl">
          <Button variant="default" onClick={() => navigate(-1)} mr="auto">
            Cancel
          </Button>
          <Button variant="default" onClick={prevStep} disabled={active == 0 ? true : false}>
            Back
          </Button>
          {active < 4 ? <Button onClick={nextStep}>Next step</Button> : <Button>Submit</Button>}
        </Group>
      </Container>
      <Footer />
    </>
  );
}
