import { FC, useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Drawer,
  Flex,
  Grid,
  Group,
  Paper,
  Transition,
  useMantineTheme,
  createStyles,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { shallow } from 'zustand/shallow';
import { useStore } from '../../index';
import { Sidebar } from '../Sidebar/Sidebar';
import { RightBar } from '../Sidebar/Rightbar';

const useStyles = createStyles((theme) => ({
  dropdown: {
    position: 'absolute',
    top: 56,
    left: '50%',
    overflow: 'hidden',
    zIndex: 1000,

    [theme.fn.smallerThan('sm')]: {
      left: '25%',
    },

    [theme.fn.smallerThan('xs')]: {
      left: 0,
    },
  },
}));

const ContentContainer: FC<any> = ({ children }) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const [rightbar, setRightbar] = useStore((state) => [state.rightbar, state.setRightbar]);
  const mdBreak = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const handleClose = () => {
    setRightbar({ opened: false });
  };

  return (
    <Container fluid pl={0} m={0} h={`calc(100vh - 56px)`} sx={{ overflow: 'hidden' }}>
      <Grid sx={{ overflow: 'hidden' }}>
        <Grid.Col span="content" pr={0}>
          <Box maw={300} sx={{ position: 'static', top: '56px', overflow: 'hidden' }}>
            <Sidebar handleClick={() => {}} width={{ sm: 300 }} hidden={true} />
          </Box>
        </Grid.Col>
        <Grid.Col span="auto" mx={0} px={0}>
          <Box sx={{ height: `calc(100vh - 56px)`, overflowY: 'auto' }}>{children}</Box>
        </Grid.Col>

        <Transition mounted={rightbar.opened} transition="slide-left" timingFunction="ease">
          {(styles) =>
            !mdBreak ? (
              <Grid.Col xl={3} span={4} mx={0} px={0}>
                <Box
                  sx={{
                    height: `calc(100vh - 56px)`,
                    overflowY: 'auto',
                    borderLeft: `2px solid ${
                      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]
                    }`,
                  }}
                >
                  <RightBar handleClose={handleClose} content={rightbar.content} />
                </Box>
              </Grid.Col>
            ) : (
              <Paper
                className={classes.dropdown}
                withBorder
                style={{
                  ...styles,
                  overflowY: 'auto',
                  background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                }}
              >
                <RightBar handleClose={handleClose} content={rightbar.content} />
              </Paper>
            )
          }
        </Transition>
      </Grid>
    </Container>
  );
};

export default ContentContainer;
