import React, { memo, ChangeEventHandler } from 'react';
import {
  Avatar,
  ActionIcon,
  Box,
  Button,
  Menu,
  Card,
  Divider,
  Drawer,
  Group,
  Container,
  Image,
  ScrollArea,
  Title,
  Paper,
  Text,
  Table,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconX, IconPlus } from '@tabler/icons';

import { Handle, Position } from 'reactflow';

interface PersonNodeProps {
  data: {
    label: string;
    sex: string;
    firstName: string;
    lastName: string;
    birth?: Date;
    death?: Date;
    image?: string;
    color: string;
    infoHandler: Function;
  };
}

const PersonNode = memo(({ data }: PersonNodeProps) => {
  const theme = useMantineTheme();
  const [open, setOpen] = React.useState(false);
  const backgroundColor = theme.colorScheme == 'dark' ? theme.colors.dark[7] : theme.white;
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const mdBreak = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const lgBreak = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        radius="lg"
        sx={{
          display: 'flex',
          overflow: 'visible',
          background: backgroundColor,
          border: `3px solid ${data.sex == 'F' ? 'lightpink' : 'lightblue'}`,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 250, maxHeight: 200 }}>
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar
              alt="Daniel Di Salvo"
              src={data.image}
              radius={50}
              mt="-50px"
              w={100}
              h={100}
              sx={{
                border: `2px solid ${data.sex == 'F' ? 'lightpink' : 'lightblue'}`,
              }}
            />
          </Container>
          <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Title lineClamp={2} size="h4">
              {data.label}
            </Title>
            <Text color="dimmed" fs="italic">
              01/01/1990 - 01/01/2075
            </Text>
            <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
              <Button variant="subtle" onClick={handleDrawerOpen}>
                more info
              </Button>
            </Box>
          </Container>
        </Box>
      </Card>
      <Handle type="source" position={Position.Top} id="top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="left" style={{ opacity: 0 }} />
      <Drawer
        position="right"
        size={mdBreak ? '90%' : lgBreak ? '50%' : '30%'}
        opened={open}
        withCloseButton={false}
        onClose={handleDrawerClose}
      >
        <Box style={{ height: '100vh', overflowY: 'auto', position: 'relative' }}>
          <Box pt={15} pl={15}>
            <ActionIcon onClick={handleDrawerClose}>{<IconX />}</ActionIcon>
          </Box>
          {/* <Box sx={{ height: 56 }}></Box> */}
          <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar
              alt="Daniel Di Salvo"
              src={data.image}
              radius="sm"
              sx={{
                width: '15vw',
                height: '15vw',
                border: `1px solid ${data.sex == 'F' ? 'lightpink' : 'lightblue'}`,
              }}
            />
          </Container>

          <Container
            fluid
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Title size="h3">{data.label}</Title>
            <Text fs="italic" color="dimmed">
              01/01/1990 - 01/01/2075
            </Text>
            <Button.Group>
              <Button variant="outline">Photos</Button>
              <Button variant="outline">Documents</Button>
              <Button variant="outline">Timeline</Button>
            </Button.Group>
            <Container p={10} size="lg">
              <Divider orientation="horizontal" />
            </Container>
            <Table striped aria-label="simple table">
              <tbody>
                <tr>
                  <td>Pronunciation</td>
                  <td align="right">pronunciation</td>
                </tr>
                <tr>
                  <td>Sex</td>
                  <td align="right">sex</td>
                </tr>
                <tr>
                  <td>Born</td>
                  <td align="right">
                    <>
                      birth_name <br></br>birth_date <br></br> birth_place
                    </>
                  </td>
                </tr>
                <tr>
                  <td>Disappeared</td>
                  <td align="right">
                    <>
                      disappeared_date <br></br>disappeared_place
                    </>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td align="right">disappeared_status</td>
                </tr>
                <tr>
                  <td>Died</td>
                  <td align="right">
                    <>
                      death_date <br></br>death_place
                    </>
                  </td>
                </tr>
                <tr>
                  <td>Cause of death</td>
                  <td align="right">death_cause</td>
                </tr>
                <tr>
                  <td>Resting place</td>
                  <td align="right">
                    <>
                      resting_place <br></br>resting_place_coordinates
                    </>
                  </td>
                </tr>
                <tr>
                  <td>Nationality</td>
                  <td align="right">nationality</td>
                </tr>
                <tr>
                  <td>Other names</td>
                  <td align="right">other_names</td>
                </tr>
                <tr>
                  <td>Siglum</td>
                  <td align="right">siglum</td>
                </tr>
                <tr>
                  <td>Citizenship</td>
                  <td align="right">citizenship</td>
                </tr>
                <tr>
                  <td>Education</td>
                  <td align="right">education</td>
                </tr>
                <tr>
                  <td>Alma mater</td>
                  <td align="right">alma_mater</td>
                </tr>
                <tr>
                  <td>Occupation</td>
                  <td align="right">occupation</td>
                </tr>
                <tr>
                  <td>Notable work</td>
                  <td align="right">notable_works</td>
                </tr>
                <tr>
                  <td>Height</td>
                  <td align="right">height</td>
                </tr>
                <tr>
                  <td>Spouse</td>
                  <td align="right">spouse</td>
                </tr>
                <tr>
                  <td>Partner</td>
                  <td align="right">partner</td>
                </tr>
                <tr>
                  <td>Children</td>
                  <td align="right">children</td>
                </tr>
                <tr>
                  <td>Parent</td>
                  <td align="right">parents</td>
                </tr>
                <tr>
                  <td>Awards</td>
                  <td align="right">awards</td>
                </tr>
              </tbody>
            </Table>
          </Container>
        </Box>
      </Drawer>
    </>
  );
});

export default PersonNode;
