import { useState, forwardRef, HTMLInputTypeAttribute, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Menu,
  Group,
  Center,
  Grid,
  LoadingOverlay,
  Chip,
  Container,
  Divider,
  Flex,
  Space,
  Tabs,
  Title,
  Avatar,
  Paper,
  Select,
  Text,
  ThemeIcon,
  TextInput,
  createStyles,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus, IconPhone, IconAt, IconMapPin } from '@tabler/icons';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { listTenants, getTenant } from '../../../../api/tenant-service';

import { prefixes, suffixes, states, countries } from '../../utils/data';

const useStyles = createStyles((theme) => ({
  responsiveHide: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  searchbar: {
    width: '75%',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

const userRoleColorMap = { OWNER: 'grape', ADMIN: 'blue', MEMBER: 'gray', VIEWER: 'dark' };

export default function Recipes() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    listTenants().then((tenants) => {
      setTenants(tenants);
      setLoading(false);
    });
  }, []);

  return (
    // <Tabs.Panel value="families" pt="sm">
    <Container fluid m={0} p={0}>
      <Box>
        <Container fluid py={theme.spacing.xl}>
          <Grid justify="flex-start">
            <div style={{ position: 'relative' }}>
              <LoadingOverlay visible={loading} overlayBlur={2} />
              <Grid.Col sm={12} pr={theme.spacing.xl}>
                <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                  {tenants.length} family ties
                </Title>
                <Text>These are the families you are a member of.</Text>
              </Grid.Col>
              <Grid.Col sm={12}>
                {tenants.map((tenant) => (
                  <Container fluid>
                    <Divider mb={theme.spacing.lg} />
                    <Grid justify="space-between">
                      <Grid.Col span="content">
                        <Box pb={theme.spacing.lg}>
                          <Title size="h5">{tenant.tenantName}</Title>
                          {/* <Text size="xs" color="dimmed">
                          cdb4607a-c952-4e8c-bfc5-0b36c45b09e0
                        </Text> */}
                        </Box>
                      </Grid.Col>
                      <Grid.Col span="content">
                        <Badge color={userRoleColorMap[tenant.userRole]}>{tenant.userRole}</Badge>
                      </Grid.Col>
                    </Grid>
                  </Container>
                ))}
              </Grid.Col>
            </div>
          </Grid>
        </Container>
      </Box>
    </Container>
    // </Tabs.Panel>
  );
}
