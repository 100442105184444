import { useState, forwardRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Group,
  Avatar,
  Badge,
  ColorSwatch,
  Divider,
  Text,
  Menu,
  Indicator,
  UnstyledButton,
  Flex,
  Center,
  Transition,
  Paper,
  ActionIcon,
  useMantineColorScheme,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import {
  IconChevronRight,
  IconChevronDown,
  IconCloud,
  IconFriends,
  IconUser,
  IconSettings,
  IconBed,
  IconSearch,
  IconPhoto,
  IconMessageCircle,
  IconDoorExit,
  IconArrowsLeftRight,
  IconSun,
  IconMoonStars,
  IconPalette,
  IconBell,
} from '@tabler/icons';
import { useStore } from '../../index';
import { useIdle, useMediaQuery, upperFirst } from '@mantine/hooks';
import { useAuthenticator } from '@aws-amplify/ui-react';

const useStyles = createStyles((theme) => ({
  responsiveHide: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  dropdown: {
    position: 'absolute',
    top: 56,
    left: '75%',
    right: '100%',
    width: '100%',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('xs')]: {
      left: '50%',
    },
  },

  linkLabel: {
    marginRight: 5,
  },
}));

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  name: string;
  email: string;
  icon?: React.ReactNode;
  idle?: boolean;
}

const StyledButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, icon, idle, ...rest }: UserButtonProps, ref) => {
    const { classes } = useStyles();

    return (
      <UnstyledButton
        ref={ref}
        sx={(theme) => ({
          display: 'flex',
          padding: theme.spacing.md,
          margin: 0,
          height: 56,
          alignItems: 'center',
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.white,
          backgroundImage:
            theme.colorScheme === 'dark'
              ? theme.fn.gradient({ from: theme.colors.dark[6], to: theme.primaryColor, deg: 90 })
              : theme.fn.gradient({
                  from: theme.primaryColor,
                  to: theme.colors[theme.other.secondaryColor ?? 'teal'][theme.primaryShade[theme.colorScheme]],
                  deg: 90,
                }),
        })}
        {...rest}
      >
        <Group>
          <div className={classes.responsiveHide} style={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {name}
            </Text>
            <Text size="xs">{email}</Text>
          </div>
          <Indicator color={idle ? 'yellow' : 'green'} size={12} offset={2} position="bottom-end" withBorder>
            <Avatar src={image} radius="xl" />
          </Indicator>
        </Group>
      </UnstyledButton>
    );
  }
);

export default function UserButton() {
  const { classes } = useStyles();
  const idle = useIdle(300000, { initialState: false });
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [opened, setOpened] = useState(false);
  const setRightbar = useStore((state) => state.setRightbar);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <Group ml="auto" mr={-5}>
      <Menu
        opened={opened}
        onChange={setOpened}
        transition="pop-top-right"
        offset={0}
        position="bottom-end"
        width={useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`) ? '75%' : 'target'}
      >
        <Menu.Target>
          <StyledButton
            image={user?.attributes?.picture}
            name={`${user?.attributes?.given_name ?? ''} ${user?.attributes?.family_name ?? ''}`}
            email={user?.attributes?.email}
            idle={idle}
          />
        </Menu.Target>

        <Menu.Dropdown className={classes.dropdown}>
          <Menu.Label>Status</Menu.Label>
          <Menu.Item icon={<IconBed size={18} />}>
            <Group>
              <Text>Status</Text>
              <Badge variant="filled" ml="auto" color={idle ? 'yellow' : 'green'}>
                {idle ? 'idle' : 'active'}
              </Badge>
            </Group>
          </Menu.Item>
          <Menu.Item icon={<IconBell size={18} />}>Notifications</Menu.Item>
          <Menu.Divider />
          <Menu.Label>Preferences</Menu.Label>
          <Menu.Item icon={<IconUser size={18} />} onClick={() => setRightbar({ opened: true, content: 'Profile' })}>
            Profile
          </Menu.Item>
          <Menu.Item icon={<IconFriends size={18} />} onClick={() => setRightbar({ opened: true, content: 'Family' })}>
            Family
          </Menu.Item>
          <Menu.Item icon={<IconPalette size={18} />} onClick={() => setRightbar({ opened: true, content: 'Theme' })}>
            Theme
          </Menu.Item>

          {/* <Menu.Item
            color={colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[4]}
            icon={colorScheme === 'dark' ? <IconSun size={18} /> : <IconMoonStars size={18} />}
            onClick={() => toggleColorScheme()}
          >
            {upperFirst(colorScheme === 'light' ? 'dark' : 'light')} mode
          </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item color="red" onClick={signOut} icon={<IconDoorExit size={14} />}>
            Log out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
