import { useState, forwardRef, ReactElement } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {
  createStyles,
  Box,
  Button,
  Menu,
  Group,
  Center,
  Grid,
  Container,
  Divider,
  Space,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';

import CardCarousel from '../../Components/Carousel/CardCarousel';
import FeaturedMain from '../../Components/Featured/FeaturedMain';
import CategoryChips from '../../Components/Categories/CategoryChips';
import FeaturedCookbook from '../../Components/Featured/FeaturedCoobook';
import RecipeCard from '../../Components/Cards/RecipeCard';

import * as data from '../../data/index';

const useStyles = createStyles((theme) => ({
  responsiveHide: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  searchbar: {
    width: '75%',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

export default function Recipes() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <Box>
        <FeaturedMain main={data.main} />
        <Container className={classes.searchbar} my={theme.spacing.xl}>
          <TextInput size="lg" m="5" placeholder="What's cooking?" icon={<IconSearch />} />
        </Container>
        <CategoryChips categories={data.categories} />
        <Box my={theme.spacing.xl}>
          <Center sx={{ justifyContent: 'flex-start' }}>
            <Title size="h3" pr={15} sx={{ display: 'inline' }}>
              Cookbooks
            </Title>

            <Button
              size="xs"
              variant="filled"
              p={10}
              py={0}
              rightIcon={<IconPlus size={16} />}
              onClick={() => navigate('/recipes/cookbook/new')}
            >
              new
            </Button>
          </Center>
        </Box>
        {data.books.length > 0 ? (
          <CardCarousel>
            {data.books.map((cookbook) => (
              <FeaturedCookbook cookbook={cookbook} />
            ))}
          </CardCarousel>
        ) : (
          <Container
            size="md"
            bg={theme.colorScheme == 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]}
            p={theme.spacing.md}
            sx={{ borderRadius: 5 }}
          >
            <Center>
              <Text color="dimmed" fz="xl" pr={15}>
                Get started with a new cookbook
              </Text>
            </Center>
            <Center>
              <Button
                size="md"
                variant="default"
                p={10}
                py={0}
                ml="5"
                onClick={() => navigate('/recipes/cookbook/new')}
              >
                Create my first cookbook
              </Button>
            </Center>
          </Container>
        )}

        <Box my={theme.spacing.xl}>
          <Center sx={{ justifyContent: 'flex-start' }}>
            <Title size="h3" pr={15} sx={{ display: 'inline' }}>
              Recently added
            </Title>
            <Button
              size="xs"
              variant="filled"
              p={10}
              py={0}
              rightIcon={<IconPlus size={16} />}
              onClick={() => navigate('/recipes/new')}
            >
              new
            </Button>
          </Center>
        </Box>
        {data.books.length > 0 ? (
          <CardCarousel slideSize="sm">
            {data.recipes.map((recipe) => (
              <RecipeCard recipe={recipe} />
            ))}
          </CardCarousel>
        ) : (
          <Container
            size="md"
            bg={theme.colorScheme == 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]}
            p={theme.spacing.md}
            sx={{ borderRadius: 5 }}
          >
            <Center>
              <Text color="dimmed" fz="xl" pr={15}>
                Get started with a new recipe
              </Text>
            </Center>
            <Center>
              <Button size="md" variant="default" p={10} py={0} ml="5" onClick={() => navigate('/recipes/new')}>
                Create my first recipe
              </Button>
            </Center>
          </Container>
        )}
      </Box>
    </>
  );
}
