// import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useCallback, useRef, Children } from 'react';
import { createStyles, Box, Button, Menu, Progress, useMantineTheme, MantineNumberSize } from '@mantine/core';
import { IconChevronRight, IconChevronLeft } from '@tabler/icons';
import { Carousel, Embla } from '@mantine/carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

const useStyles = createStyles((theme) => ({
  carouselBox: {
    marginRight: 0,
    marginLeft: 0,
    [theme.fn.smallerThan('sm')]: {
      marginRight: 5,
      marginLeft: 5,
    },
  },
}));

export default function CardCarousel({ children, slideSize = 'md', gap = 'xs' }) {
  const theme = useMantineTheme();
  const wheelGestures = useRef(WheelGesturesPlugin({ forceWheelAxis: 'x' }));
  const [scrollProgress, setScrollProgress] = useState(0);
  const [embla, setEmbla] = useState<Embla | null>(null);

  const handleScroll = useCallback(() => {
    if (!embla) return;
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [embla, setScrollProgress]);

  useEffect(() => {
    if (embla) {
      embla.on('scroll', handleScroll);
      handleScroll();
    }
  }, [embla]);

  let slideWidth;
  let count = Children.count(children);
  switch (slideSize) {
    case 'xl': {
      slideWidth = '95%';
      break;
    }
    case 'lg': {
      slideWidth = '70%';
      break;
    }
    case 'md': {
      slideWidth = count * 45 > 100 ? '45%' : `${Math.min(50, Math.floor(100 / count))}%`;
      break;
    }
    case 'sm': {
      slideWidth = count * 22 > 100 ? '22%' : `${Math.min(50, Math.floor(100 / count))}%`;
      break;
    }
    case 'xs': {
      slideWidth = count * 18 > 100 ? '18%' : `${Math.min(50, Math.floor(100 / count))}%`;
      break;
    }
    default: {
      slideWidth = '45%';
      break;
    }
  }
  Children.count(children);

  return (
    <Box mx={25}>
      <Carousel
        slideSize={slideWidth}
        align="start"
        slideGap={gap as MantineNumberSize}
        controlsOffset={-100}
        controlSize={36}
        dragFree
        inViewThreshold={0.75}
        getEmblaApi={setEmbla}
        nextControlIcon={<IconChevronRight size={24} />}
        previousControlIcon={<IconChevronLeft size={24} />}
        plugins={[wheelGestures.current]}
        breakpoints={[
          { maxWidth: 'sm', slideSize: '70%' },
          { maxWidth: 'xs', slideSize: '93%', slideGap: 10 },
        ]}
        styles={{
          control: {
            background: 'transparent',
            border: 0,
            color: theme.colorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[7],
            boxShadow: '0px 0px',
            transform: 'scale(1,1.5)',
            '&[data-inactive]': {
              opacity: 0,
              cursor: 'default',
            },
          },
          controls: {
            right: -45,
            left: -45,
            background: 'transparent',
            boxShadow: '0px 0px',

            [theme.fn.smallerThan('sm')]: {
              right: 5,
              left: 5,
            },
          },
          root: {
            // position: 'absolute',
            overflow: 'visible',
            width: '100%',
            marginLeft: 'auto',
          },
        }}
      >
        {Children.map(children, (child) => {
          return <Carousel.Slide>{child}</Carousel.Slide>;
        })}
        {/* <Carousel.Slide>{children[0]}</Carousel.Slide> */}
      </Carousel>
      {Children.count(children) > 0 && (
        <Progress value={scrollProgress} styles={{ bar: { transitionDuration: '0ms' } }} size="xs" mt="xl" mx="auto" />
      )}
    </Box>
  );
}
