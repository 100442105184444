import { Auth } from 'aws-amplify';

const oauth = {
  domain: 'auth.courtney.cloud',
  scope: ['email', 'openid', 'phone', 'aws.cognito.signin.user.admin'],
  redirectSignIn: 'http://dev.localhost:3000', //https://www.courtney.cloud',
  redirectSignOut: 'http://dev.localhost:3000/login', //'https://www.courtney.cloud/login',
  responseType: 'code',
};

export default {
  Auth: {
    oauth,
    region: 'us-east-1',
    identityPoolId: 'us-east-1:5ff631ba-aa09-4d54-8cc2-de063f6b6131',
    userPoolId: 'us-east-1_QYuvuamg2',
    userPoolWebClientId: '20ptcq3n1a59i8ndqtp8169kh1',
  },
  API: {
    endpoints: [
      {
        name: 'CourtneyCloud',
        endpoint: 'https://api.courtney.cloud/',
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }),
      },
    ],
  },
};
