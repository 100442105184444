/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  createStyles,
  Group,
  Modal,
  Text,
  Title,
  TextInput,
  Button,
  Image,
  useMantineTheme,
} from '@mantine/core';
import { useStore } from '../../../../index';
import { PopOverItem } from '../PopOver/PopOverItem';
import { InfiniteList } from '../InfiniteList/InfiniteList';
import { ChatBubble } from '../Chat/ChatBubble/ChatBubble';
import { EditableChatBubble } from '../Chat/ChatBubble/EditableChatBubble';
import { formatTime } from '../../utilities/formatTime';
import { formatDate } from 'Views/Chat/utilities/formatDate';

// import { AttachmentProcessor } from './AttachmentProcessor';
import insertDateHeaders from '../../utilities/insertDateHeaders';
import { useChatChannelState, useChatMessagingState } from '../../providers/MessagingProvider';

const Messages = ({
  messages,
  messagesRef,
  setMessages,
  channelArn,
  channelName,
  userId,
  setChannelMessageToken,
  activeChannelRef,
}) => {
  const theme = useMantineTheme();
  // const [user] = useStore((state) => [state.user]);
  const { chime, channelMessageTokenRef } = useChatChannelState();
  const [isLoading, setIsLoading] = useState(false);

  const handleScrollTop = async () => {
    setIsLoading(true);
    if (!channelMessageTokenRef.current) {
      console.log('No new messages');
      setIsLoading(false);
      return;
    }
    const oldMessages = await chime.listChannelMessages(
      activeChannelRef.current.ChannelArn,
      userId,
      channelMessageTokenRef.current
    );
    const newMessages = [...oldMessages.Messages, ...messagesRef.current];

    setMessages(newMessages);
    setChannelMessageToken(oldMessages.NextToken);
    setIsLoading(false);
  };

  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showRedactModal, setShowRedactModal] = useState(false);

  const [editingMessageId, setEditingMessageId] = useState('');
  const [redactingMessageId, setRedactingMessageId] = useState('');

  const handleDiscardEdit = () => {
    setShowDiscardModal(false);
    setEditingMessageId('');
  };

  const discardModal = (
    <Modal opened={showDiscardModal} onClose={() => setShowDiscardModal(false)} title="Discard Changes?">
      <div>You cannot undo this action.</div>
      <Group position="center" mt="xl">
        <Button type="submit" color={theme.other.secondaryColor} onClick={handleDiscardEdit} key="1">
          Discard
        </Button>
        <Button variant="default" onClick={() => setShowDiscardModal(false)} mr="auto" key="2">
          Cancel
        </Button>
      </Group>
    </Modal>
  );

  const handleShowRedactModal = (messageId) => {
    setRedactingMessageId(messageId);
    setShowRedactModal(true);
  };

  const handleCloseRedactModal = () => {
    setRedactingMessageId('');
    setShowRedactModal(false);
  };

  const redact = async () => {
    await chime.redactChannelMessage(channelArn, redactingMessageId, userId);
    setShowRedactModal(false);
  };

  const redactModal = (
    <Modal opened={showRedactModal} onClose={handleCloseRedactModal} title="Delete Message?">
      <div>You cannot undo this action.</div>
      <Group position="center" mt="xl">
        <Button type="submit" color={theme.other.secondaryColor} onClick={redact} key="1">
          Delete
        </Button>
        <Button variant="default" onClick={() => handleCloseRedactModal()} mr="auto" key="2">
          Cancel
        </Button>
      </Group>
    </Modal>
  );

  const cancelEdit = (e) => {
    e.preventDefault();
    setShowDiscardModal(true);
  };

  const saveEdit = async (e, newText, metadata) => {
    e.preventDefault();
    await chime.updateChannelMessage(channelArn, editingMessageId, newText, metadata, userId);
    setEditingMessageId('');
  };

  const flattenedMessages = messages.map((m) => {
    const content = !m.Content || m.Redacted ? '(Deleted)' : m.Content;
    let editedNote;
    if (m.LastEditedTimestamp && !m.Redacted) {
      const time = formatTime(m.LastEditedTimestamp);
      const date = formatDate(m.LastEditedTimestamp, undefined, undefined, 'today', 'yesterday');
      editedNote = <i style={{ fontStyle: 'italic' }}>{` (edited ${date} at ${time})`}</i>;
    }
    return {
      content: content,
      editedNote: editedNote,
      messageId: m.MessageId,
      createdTimestamp: m.CreatedTimestamp,
      redacted: m.Redacted,
      senderName: m.Sender.Name,
      senderId: m.Sender.Arn,
      metadata: m.Metadata,
    };
  });

  const listItems = insertDateHeaders(flattenedMessages);

  const messageList = listItems.map((m, i, self) => {
    if (!m.content) {
      return m;
    }

    if (m.Metadata) {
      let metadata = JSON.parse(m.Metadata);
      if (metadata.isMeetingInfo) {
        return m;
      }
    }

    const variant = chime.memberArn === m.senderId ? 'outgoing' : 'incoming';
    let actions = null;
    if (variant === 'outgoing') {
      actions = [
        <PopOverItem key="1" children={<span>Edit</span>} onClick={() => setEditingMessageId(m.messageId)} />,
        <PopOverItem key="2" children={<span>Delete</span>} onClick={() => handleShowRedactModal(m.messageId)} />,
      ];
    }

    const prevMessageSender = self[i - 1]?.senderId;
    const currMessageSender = m.senderId;
    const nextMessageSender = self[i + 1]?.senderId;

    let showTail = true;
    if (
      currMessageSender && // it is a message
      nextMessageSender && // the item after is a message
      currMessageSender === nextMessageSender // the item after is from the same sender
    ) {
      showTail = false;
    }
    let showName = true;
    if (
      currMessageSender && // it is a message
      prevMessageSender && // the item before is a message
      currMessageSender === prevMessageSender // the message before is from the same sender
    ) {
      showName = false;
    }

    // const attachment = (metadata) => {
    //   try {
    //     const metadataJSON = JSON.parse(metadata);
    //     return metadataJSON?.attachments[0];
    //   } catch (err) {
    //     // not an json object! ignoring
    //   }
    //   return false;
    // };

    return (
      <Box
        // timestamp={formatTime(m.createdTimestamp)}
        // actions={actions}
        key={`message${i.toString()}`}
        // css="margin: 1rem;"
        // style={{ display: 'block', flexBasis: 'auto' }}
      >
        {editingMessageId === m.messageId && !m.redacted ? (
          <EditableChatBubble
            variant={variant}
            senderName={m.senderName}
            senderId={m.senderId}
            content={m.content}
            save={(event, value) => saveEdit(event, value, m.metadata)}
            cancel={cancelEdit}
            showName={showName}
            showTail={showTail}
          />
        ) : (
          <ChatBubble
            variant={variant}
            senderName={m.senderName}
            senderId={m.senderId}
            redacted={m.redacted}
            showName={showName}
            showTail={showTail}
          >
            <div>
              {m.content}
              {m.editedNote}
            </div>
            {/* {m.metadata && attachment(m.metadata) && (
                <div style={{ marginTop: '10px' }}>
                  <AttachmentProcessor senderId={m.senderId} {...attachment(m.metadata)} />
                </div>
              )} */}
          </ChatBubble>
        )}
      </Box>
    );
  });

  return (
    <Box>
      {showDiscardModal && discardModal}
      {showRedactModal && redactModal}
      <div>{channelName}</div>
      <InfiniteList items={messageList} onLoad={handleScrollTop} isLoading={isLoading} />
    </Box>
  );
};
export default Messages;
