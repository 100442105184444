import { useContext, useState, createContext } from 'react';
const UserPermissionContext = createContext({} as any);

const UserPermissionProvider = ({ children }) => {
  const [role, setRole] = useState('user');
  const providerValue = {
    role,
    setRole,
  };

  return <UserPermissionContext.Provider value={providerValue}>{children}</UserPermissionContext.Provider>;
};

const useUserPermission = () => {
  const context = useContext(UserPermissionContext);

  if (!context) {
    throw new Error('useUserPermission must be used within UserPermissionProvider');
  }

  return context;
};

export { UserPermissionProvider, useUserPermission };
