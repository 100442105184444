import React, { useState, useRef, useEffect } from 'react';
import { Container, createStyles, Text, Title, TextInput, Button, Image, Stack } from '@mantine/core';

const MessageInput = ({ activeChannelArn, member, hasMembership, chime }) => {
  const [text, setText] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const resetState = () => {
    setText('');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeChannelArn]);

  const onChange = (e) => {
    setText(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    await chime.sendChannelMessage(activeChannelArn, text, 'PERSISTENT', 'STANDARD', member);
    resetState();
  };

  if (hasMembership) {
    return (
      <div className="message-input-container">
        <form onSubmit={onSubmit} className="message-input-form">
          <TextInput
            onChange={onChange}
            value={text}
            type="text"
            placeholder="Type your message"
            autoFocus
            className="text-input"
            ref={inputRef}
          />
        </form>
      </div>
    );
  }
  return <div className="message-input-container join-channel-message">Join this channel to send messages.</div>;
};

export default MessageInput;
