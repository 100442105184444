import { useMemo } from 'react';
import {
  createStyles,
  Box,
  Button,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Flex,
  Image,
  Tabs,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';
import { Footer } from '../../Base/Footer/Footer';

import Masonry from '@mui/lab/Masonry';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function ImageMasonry() {
  const theme = useMantineTheme();
  const mode = theme.colorScheme;
  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                primary: {
                  main: theme.colors.blue[6],
                  contrastText: '#fff',
                },
                secondary: {
                  main: '#0277bd',
                },
                background: {
                  default: theme.white,
                },
              }
            : {
                primary: {
                  main: theme.colors.blue[4],
                  contrastText: '#fff',
                },
                secondary: {
                  main: '#ab47bc',
                },
                background: {
                  default: theme.colors.dark[7],
                },
              }),
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <ScopedCssBaseline>
        <Container size="lg">
          <Tabs defaultValue="explore" aria-label="navigate actions" sx={{ overflow: 'auto' }}>
            <Tabs.List h="50px" miw="20rem">
              <Tabs.Tab value="explore">Explore</Tabs.Tab>
              <Tabs.Tab value="albums">Albums</Tabs.Tab>
              <Tabs.Tab value="family">Family</Tabs.Tab>
              <Tabs.Tab value="upload" ml="auto">
                Upload
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="explore" pt="xs">
              <Box my={theme.spacing.md}>
                <Title size="h3">Recently added</Title>

                <Masonry columns={4} spacing={1}>
                  {itemData.map((item, index) => (
                    <div key={index}>
                      <img
                        src={`${item.img}?w=162&auto=format`}
                        srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: 'block',
                          width: '100%',
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
                <Center>
                  <Button size="md" variant="outline">
                    load more
                  </Button>
                </Center>
              </Box>
              <Box my={theme.spacing.md}>
                <Title size="h3">Your family</Title>
                <Masonry columns={4} spacing={1}>
                  {itemData.map((item, index) => (
                    <div key={index}>
                      <Image
                        src={`${item.img}?w=162&auto=format`}
                        alt={item.title}
                        withPlaceholder={true}
                        imageProps={{ srcSet: `${item.img}?w=162&auto=format&dpr=2 2x`, loading: 'lazy' }}
                        style={{
                          borderBottomLeftRadius: 4,
                          borderBottomRightRadius: 4,
                          display: 'block',
                          width: '100%',
                        }}
                      />
                    </div>
                  ))}
                </Masonry>
                <Center>
                  <Button size="md" variant="outline">
                    load more
                  </Button>
                </Center>
              </Box>
            </Tabs.Panel>
          </Tabs>
        </Container>
        <Footer />
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
    title: 'Snacks',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
    title: 'Tower',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d',
    title: 'Tree',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1627000086207-76eabf23aa2e',
    title: 'Camping Car',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7',
    title: 'Mountain',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
];
