import { useState, useCallback } from 'react';
import { Box, Button, Center, Container, Flex, Slider } from '@mantine/core';
import { IconZoomIn } from '@tabler/icons';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from './utils/cropImage';

export const PhotoCrop = ({ handleImage, image, shape, aspect = 16 / 9 }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
      console.log('done', croppedImage);
      handleImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: 0 }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: '80px', padding: 0 }}>
        <Cropper
          image={image.image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropShape={shape}
          minZoom={0.5}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: '50%',
          transform: 'translateX(-50%)',
          height: '80px',
        }}
      >
        <Box sx={{ position: 'relative', display: 'inline' }}>
          <Slider
            value={zoom}
            min={0.5}
            max={3}
            step={0.1}
            label={(v) => Math.floor((100 * (v - 1)) / (3 - 1)) + '%'}
            aria-labelledby="Zoom"
            onChange={setZoom}
            size="lg"
            thumbSize={26}
            thumbChildren={<IconZoomIn size={16} />}
            styles={{ thumb: { borderWidth: 2, padding: 3 } }}
            sx={{ padding: '22px 0px' }}
          />
        </Box>
        <Center>
          <Button onClick={cropImage}>Crop</Button>
        </Center>
      </Box>
    </Box>
  );
};
