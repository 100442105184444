import { memo, useEffect, useState } from 'react';
import {
  Avatar,
  ActionIcon,
  Box,
  Button,
  Menu,
  Card,
  Divider,
  Drawer,
  Group,
  Container,
  Image,
  Space,
  Title,
  Paper,
  Text,
  Table,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useStore, useStoreApi, useReactFlow, Panel } from 'reactflow';
import type { ReactFlowState } from '@reactflow/core';

const isInteractiveSelector = (s: ReactFlowState) => s.nodesDraggable && s.nodesConnectable && s.elementsSelectable;

interface CustomControlsProps {
  vertical?: Function;
  horizontal?: Function;
  sx?: any;
}

function CustomControls({ vertical, horizontal, sx }: CustomControlsProps) {
  const theme = useMantineTheme();
  const store = useStoreApi();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const isInteractive = useStore(isInteractiveSelector);
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const buttonColor = theme.colorScheme == 'dark' ? theme.colors.dark[6] : theme.white;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!isVisible) {
    return null;
  }

  const onToggleInteractivity = () => {
    store.setState({
      nodesDraggable: !isInteractive,
      nodesConnectable: !isInteractive,
    });
  };

  const style = {
    position: 'absolute',
    zIndex: 10,
    ...sx,
  };

  return (
    <Box p={15} sx={style}>
      <Box p={0}>
        <Button.Group
          orientation={xsBreak ? 'horizontal' : 'vertical'}
          aria-label="vertical outlined button group"
          sx={{ background: buttonColor }}
        >
          <Button size="sm" px={5} variant="outline" onClick={() => zoomIn()}>
            <AddIcon />
          </Button>
          <Button size="sm" px={5} variant="outline" onClick={() => zoomOut()}>
            <RemoveIcon />
          </Button>
          <Button size="sm" px={5} variant="outline" onClick={() => fitView()}>
            <FullscreenIcon />
          </Button>
          <Button size="sm" px={5} variant="outline" onClick={onToggleInteractivity}>
            {isInteractive ? <LockOpenIcon /> : <LockIcon />}
          </Button>
          {horizontal && (
            <Button size="sm" px={5} variant="outline" onClick={() => horizontal()}>
              <SwapHorizIcon />
            </Button>
          )}
          {vertical && (
            <Button size="sm" px={5} variant="outline" onClick={() => vertical()}>
              <SwapVertIcon />
            </Button>
          )}
        </Button.Group>
      </Box>
    </Box>
  );
}

export default CustomControls;
