import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Group,
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  Image,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import Messages from '../../components/Messages/Messages';
import { useStore } from '../../../../index';
// import { ChimeApi } from '../../../../api/chime-service';
import { useChatChannelState, useChatMessagingState } from '../../providers/MessagingProvider';
import { useUserPermission } from '../../providers/UserPermissionProvider';

import MessageInput from '../../components/Input/MessageInput';

export default function ChannelMessages() {
  const { id } = useParams();
  const theme = useMantineTheme();
  const [currentTenant, user] = useStore((state) => [state.tenant, state.user]);
  const userPermission = useUserPermission();
  const { messages, messagesRef, setMessages, onReceiveMessage } = useChatMessagingState();
  const {
    chime,
    channelIdChangeHandler,
    channelList,
    channelListModerator,
    activeChannel,
    activeView,
    activeChannelFlow,
    activeChannelRef,
    channelListRef,
    channelListModeratorRef,
    unreadChannels,
    activeChannelMemberships,
    // activeChannelMembershipsWithPresence,
    hasMembership,
    channelMessageToken,
    channelMessageTokenRef,
    meetingInfo,
    setActiveChannel,
    setActiveView,
    setActiveChannelFlow,
    setActiveChannelMemberships,
    // setActiveChannelMembershipsWithPresence,
    setChannelMessageToken,
    setChannelList,
    setChannelListModerator,
    setUnreadChannels,
    setMeetingInfo,
    moderatedChannel,
    setModeratedChannel,
    typingIndicator,
    setTypingIndicator,
  } = useChatChannelState();

  useEffect(() => {
    if (!activeChannel.Channel && channelList.length < 1) {
      chime.describeChannel(`${currentTenant.appInstanceArn}/channel/${id}`).then((channel) => {
        setActiveChannel(channel);
        channelIdChangeHandler(channel);
      });
    }
  }, [id]);

  const handleDeleteChannels = () => {
    const test = chime.deleteChannel({
      channelArn: `${currentTenant.appInstanceArn}/channel/${id}`,
    });
    console.log(test);
  };
  const handleJoinChannel = () => {
    const test = chime.createChannelMembership({
      channelArn: `${currentTenant.appInstanceArn}/channel/${id}`,
    });
    console.log(test);
  };
  const handleShowState = () => {
    console.log(
      JSON.stringify(
        {
          chime,
          channelIdChangeHandler,
          channelList,
          channelListModerator,
          activeChannel,
          activeView,
          activeChannelFlow,
          activeChannelRef,
          channelListRef,
          channelListModeratorRef,
          unreadChannels,
          activeChannelMemberships,
          // activeChannelMembershipsWithPresence,
          hasMembership,
          channelMessageToken,
          channelMessageTokenRef,
          meetingInfo,
          setActiveChannel,
          setActiveView,
          setActiveChannelFlow,
          setActiveChannelMemberships,
          // setActiveChannelMembershipsWithPresence,
          setChannelMessageToken,
          setChannelList,
          setChannelListModerator,
          setUnreadChannels,
          setMeetingInfo,
          moderatedChannel,
          setModeratedChannel,
          typingIndicator,
          setTypingIndicator,
        },
        null,
        4
      )
    );
  };

  return (
    <Container mt={theme.spacing.lg} size="xl">
      <Group>
        <Button onClick={handleDeleteChannels}>Delete Channel</Button>
        {!hasMembership && <Button onClick={handleJoinChannel}>Join Channel</Button>}
        <Button onClick={handleShowState}>Show State</Button>
      </Group>
      {!activeChannel.Channel && (
        <>
          {messages.length > 1 && (
            <Messages
              messages={messages}
              messagesRef={messagesRef}
              setMessages={setMessages}
              channelArn={activeChannelRef.current.ChannelArn}
              setChannelMessageToken={setChannelMessageToken}
              activeChannelRef={activeChannelRef}
              channelName={activeChannel.Name}
              userId={user.userId}
            />
          )}
          <MessageInput
            activeChannelArn={activeChannelRef.current.ChannelArn}
            member={chime.memberArn}
            hasMembership={hasMembership}
            chime={chime}
          />
        </>
      )}
    </Container>
  );
}
