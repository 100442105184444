import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Group,
  NumberInput,
  Paper,
  Select,
  Stepper,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';

import { Footer } from '../../../Base/Footer/Footer';
import TitleForm from './Forms/Cookbook/TitleForm';
import PhotosForm from './Forms/Photos/PhotosForm';
import ChaptersForm from './Forms/Cookbook/ChaptersForm';
import PreviewForm from './Forms/Preview/CookbookPreview';

export default function AddRecipe() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const form = useForm({
    initialValues: {
      title: undefined,
      description: undefined,
      files: [],
      chapters: [
        { chapter: 1, value: 'Breakfast Recipes' },
        { chapter: 2, value: 'Lunch Recipes' },
        { chapter: 3, value: 'Dinner Recipes' },
      ],
    },
  });

  return (
    <>
      <Container size="lg" mt={15}>
        <Stepper active={active} size="sm" onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Details">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <TitleForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Photos">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <PhotosForm form={form} multi={false} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Chapters">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <ChaptersForm form={form} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Review">
            <Paper p={xsBreak ? 5 : 25} withBorder>
              <PreviewForm form={form} />
            </Paper>
          </Stepper.Step>
          {/* <Stepper.Completed>Completed, click back button to get to previous step</Stepper.Completed> */}
        </Stepper>

        <Group position="center" mt="xl">
          <Button variant="default" onClick={() => navigate(-1)} mr="auto">
            Cancel
          </Button>
          <Button variant="default" onClick={prevStep} disabled={active == 0 ? true : false}>
            Back
          </Button>
          {active < 3 ? <Button onClick={nextStep}>Next step</Button> : <Button>Looks good!</Button>}
        </Group>
      </Container>
      <Footer />
    </>
  );
}
