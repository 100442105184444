import {
  createStyles,
  Box,
  Button,
  Menu,
  Chip,
  Center,
  Flex,
  Container,
  NavLink,
  Stack,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';

export default function CategoryChips({ categories }: Record<string, string[]>) {
  return (
    <Flex gap="xs" justify="center" align="center" direction="row" wrap="wrap">
      {categories.map((category) => (
        <Button size="xs" px={10} mr={5} radius="xl" variant="outline">
          {category}
        </Button>
      ))}

      <Button size="xs" rightIcon={<IconPlus size={16} />} radius="xl" variant="outline">
        View More
      </Button>
    </Flex>
  );
}
