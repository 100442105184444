import { useState, SyntheticEvent } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  Button,
  Box,
  Header,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Flex,
  Tabs,
  Title,
  Space,
  Paper,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { Footer } from '../../Base/Footer/Footer';
import Explore from './Tabs/Explore/Explore';
import FoodSearch from './Tabs/Nutrition/FoodSearch';

export default function Recipes() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  return (
    <>
      <Container fluid h="100%" sx={{ position: 'relative', padding: 0 }}>
        <Box w="100%" h="100%" p={theme.spacing.md} sx={{ position: 'absolute' }}>
          <Tabs defaultValue="explore" aria-label="navigate actions">
            <Container size="lg" h="100%" sx={{ position: 'relative', padding: 0 }}>
              <Box maw="100%" sx={{ overflowX: 'auto', position: 'relative' }}>
                <Tabs.List h="50px" miw="30rem">
                  <Tabs.Tab value="explore" onClick={() => navigate('explore')}>
                    Explore
                  </Tabs.Tab>
                  <Tabs.Tab value="favorites" onClick={() => navigate('')}>
                    Favorites
                  </Tabs.Tab>
                  <Tabs.Tab value="cookbooks" onClick={() => navigate('')}>
                    Cookbooks
                  </Tabs.Tab>
                  <Tabs.Tab value="nutrition" onClick={() => navigate('nutrition')}>
                    Nutrition
                  </Tabs.Tab>

                  <Tabs.Tab value="personal" ml="auto" onClick={() => navigate('personal')} sx={{ zIndex: 0 }}>
                    My recipes
                  </Tabs.Tab>
                </Tabs.List>
              </Box>
            </Container>
            <Tabs.Panel value="explore" pt="xs">
              <Container size="lg" h="100%" sx={{ position: 'relative', padding: 0 }}>
                <Outlet />
              </Container>
              <Footer />
            </Tabs.Panel>

            <Tabs.Panel value="nutrition" pt="xs">
              <Outlet />
              <Footer />
            </Tabs.Panel>
            <Tabs.Panel value="personal" pt="xs">
              <Outlet />
              <Footer />
            </Tabs.Panel>
          </Tabs>
          <Space h="xl" />
        </Box>
      </Container>
    </>
  );
}
