import { useState, forwardRef, HTMLInputTypeAttribute } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ColorSwatch,
  Container,
  Divider,
  Group,
  Center,
  Flex,
  Grid,
  Image,
  Menu,
  Paper,
  Radio,
  Select,
  Tabs,
  Title,
  Space,
  Text,
  ThemeIcon,
  TextInput,
  createStyles,
  UnstyledButton,
  useMantineTheme,
  useMantineColorScheme,
  Tooltip,
} from '@mantine/core';
import { IconSearch, IconPlus, IconPhone, IconAt, IconMapPin } from '@tabler/icons';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { prefixes, suffixes, states, countries } from '../../utils/data';

const Swatches = ({ isPrimaryColor }) => {
  const theme = useMantineTheme();
  const selectedColor = isPrimaryColor ? theme.primaryColor : theme.other.secondaryColor;

  return (
    <Group spacing={2}>
      {Object.keys(theme.colors).map((c) => (
        <Tooltip label={c} key={`label-${c}`}>
          <ColorSwatch
            component="button"
            radius={c == selectedColor ? 'md' : 'xl'}
            size={c == selectedColor ? 30 : 22}
            key={c}
            color={theme.colors[c][theme.primaryShade[theme.colorScheme]]}
            onClick={() => theme.other.updateThemeColors(isPrimaryColor ? { primaryColor: c } : { secondaryColor: c })}
            sx={{ cursor: 'pointer' }}
          />
        </Tooltip>
      ))}
    </Group>
  );
};

export default function Recipes() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    // <Tabs.Panel value="theme" pt="sm">
    <Container fluid m={0} p={0}>
      <Box>
        <Container fluid py={theme.spacing.xl}>
          <Grid justify="flex-start">
            <Grid.Col sm={12}>
              <Title size="h3" pr={15} sx={{ display: 'inline' }}>
                Dark mode
              </Title>
              <Text>All the cool kids are using dark mode.</Text>
            </Grid.Col>
            <Grid.Col sm={6}>
              <Box p={theme.spacing.xs}>
                <Center>
                  <Box
                    p={theme.spacing.xs}
                    component="a"
                    onClick={() => toggleColorScheme()}
                    sx={{
                      border: colorScheme == 'light' ? `2px solid ${theme.colors.blue[6]}` : 'none',
                      borderRadius: theme.spacing.lg,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Image radius="md" src={process.env.PUBLIC_URL + '/light-mode.png'} alt="Dark mode" />
                  </Box>
                </Center>
                <Space h={theme.spacing.sm} />
                <Center>
                  <Radio checked={colorScheme == 'light'} value={colorScheme} onChange={() => toggleColorScheme()} />
                </Center>
              </Box>
            </Grid.Col>
            <Grid.Col sm={6}>
              <Box p={theme.spacing.xs}>
                <Center>
                  <Box
                    p={theme.spacing.xs}
                    component="a"
                    onClick={() => toggleColorScheme()}
                    sx={{
                      border: colorScheme == 'dark' ? `2px solid ${theme.colors.blue[6]}` : 'none',
                      borderRadius: theme.spacing.lg,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Image radius="md" src={process.env.PUBLIC_URL + '/dark-mode.png'} alt="Light mode" />
                  </Box>
                </Center>
                <Space h={theme.spacing.sm} />
                <Center>
                  <Radio value={colorScheme} checked={colorScheme == 'dark'} onChange={() => toggleColorScheme()} />
                </Center>
              </Box>
            </Grid.Col>
          </Grid>
        </Container>
        <Divider pb={theme.spacing.xl} />
        <Container fluid py={theme.spacing.xl}>
          <Grid justify="flex-start">
            <Title size="h3" pr={15} sx={{ display: 'inline' }}>
              Color Palette
            </Title>
            <Text>Customize the looks and feel with your own colors.</Text>
            <Grid.Col sm={12}>
              <Center sx={{ justifyContent: 'flex-start' }}>
                <Title inline size="h4" pr={15} sx={{ display: 'inline' }}>
                  Primary color
                </Title>
                <Text color="dimmed">{theme.primaryColor}</Text>
              </Center>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Swatches isPrimaryColor={true} />
            </Grid.Col>
          </Grid>
        </Container>
        <Container fluid>
          <Grid justify="flex-start">
            <Grid.Col sm={12}>
              <Center sx={{ justifyContent: 'flex-start' }}>
                <Title size="h4" pr={15} sx={{ display: 'inline' }}>
                  Secondary color
                </Title>
                <Text color="dimmed">{theme.other.secondaryColor}</Text>
              </Center>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Swatches isPrimaryColor={false} />
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </Container>
    // </Tabs.Panel>
  );
}
