import { API } from 'aws-amplify';
import { apiName, headers } from './api-config';

// Get details of your user or another user: GET /tenants/{tenantId}
export const getUser = async (userId?: string) => {
  const path = userId ? `user/${userId}` : 'user';
  try {
    const response = await API.get(apiName, path, {});
    console.log('getUser', response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Update your user: POST /user
export const updateUser = async ({ body }) => {
  try {
    const response = await API.post(apiName, `user`, { body });

    console.log(response);
    return { status: 'success', ...response };
  } catch (error) {
    return { status: 'error', ...error };
  }
};
