import { FC } from 'react';
import { Box } from '@mantine/core';

const BaseContainer: FC<any> = ({ children }) => {
  return (
    <Box
      sx={{
        // position: 'absolute',
        maxHeight: '100vh',
        maxWidth: '100vw',
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </Box>
  );
};

export default BaseContainer;
