import { API } from 'aws-amplify';
import { apiName, headers } from './api-config';

/*
    Tenant details
*/
// Create a tenant: PUT /tenants
export interface putTenantProps {
  tenantName: string;
  color?:
    | 'dark'
    | 'dark'
    | 'gray'
    | 'red'
    | 'pink'
    | 'grape'
    | 'violet'
    | 'indigo'
    | 'blue'
    | 'cyan'
    | 'teal'
    | 'green'
    | 'lime'
    | 'yellow'
    | 'orange';
}
export const putTenant = async ({ tenantName, color }: putTenantProps) => {
  try {
    const response = await API.put(apiName, `tenants`, {
      body: {
        tenantName,
        ...(color && { color }),
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

// Get details of a tenant: GET /tenants/{tenantId}
export const getTenant = async (tenantId: string) => {
  try {
    const response = await API.get(apiName, `tenants/${tenantId}`, {});

    return response;
  } catch (error) {
    console.log(error);
  }
};

// Update a tenant: PATCH /tenants/{tenantId}
export const updateTenant = async ({ tenantId, body }) => {
  try {
    const response = await API.patch(apiName, `tenants/${tenantId}`, { body });

    console.log(response);
  } catch (error) {
    console.log(error);
  }
};

// List all tenants: GET /tenants
export const listTenants = async () => {
  try {
    const response = await API.get(apiName, `tenants`, { withCredentials: true });

    console.log('tenants', response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Delete a tenant: DELETE /tenants/{tenantId}
export const deleteTenant = async (tenantId) => {
  try {
    const response = await API.del(apiName, `tenants/${tenantId}`, {});

    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
