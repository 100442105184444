import React, { FC, HTMLAttributes, ReactNode } from 'react';

export interface ChatBubbleProps extends Omit<HTMLAttributes<HTMLDivElement>, 'css'> {
  /** Determines styling for outgoing and incoming messages. */
  variant: any;
  /** The name of the user that sent the message. */
  senderName?: string;
  senderId: string;
  /** The timestamp of the message being sent. */
  timestamp?: string;
  /** Adds the bubble tail style to a message. */
  showTail?: boolean;
  /** Determines if the message has been removed after being sent. */
  redacted?: boolean;
  /** Includes other elements or components, such as a message attachment. */
  children?: ReactNode | ReactNode[];
  showName?: boolean;
}

export const ChatBubble: FC<ChatBubbleProps> = ({
  variant,
  senderName,
  senderId,
  showName,
  timestamp,
  showTail,
  redacted,
  children,
}) => {
  return (
    <div data-testid="chat-bubble">
      {(senderName || timestamp) && (
        <div className="ch-header">
          {senderName && <div>{senderName}</div>}
          {timestamp && <div>{timestamp}</div>}
        </div>
      )}
      {children && <div>{children}</div>}
    </div>
  );
};

export default ChatBubble;
