import { Chip } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

export const books = [
  // {
  //   title: 'Featured Cookbook',
  //   date: 'Nov 12',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Gordon Ramsey',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Cookbook title',
  //   date: 'Nov 11',
  //   description:
  //     'This is a wider card with supporting text below as a natural lead-in to additional content. This card example has an exceptionally long title so you can see how the overflow is handled. Ellipses should be used to show the truncation.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
  // {
  //   title: 'Cookbook title',
  //   date: 'Nov 13',
  //   description: 'This is a wider card with supporting text below as a natural lead-in to additional content.',
  //   image: 'https://source.unsplash.com/random',
  //   imageLabel: 'Image Text',
  //   author: 'Uncle Roger',
  //   avatar: <FaceIcon />,
  // },
];
