import {
  createStyles,
  Box,
  Button,
  Menu,
  Grid,
  Center,
  Burger,
  Container,
  NavLink,
  Space,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: '#11284b',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%)',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },

  image: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  content: {
    marginLeft: theme.spacing.xl,
    backgroundColor: theme.colors.gray[8] + 88,
    padding: 15,
    display: 'block',
    marginRight: '50%',
    borderRadius: 10,

    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
    },
  },

  title: {
    color: theme.white,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: 500,
    fontSize: 36,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      fontSize: 34,
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.white,
    opacity: 0.75,
    maxWidth: 500,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },
}));

interface FeaturedMainProps {
  main: {
    description: string;
    image: string;
    imageText: string;
    linkText: string;
    title: string;
  };
}

export default function FeaturedMain({ main }: FeaturedMainProps) {
  const { classes } = useStyles();
  return (
    <Paper className={classes.root} sx={{ backgroundImage: `url(${main.image})` }}>
      <Container size="lg">
        <Box className={classes.content}>
          <Title className={classes.title}>A fully featured React components library</Title>

          <Text className={classes.description} mt={30}>
            Build fully functional accessible web applications with ease – Mantine includes more than 100 customizable
            components and hooks to cover you in any situation
          </Text>
          <Button variant="gradient" radius={5} gradient={{ from: 'blue', to: 'teal' }} size="xl" mt={10}>
            Get started
          </Button>
        </Box>
      </Container>
    </Paper>
  );
}
