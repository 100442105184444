import { useMemo } from 'react';
import { Container, Paper, useMantineTheme } from '@mantine/core';
import CalendarWeekView from './WeekView';

import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function PersonalCalendar() {
  const theme = useMantineTheme();
  const mode = theme.colorScheme;
  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? {
                primary: {
                  main: theme.colors.blue[6],
                  contrastText: '#fff',
                },
                secondary: {
                  main: '#0277bd',
                },
                background: {
                  default: theme.white,
                },
              }
            : {
                primary: {
                  main: theme.colors.blue[4],
                  contrastText: '#fff',
                },
                secondary: {
                  main: '#ab47bc',
                },
                background: {
                  default: theme.colors.dark[7],
                },
              }),
        },
      }),
    [mode]
  );
  return (
    <ThemeProvider theme={muiTheme}>
      <ScopedCssBaseline>
        <Container fluid p={15}>
          <Paper withBorder shadow="md">
            <CalendarWeekView />
          </Paper>
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
