import { useState } from 'react';
import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Flex,
  Group,
  Modal,
  SegmentedControl,
  Space,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { ThemeContext } from '@emotion/react';

export function CreateChannelModal({ chime, setRefresh }) {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      name: '',
      privacy: 'PUBLIC',
      mode: 'UNRESTRICTED',
    },
  });

  const handleCreateChannel = (values) => {
    const channel = chime.createChannel(values).then((channel) => {
      setRefresh(true);
      close();
      console.log(channel);
    });
  };

  return (
    <>
      <Modal opened={opened} onClose={() => close()} title="Create a new channel">
        <form onSubmit={form.onSubmit((values) => handleCreateChannel(values))}>
          <Group>
            <Flex justify="flex-start">
              <TextInput
                withAsterisk
                label="Name"
                placeholder="Channel name"
                miw={250}
                {...form.getInputProps('name')}
              />
            </Flex>
            <Space h={theme.spacing.md} />
            <SegmentedControl
              color={theme.other.secondaryColor}
              data={[
                { label: 'Public', value: 'PUBLIC' },
                { label: 'Private', value: 'PRIVATE' },
              ]}
              {...form.getInputProps('privacy')}
            />
            <Text size="xs" color="dimmed">
              {form.values.privacy == 'PRIVATE'
                ? "Private channels aren't discoverable by family members outside the channel."
                : 'Public channels are discoverable by anyone in the family.'}
            </Text>
            <SegmentedControl
              color={theme.other.secondaryColor}
              data={[
                { label: 'Anyone can join', value: 'UNRESTRICTED' },
                { label: 'Owners must add members', value: 'RESTRICTED' },
              ]}
              {...form.getInputProps('mode')}
            />
            <Text size="xs" color="dimmed">
              {form.values.mode == 'UNRESTRICTED'
                ? 'Anyone can add themselves and other members to this channel.'
                : 'Only owners and moderators can add members to this channel.'}
            </Text>
            <Group position="center" mt="xl">
              <Button type="submit" color={theme.other.secondaryColor}>
                Create
              </Button>
              <Button variant="default" onClick={() => close()} mr="auto">
                Cancel
              </Button>
            </Group>
          </Group>
        </form>
      </Modal>

      <ActionIcon onClick={() => open()}>
        <IconPlus size={16} stroke={1.5} />
      </ActionIcon>
    </>
  );
}
