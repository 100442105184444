import { useState } from 'react';

import {
  ActionIcon,
  Box,
  Button,
  Card,
  Center,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  NumberInput,
  Paper,
  Space,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IconGripVertical, IconHelp, IconX } from '@tabler/icons';

export default function DetailsForm({ form }) {
  const theme = useMantineTheme();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  return (
    <>
      <Container size="sm">
        <Grid gutter={50}>
          <Grid.Col xs={12}>
            <Center inline>
              <Title size="h3">Title</Title>
              <ActionIcon color={theme.colors.teal[6]} variant="transparent">
                <IconHelp size={20} />
              </ActionIcon>
            </Center>
            <TextInput mt={5} mb={10} placeholder="My Famous Cookbook" {...form.getInputProps('title')} />
            <Center inline>
              <Title size="h3">Description</Title>
              <ActionIcon color={theme.colors.teal[6]} variant="transparent">
                <IconHelp size={20} />
              </ActionIcon>
            </Center>
            <Textarea
              mt={1}
              autosize
              minRows={2}
              maxRows={4}
              placeholder="Cook these recipes to make friends, become successful, and discover lifelong happiness."
              {...form.getInputProps('description')}
            />
          </Grid.Col>
        </Grid>
        <Box
          bg={theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1]}
          mt={25}
          mb={10}
          pb={15}
          sx={{ borderRadius: 10 }}
        >
          <Center>
            <Title color="dimmed" size="h4" mt={15}>
              Preview
            </Title>
          </Center>
          <Grid>
            <Grid.Col span={12}></Grid.Col>
            <Grid.Col xs={12}>
              <Container>
                <Paper shadow="md" p={15}>
                  <Center>
                    <Title color="dimmed" size="h3">
                      {form.values.title ?? 'My Famous Cookbook'}
                    </Title>
                  </Center>
                  <Space h="md" />
                  <Text color="dimmed">
                    {form.values.description ??
                      'Cook these recipes to make friends, become successful, and discover lifelong happiness.'}
                  </Text>
                </Paper>
              </Container>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
