import { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  createStyles,
  Box,
  Button,
  Menu,
  Grid,
  Center,
  ColorSwatch,
  Checkbox,
  Container,
  Flex,
  Group,
  Anchor,
  Title,
  Avatar,
  Radio,
  Space,
  Paper,
  Text,
  TextInput,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconCloud, IconAt, IconKey } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { useStore } from '../../index';
import { Auth } from 'aws-amplify';
import { putTenant, putTenantProps } from 'api/tenant-service';

async function updateUser(tenantId) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    'custom:primaryTenant': tenantId,
  });
}

export default function Welcome() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [currentTenant, changeTenant] = useStore((state) => [state.tenant, state.setTenant]);
  const [newTenant, setNewTenant] = useState<string>(undefined);

  const form = useForm({
    initialValues: {
      tenantName: '',
      color: 'blue',
      // files: [],
    } as putTenantProps,
  });

  const handleSubmit = (values) => {
    putTenant(values).then((response) => {
      updateUser(response.tenantId)
        .then(() => changeTenant(response.tenantId))
        .then(() => setNewTenant(response.tenantId));
    });
  };

  useEffect(() => {
    if (newTenant !== undefined) {
      localStorage.setItem('CurrentTenantId', newTenant);
      navigate('/', { replace: true });
      window.location.reload();
    }
  }, [newTenant]);

  const swatches = Object.keys(theme.colors).map((c) => (
    <ColorSwatch
      component="button"
      size={c == form.values.color ? 30 : 22}
      key={c}
      color={theme.colors[c][theme.primaryShade[theme.colorScheme]]}
      onClick={(e) => handleColorChange(e, c)}
      sx={{ cursor: 'pointer' }}
    />
  ));

  const handleColorChange = (e, color) => {
    e.preventDefault();
    form.setFieldValue('color', color);
  };

  return (
    <Container size="xs">
      <Space h="xl" />
      <Paper
        p="md"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Space h="xl" />
        <Title size="h2">Let's get started</Title>
        <Space h="xl" />
        <Avatar src={null} h={64} w={64} alt="M" color={form.values.color} radius="md">
          <Text fz="xl">
            {form.values.tenantName.slice(0, 4) == 'The '
              ? form.values.tenantName.replace(/ /g, '')[3] ?? 'F'
              : form.values.tenantName.replace(/ /g, '')[0] ?? 'F'}
          </Text>
        </Avatar>
        <Space h="xl" />
        <Box sx={{ mt: 1, width: '70%' }}>
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <TextInput
              required
              radius="md"
              id="tenantName"
              size="md"
              label="Family Name"
              {...form.getInputProps('tenantName')}
              placeholder="The Mercer Family"
              autoFocus
            />
            <Space h="md" />

            <Group position="center" spacing={1}>
              {swatches}
            </Group>
            <Space h="md" />
            <Button type="submit" radius="md" fullWidth variant="filled" sx={{ mt: 3, mb: 2 }}>
              Create family
            </Button>
            <Space h="sm" />
            <Flex align="center" justify="center">
              <Anchor span size="sm">
                I have a family code.
              </Anchor>
            </Flex>
            <Space h="xl" />
          </form>
        </Box>
      </Paper>
    </Container>
  );
}
