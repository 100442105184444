import { useEffect, useState } from 'react';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  Collapse,
  Container,
  Group,
  Modal,
  Text,
  Image,
  SimpleGrid,
  Slider,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import { IconUpload, IconPhoto, IconX, IconAlertCircle, IconCrop } from '@tabler/icons';
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from '@mantine/dropzone';
import { PhotoCrop } from '../../../../../Base/Photos/Cropping/PhotoCrop';

const CustomAlert = ({ files, setAlert }) => {
  const [opened, setOpened] = useState(false);
  let text;
  if (files[0].errors.some((e) => e.code == 'file-invalid-type')) {
    text = 'This is not a valid image. Look for files ending with ".png", ".gif",".jpeg", ".svg", or ".webp".';
  } else {
    text = JSON.stringify(files[0].errors);
  }
  return (
    <Alert
      icon={<IconAlertCircle size={16} />}
      title="Whoops!"
      color="red"
      withCloseButton
      onClose={() => setAlert(<></>)}
    >
      {text}
      <Button variant="default" size="xs" onClick={() => setOpened((o) => !o)}>
        View error
      </Button>
      <Collapse in={opened}>{JSON.stringify(files, null, 2)}</Collapse>
    </Alert>
  );
};

interface selectedFile {
  name: string;
  image: string;
  croppedImage?: string;
}

export default function PhotosForm({ form, multi = true }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState({} as selectedFile);
  const [alert, setAlert] = useState(<></>);

  console.log(form);

  const previews = form.values.files.map((file, index) => {
    const name = file.name;
    const cropButton = () => {
      setSelectedFile(file);
      setOpened(true);
    };
    return (
      <Box sx={{ overflow: 'hidden' }}>
        <Center>
          <Image key={index} mah="50px" maw="100px" src={file.croppedImage ?? file.image} />
          <Stack spacing={2} ml={5}>
            <ActionIcon variant="filled" color="blue" onClick={cropButton}>
              <IconCrop />
            </ActionIcon>
            <ActionIcon
              variant="outline"
              color="red.8"
              onClick={() => {
                removeFile(file);
              }}
            >
              <IconX />
            </ActionIcon>
          </Stack>
        </Center>
      </Box>
    );
  });

  const removeFile = (file) => {
    const filteredFiles = form.values.files.filter((f) => f.image != file.image);
    form.setFieldValue('files', filteredFiles);
  };

  const dropFiles = (uploadedFiles) => {
    uploadedFiles.map((file) => {
      const formatted = { name: file.name, image: URL.createObjectURL(file) };
      if (multi) {
        form.insertListItem('files', formatted);
      } else {
        form.setFieldValue('files', [formatted]);
      }
    });
  };

  const confirmFile = (croppedImage, name) => {
    if (multi) {
      const formIndex = form.values.files.findIndex((file) => file.name == name);
      const image = form.values.files[formIndex].image;
      if (formIndex > -1) {
        form.removeListItem('files', formIndex);
      }
      form.insertListItem('files', { name, image, croppedImage });
    } else {
      form.setFieldValue('files', [{ ...form.values.files[0], croppedImage }]);
    }

    setOpened(false);
  };

  return (
    <Box>
      <Container size="sm">{alert}</Container>
      <Dropzone
        multiple={multi}
        accept={IMAGE_MIME_TYPE}
        onDrop={dropFiles}
        onReject={(files) => {
          setAlert(<CustomAlert files={files} setAlert={setAlert} />);
        }}
        maxSize={5 * 1024 ** 2}
      >
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX size={50} stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size={50} stroke={1.5} />
          </Dropzone.Idle>

          <Box>
            <Text size="xl" inline>
              Drag images here {multi && 'or click to select multiple images'}
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              {multi ? 'Attach as many images as you like' : 'Attach an image'}, images should not exceed 5mb
            </Text>
          </Box>
        </Group>
      </Dropzone>
      <Group>
        <Text>selected photos: {form.values.files.length}</Text>
        {form.values.files.map((f, index) => (
          <Image h="auto" w="100%" key={index} src={f.imageUrl} />
        ))}
      </Group>
      <SimpleGrid
        cols={6}
        breakpoints={[
          { maxWidth: 980, cols: 4, spacing: 'md' },
          { maxWidth: 755, cols: 3, spacing: 'sm' },
          { maxWidth: 600, cols: 2, spacing: 'xs' },
        ]}
        mt={previews.length > 0 ? 'xl' : 0}
      >
        {previews}
      </SimpleGrid>

      <Modal opened={opened} size="lg" onClose={() => setOpened(false)} title="Introduce yourself!">
        <Container size="xs">
          <Box h="500px" p={0} sx={{ position: 'relative' }}>
            {selectedFile && (
              <PhotoCrop
                handleImage={(image) => confirmFile(image, selectedFile.name)}
                aspect={16 / 9}
                shape="rect"
                image={selectedFile.image}
              />
            )}
          </Box>
        </Container>
      </Modal>
    </Box>
  );
}
