export const NutrientIds = {
  ENERGY: 1008,
  CALCIUM: 1087,
  IRON: 1089,
  VITAMIN_A: 1104,
  VITAMIN_C: 1162,
  PROTEIN: 1003,
  FAT: 1004,
  CARBOHYDRATE: 1005,
  SUGAR: 2000,
  FIBER: 1079,
  POTASSIUM: 1092,
  SODIUM: 1093,
  CHOLESTEROL: 1253,
};

export const formatNutrient = (nutrient) => {
  const formatProps = {};
  if (nutrient.nutrientId == 1005) {
    formatProps['nutrientName'] = 'Carbohydrates';
  }
  return { ...nutrient, ...formatProps };
};
