// eslint-disable-next-line no-unused-vars
import { ChimeSDKIdentity } from '@aws-sdk/client-chime-sdk-identity';
import {
  ChimeSDKMessaging,
  CreateChannelCommand,
  ListChannelsCommand,
  DescribeChannelCommand,
  DeleteChannelCommand,
  SendChannelMessageCommand,
  GetMessagingSessionEndpointCommand,
  CreateChannelMembershipCommand,
  ListChannelMembershipsCommand,
  ListChannelMessagesCommand,
  ListChannelMembershipsForAppInstanceUserCommand,
  ListChannelsModeratedByAppInstanceUserCommand,
  DescribeChannelMembershipForAppInstanceUserCommand,
  UpdateChannelReadMarkerCommand,
} from '@aws-sdk/client-chime-sdk-messaging';
import { Auth } from 'aws-amplify';

export const Persistence = {
  PERSISTENT: 'PERSISTENT',
  NON_PERSISTENT: 'NON_PERSISTENT',
};
export const MessageType = {
  STANDARD: 'STANDARD',
  CONTROL: 'CONTROL',
};

export class ChimeApi {
  protected chimeIdentity: ChimeSDKIdentity | null;
  protected chimeMessaging: ChimeSDKMessaging | null;
  public appInstanceArn: string;
  public memberArn: string;
  public userId: string;

  constructor(appInstanceArn: string, userId: string) {
    this.appInstanceArn = appInstanceArn;
    this.memberArn = `${appInstanceArn}/user/${userId}`;
    this.userId = userId;
  }

  // Setup Chime Messaging Client lazily
  chimeMessagingClient = async () => {
    const credentials = await Auth.currentUserCredentials();
    if (this.chimeMessaging == null) {
      console.log('new messaging client');
      this.chimeMessaging = new ChimeSDKMessaging({
        region: 'us-east-1',
        credentials,
      });
    }

    return this.chimeMessaging;
  };

  resetAWSClients = () => {
    console.log('resetting clients');
    this.chimeMessaging = null;
    this.chimeIdentity = null;
  };
  // Setup Chime Identity Client lazily
  chimeIdentityClient = async () => {
    const credentials = await Auth.currentUserCredentials();
    if (this.chimeIdentity == null) {
      console.log('new identity client');
      this.chimeIdentity = new ChimeSDKIdentity({
        region: 'us-east-1',
        credentials,
      });
    }
    return this.chimeIdentity;
  };

  getMessagingSessionEndpoint = async () => {
    console.log('getting messaging session endpoint');

    const client = await this.chimeMessagingClient();
    const command = new GetMessagingSessionEndpointCommand({});
    const response = await client.send(command);
    return response;
  };

  listChannels = async () => {
    console.log('listChannels called');

    const chimeBearerArn = this.memberArn;
    const params = {
      AppInstanceArn: this.appInstanceArn,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new ListChannelsCommand(params);
    const response = await client.send(command);
    console.log('response', response);

    const channels = response.Channels;
    return channels;
  };

  createChannel = async ({
    name,
    metadata = '',
    mode = 'UNRESTRICTED',
    privacy = 'PUBLIC',
  }: {
    name: string;
    metadata?: string;
    mode?: 'UNRESTRICTED' | 'RESTRICTED';
    privacy?: 'PUBLIC' | 'PRIVATE';
  }) => {
    const params = {
      AppInstanceArn: this.appInstanceArn,
      Metadata: metadata,
      Name: name,
      Mode: mode,
      Privacy: privacy,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new CreateChannelCommand(params);
    const response = await client.send(command);
    return response;
  };

  deleteChannel = async ({ channelArn }) => {
    const params = {
      ChannelArn: channelArn,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new DeleteChannelCommand(params);
    const response = await client.send(command);

    return response;
  };

  describeChannel = async (channelArn) => {
    const params = {
      ChannelArn: channelArn,
      ChimeBearer: this.memberArn,
    };
    console.log('describeParams', params);

    const client = await this.chimeMessagingClient();
    const command = new DescribeChannelCommand(params);
    const response = await client.send(command);

    return response.Channel;
  };

  sendChannelMessage = async (channelArn, messageContent, persistence, type, member, options = null) => {
    const params = {
      ChimeBearer: this.memberArn,
      ChannelArn: channelArn,
      Content: messageContent,
      Persistence: persistence, // Allowed types are PERSISTENT and NON_PERSISTENT
      Type: type, // Allowed types are STANDARD and CONTROL
      Metadata: undefined,
    };
    if (options && options.Metadata) {
      params.Metadata = options.Metadata;
    }

    const client = await this.chimeMessagingClient();
    const command = new SendChannelMessageCommand(params);
    const response = await client.send(command);

    const sentMessage = {
      response: response,
      CreatedTimestamp: new Date(),
      Sender: { Arn: this.memberArn, Name: member.username },
    };
    console.log(sentMessage);
    return sentMessage;
  };

  // const updateChannel = async (channelArn, name, mode, metadata, userId) => {
  //   console.log('updateChannel called');

  //   const chimeBearerArn = this.memberArn;
  //   console.log(chimeBearerArn);
  //   const params = {
  //     ChannelArn: channelArn,
  //     Name: name,
  //     Mode: mode,
  //     Metadata: metadata,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).updateChannel(params);
  //   const response = await request.promise();
  //   return response;
  // }

  listChannelMembershipsForAppInstanceUser = async () => {
    const params = {
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new ListChannelMembershipsForAppInstanceUserCommand(params);
    const response = await client.send(command);

    const channels = response.ChannelMemberships;
    return channels;
  };

  describeChannelMembershipsForAppInstanceUser = async ({
    channelArn,
    appInstanceUserArn,
  }: {
    channelArn: string;
    appInstanceUserArn?: string;
  }) => {
    const params = {
      ChimeBearer: this.memberArn,
      AppInstanceUserArn: appInstanceUserArn ?? this.memberArn,
      ChannelArn: channelArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new DescribeChannelMembershipForAppInstanceUserCommand(params);
    const response = await client.send(command);

    const channels = response.ChannelMembership;
    return channels;
  };

  listChannelsModeratedByAppInstanceUser = async () => {
    const params = {
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new ListChannelsModeratedByAppInstanceUserCommand(params);
    const response = await client.send(command);

    return response.Channels;
  };

  // const listSubChannels = async (channelArn, userId) => {
  //   console.log('listSubChannels called');
  //   const chimeBearerArn = this.memberArn;

  //   const params = {
  //     ChannelArn: channelArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).listSubChannels(params);
  //   const response = await request.promise();
  //   return response;
  // }

  // const listChannelsForAppInstanceUser = async (userId) => {
  //   console.log('listChannelsForAppInstanceUser called');

  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChimeBearer: chimeBearerArn,
  //   };
  //   const request = (await this.chimeMessagingClient()).listChannelsForAppInstanceUser(params);
  //   const response = await request.promise();
  //   const channels = response.Channels;
  //   console.log('channels', channels);
  //   return channels;
  // }

  // const listChannelModerators = async (channelArn, userId)=> {
  //   console.log('listChannelModerators called');
  //   const chimeBearerArn = this.memberArn;

  //   const params = {
  //     ChannelArn: channelArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).listChannelModerators(params);
  //   const response = await request.promise();
  //   return response ? response.ChannelModerators : null;
  // }

  // const updateChannelMessage = async (channelArn, messageId, content, metadata, userId, subChannelId) => {
  //   console.log('updateChannelMessage called');
  //   const chimeBearer = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     MessageId: messageId,
  //     Content: content,
  //     Metadata: metadata,
  //     ChimeBearer: chimeBearer,
  //     SubChannelId: subChannelId,
  //   };

  //   const request = (await this.chimeMessagingClient()).updateChannelMessage(params);

  //   const response = await request.promise();
  //   return response;
  // }

  // const redactChannelMessage = async (channelArn, messageId, userId, subChannelId) => {
  //   console.log('redactChannelMessage called');

  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     MessageId: messageId,
  //     ChimeBearer: chimeBearerArn,
  //     SubChannelId: subChannelId,
  //   };

  //   const request = (await this.chimeMessagingClient()).redactChannelMessage(params);

  //   const response = await request.promise();
  //   return response;
  // }

  // const createMeeting = async (name, userId, channelArn) => {
  //   const response = await fetch(
  //     `${appConfig.apiGatewayInvokeUrl}create?name=${encodeURIComponent(name)}&userId=${encodeURIComponent(
  //       userId
  //     )}&channel=${encodeURIComponent(channelArn)}`,
  //     {
  //       method: 'POST',
  //     }
  //   );
  //   const data = await response.json();

  //   if (data.error) {
  //     throw new Error(`Server error: ${data.error}`);
  //   }

  //   return data;
  // }

  // const createAttendee = async (name, userId, channelArn, meeting) => {
  //   const response = await fetch(
  //     `${appConfig.apiGatewayInvokeUrl}join?name=${encodeURIComponent(name)}&userId=${encodeURIComponent(
  //       userId
  //     )}&channel=${encodeURIComponent(channelArn)}&meeting=${encodeURIComponent(meeting)}`,
  //     {
  //       method: 'POST',
  //     }
  //   );
  //   const data = await response.json();

  //   if (data.error) {
  //     throw new Error(`Server error: ${data.error}`);
  //   }

  //   return data;
  // }

  // const createGetAttendeeCallback = async () => {
  //   return async (chimeAttendeeId, externalUserId) => {
  //     return {
  //       name: externalUserId,
  //     };
  //   };
  // }

  // const endMeeting(meetingId) {
  //   const res = await fetch(`${appConfig.apiGatewayInvokeUrl}end?meetingId=${encodeURIComponent(meetingId)}`, {
  //     method: 'POST',
  //   });

  //   if (!res.ok) {
  //     throw new Error('Server error ending meeting');
  //   }
  // }

  // const getChannelMessage = async (channelArn, member, messageId, subChannelId) => {
  //   console.log('getChannelMessage called');

  //   const chimeBearerArn = this.memberArn;

  //   const params = {
  //     ChannelArn: channelArn,
  //     MessageId: messageId,
  //     ChimeBearer: chimeBearerArn,
  //     SubChannelId: subChannelId,
  //   };

  //   const request = (await this.chimeMessagingClient()).getChannelMessage(params);
  //   const response = await request.promise();
  //   return response.ChannelMessage;
  // }

  listChannelMessages = async (channelArn, nextToken = null) => {
    const params = {
      ChannelArn: channelArn,
      NextToken: nextToken,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new ListChannelMessagesCommand(params);
    const response = await client.send(command);
    console.log('list messages', response);

    const messageList = response.ChannelMessages;
    messageList.sort(function (a, b) {
      // eslint-disable-next-line no-nested-ternary
      return a.CreatedTimestamp < b.CreatedTimestamp ? -1 : a.CreatedTimestamp > b.CreatedTimestamp ? 1 : 0;
    });

    const messages = [];
    for (let i = 0; i < messageList.length; i++) {
      const message = messageList[i];
      messages.push(message);
    }
    return { Messages: messages, NextToken: response.NextToken };
  };

  updateChannelReadMarker = async ({ channelArn }) => {
    const params = {
      ChannelArn: channelArn,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new UpdateChannelReadMarkerCommand(params);
    const response = await client.send(command);

    return response.ChannelArn;
  };

  // // const listAppInstanceUsers = async (appInstanceArn, userId, nextToken = null) => {
  // //   console.log('listAppInstanceUsers called');
  // //   const chimeBearerArn = this.memberArn;
  // //   const params = {
  // //     AppInstanceArn: appInstanceArn,
  // //     NextToken: nextToken,
  // //     ChimeBearer: chimeBearerArn,
  // //   };

  // //   const request = (await this.chimeIdentityClient()).listAppInstanceUsers(params);
  // //   request.on('build', function () {
  // //     request.httpRequest.headers[appInstanceUserArnHeader] = this.memberArn;
  // //   });
  // //   const response = await request.promise();
  // //   return response.AppInstanceUsers;
  // // }

  createChannelMembership = async ({ channelArn }) => {
    const params = {
      ChannelArn: channelArn,
      MemberArn: this.memberArn,
      Type: 'DEFAULT', // OPTIONS ARE: DEFAULT and HIDDEN
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new CreateChannelMembershipCommand(params);
    const response = await client.send(command);

    return response.Member;
  };

  // const deleteChannelMembership = async (channelArn, memberArn, userId, subChannelId) => {
  //   console.log('deleteChannelMembership called');
  //   const chimeBearerArn = this.memberArn;

  //   const params = {
  //     ChannelArn: channelArn,
  //     MemberArn: memberArn,
  //     ChimeBearer: chimeBearerArn,
  //     SubChannelId: subChannelId,
  //   };

  //   const request = (await this.chimeMessagingClient()).deleteChannelMembership(params);
  //   const response = await request.promise();
  //   return response;
  // }

  // const createChannelBan = async (channelArn, memberArn, userId) => {
  //   console.log('createChannelBan called');
  //   const chimeBearerArn = this.memberArn;

  //   const params = {
  //     ChannelArn: channelArn,
  //     MemberArn: memberArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).createChannelBan(params);
  //   const response = await request.promise();
  //   return response;
  // }

  // const deleteChannelBan = async (channelArn, memberArn, userId)=> {
  //   console.log('deleteChannelBan called');

  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     MemberArn: memberArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).deleteChannelBan(params);
  //   const response = await request.promise();
  //   return response;
  // }

  // const listChannelBans = async (channelArn, maxResults, nextToken, userId)=>  {
  //   console.log('listChannelBans called');

  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     MaxResults: maxResults,
  //     NextToken: nextToken,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).listChannelBans(params);
  //   const response = await request.promise();
  //   console.log('listChannelBans response', response);
  //   return response;
  // }

  listChannelMemberships = async (channelArn) => {
    const params = {
      ChannelArn: channelArn,
      ChimeBearer: this.memberArn,
    };

    const client = await this.chimeMessagingClient();
    const command = new ListChannelMembershipsCommand(params);
    const response = await client.send(command);

    return response.ChannelMemberships;
  };

  // const associateChannelFlow = async (channelArn, channelFlowArn, userId) => {
  //   console.log('associateChannelFlow called');
  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     ChannelFlowArn: channelFlowArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).associateChannelFlow(params);

  //   const response = await request.promise();
  //   console.log('associateChannelFlow response');
  //   console.log(response);
  //   return response;
  // }

  // const disassociateChannelFlow = async (channelArn, channelFlowArn, userId) => {
  //   console.log('disassociateChannelFlow called');
  //   const chimeBearerArn = this.memberArn;
  //   const params = {
  //     ChannelArn: channelArn,
  //     ChannelFlowArn: channelFlowArn,
  //     ChimeBearer: chimeBearerArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).disassociateChannelFlow(params);

  //   const response = await request.promise();
  //   return response;
  // }

  // const describeChannelFlow = async (channelFlowArn) => {
  //   console.log('describeChannelFlow called');
  //   const params = {
  //     ChannelFlowArn: channelFlowArn,
  //   };

  //   const request = (await this.chimeMessagingClient()).describeChannelFlow(params);
  //   const response = await request.promise();
  //   return response.ChannelFlow;
  // }

  // const listChannelFlows = async (appInstanceArn, maxResults, nextToken) => {
  //   console.log('listChannelFlows called');

  //   const params = {
  //     AppInstanceArn: appInstanceArn,
  //     MaxResults: maxResults,
  //     NextToken: nextToken,
  //   };

  //   const request = (await this.chimeMessagingClient()).listChannelFlows(params);
  //   const response = await request.promise();
  //   console.log('listChannelFlows response', response);
  //   return response.ChannelFlows;
  // }
}

// export {
//   associateChannelFlow,
//   disassociateChannelFlow,
//   describeChannelFlow,
//   listChannelFlows,
//   sendChannelMessage,
//   getChannelMessage,
//   listChannelMessages,
//   createChannelMembership,
//   listChannelMemberships,
//   deleteChannelMembership,
//   createChannelBan,
//   deleteChannelBan,
//   listChannelBans,
//   listSubChannels,
//   createChannel,
//   describeChannel,
//   updateChannel,
//   listChannels,
//   listChannelsForAppInstanceUser,
//   deleteChannel,
//   listChannelModerators,
//   updateChannelMessage,
//   redactChannelMessage,
//   getMessagingSessionEndpoint,
//   listChannelMembershipsForAppInstanceUser,
//   listChannelsModeratedByAppInstanceUser,
//   listAppInstanceUsers,
//   createMeeting,
//   createAttendee,
//   createGetAttendeeCallback,
//   endMeeting,
//   resetAWSClients,
// };
