import React, { useEffect, useState, useCallback, useRef, MouseEvent, useLayoutEffect } from 'react';
import {
  createStyles,
  Box,
  Header,
  Menu,
  Button,
  Center,
  Burger,
  Container,
  Grid,
  Tabs,
  Title,
  Avatar,
  Paper,
  Text,
  ThemeIcon,
  Transition,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { IconSearch, IconPlus } from '@tabler/icons';

import { useMediaQuery } from '@mantine/hooks';

import ReactFlow, {
  Edge,
  Node,
  addEdge,
  ConnectionLineType,
  ConnectionMode,
  Controls,
  ControlButton,
  Panel,
  Background,
  MiniMap,
  ReactFlowProvider,
  ReactFlowInstance,
  useStoreApi,
  useNodesState,
  useEdgesState,
  useOnSelectionChange,
  useReactFlow,
} from 'reactflow';
import useDrag from 'reactflow';

import 'reactflow/dist/style.css';

// import * as go from 'gojs';
import { Diagram, LayeredDigraphLayout, ObjectData, Key, Model, GraphObject, GraphLinksModel } from 'gojs';
import GenogramLayout from './utils/GenogramLayout';
import SimpleFloatingEdge from './Components/FloatingEdges/FloatingEdge';
import CustomControls from './Components/FlowControls/CustomControls';
import useLongPress from './Components/LongPress/LongPress';
import { createDiagram, setupDiagram, CreateDiagramProps } from './utils/SetupDiagram';
import { people as input } from './utils/genopeople';

import PersonNode from './Components/CustomNodes/PersonNode';
import VertexNode from './Components/CustomNodes/VertexNode';

const proOptions = { hideAttribution: true };
const nodeTypes = {
  personNode: PersonNode,
  vertexNode: VertexNode,
};
const edgeTypes = {
  floating: SimpleFloatingEdge,
};

const people = input as CreateDiagramProps[];
const diagram = createDiagram(people);

const getLayoutedElements = (direction: 'TB' | 'LR' = 'TB') => {
  let nodes: Node[] = [];
  let edges: Edge[] = [];
  let vertices: Node[] = [];

  // set direction for the diagram
  diagram.layout = GraphObject.make(GenogramLayout, {
    isInitial: false,
    isOngoing: false,
    direction: direction == 'TB' ? 90 : 0,
    layerSpacing: 200,
    columnSpacing: 75,
  });
  // setupDiagram(diagram, people);
  diagram.layout.doLayout(diagram);

  const nodeIterator = diagram.nodes;
  while (nodeIterator.next()) {
    const goNode = nodeIterator.value;
    goNode.data.s == 'LinkLabel'
      ? vertices.push({
          id: `${goNode.data.key}`,
          type: 'vertexNode',
          data: {
            label: goNode.data.name,
            toNode: goNode.labeledLink?.toNode?.data,
            fromNode: goNode.labeledLink?.fromNode?.data,
            ...goNode.data,
          },
          style: {
            background: '#777',
            border: '1px solid #777',
            padding: 0,
            borderRadius: '50%',
            height: '10px',
            width: '10px',
            zIndex: 1,
          },
          position: { x: goNode.location.x, y: goNode.location.y },
        })
      : nodes.push({
          id: `${goNode.data.key}`,
          type: 'personNode',
          data: {
            label: goNode.data.name,
            ...goNode.data,
          },
          position: { x: goNode.location.x, y: goNode.location.y },
        });
  }

  for (let vertex of vertices) {
    const fromId = vertex.data.fromNode?.key.toString();
    const toId = vertex.data.toNode?.key.toString();
    const fromNode = nodes.find((n) => n.id == fromId);
    const toNode = nodes.find((n) => n.id == toId);

    // ensure vertex nodes are always positioned in the center between married nodes
    // and add vertex nodes to nodes array
    if (toNode && fromNode) {
      const ax = fromNode.position.x;
      const ay = fromNode.position.y;
      const bx = toNode.position.x;
      const by = toNode.position.y;
      vertex.position.x = (ax + bx) / 2;
      vertex.position.y = (ay + by) / 2;
    }
    nodes.push(vertex);

    // store marriage relationship info inside data object of married personNodes
    nodes = nodes.map((node) => {
      if (fromId == node.id || toId == node.id) {
        return {
          ...node,
          data: {
            ...node.data,
            marriage: {
              vertex: vertex.id.toString(),
              marriedTo: node.id == toId ? fromNode : toNode,
            },
          },
        };
      } else {
        return node;
      }
    });
  }

  const linkIterator = diagram.links;
  while (linkIterator.next()) {
    const link = linkIterator.value;
    if (link.data.category == 'Marriage') {
      const labelNodes = link.labelNodes;
      while (labelNodes.next()) {
        const node = labelNodes.value;
        if (!node.isLinkLabel) continue;
        const fromNode = node!.labeledLink!.fromNode?.data;
        const toNode = node!.labeledLink!.toNode?.data;
        edges.push({
          id: `e${fromNode.key}${node.data.key}`,
          source: fromNode.key.toString(),
          target: node.data.key.toString(),
          sourceHandle: 'bottom',
          targetHandle: 'right',
          type: 'floating',
          animated: false,
          style: { strokeWidth: 2 },
        });
        edges.push({
          id: `e${toNode.key}${node.data.key}`,
          source: toNode.key.toString(),
          target: node.data.key.toString(),
          sourceHandle: 'bottom',
          targetHandle: 'right',
          type: 'floating',
          animated: false,
          style: { strokeWidth: 2 },
        });
      }
    } else {
      edges.push({
        id: `e${link.data.from}${link.data.to}`,
        source: link.data.from.toString(),
        target: link.data.to.toString(),
        type: 'smoothstep',
        sourceHandle: direction == 'TB' ? 'bottom' : 'right',
        targetHandle: direction == 'TB' ? 'top' : 'left',
        animated: false,
        style: { strokeWidth: 2 },
      });
    }
  }
  return { nodes, edges };
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements('TB');

const FamilyTree = () => {
  const theme = useMantineTheme();
  const viewPort = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [selectedNode, setSelectedNode] = useState<string>('');
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const backgroundColor = theme.colorScheme == 'dark' ? theme.colors.dark[5] : theme.colors.gray[1];
  const buttonColor = theme.colorScheme == 'dark' ? theme.colors.dark[6] : theme.white;

  useEffect(() => {}, []);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: 'floating', animated: true }, eds)),
    []
  );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  function SelectionChangeLogger() {
    useOnSelectionChange({
      onChange: ({ nodes, edges }) => {
        // setSelectedNode(node[0].id);
        // console.log('selected', nodes[0]?.data.label);
      },
    });

    return null;
  }

  const onNodeClick = (event, node) => {
    setSelectedNode(node.id);
  };

  function FocusNode({ id }) {
    const store = useStoreApi();
    const { setCenter } = useReactFlow();

    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);
    const node = nodes.find((n) => {
      return n.id.toString() == id;
    });
    if (node && node.width && node.height) {
      const x = node.position.x;
      const y = node.position.y;
      const zoom = 1;

      setCenter(x, y, { zoom, duration: 500 });
    }
    setSelectedNode('');
    return null;
  }

  const nodeColor = (node) => {
    if (node.data.sex == 'F') {
      return 'lightpink';
    }
    return 'lightblue';
  };

  const onLongPress = () => {};

  const onClick = () => {};

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress({ onLongPress, onClick }, defaultOptions);

  return (
    // <Box sx={{ display: 'relative', height: '100%', width: '100%', m: 0, p: 0 }}>
    <Box
      ref={viewPort}
      id="viewPort"
      sx={{
        margin: 0,
        padding: 0,
        height: '100%',
        maxHeight: '-webkit-fill-available',
        width: '100%',
        backgroundColor: backgroundColor,
        zIndex: 50,
      }}
    >
      <ReactFlow
        onNodeClick={onNodeClick}
        nodesDraggable={false}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        connectionMode={ConnectionMode.Loose}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodeOrigin={[0.5, 0.5]}
        proOptions={proOptions}
        minZoom={0.1}
        selectNodesOnDrag={false}
        fitView
      >
        <Panel position="top-left">
          <Button
            variant="outline"
            size="sm"
            rightIcon={<IconPlus size={16} />}
            sx={{ mb: 1, backgroundColor: buttonColor }}
            {...longPressEvent}
          >
            Add family
          </Button>
        </Panel>
        <CustomControls
          vertical={() => onLayout('TB')}
          horizontal={() => onLayout('LR')}
          sx={smallScreen ? { top: 0, right: 0 } : { bottom: 0, left: 0 }}
        />
        {!smallScreen && (
          <MiniMap
            zoomable
            pannable
            nodeColor={nodeColor}
            maskColor={`${theme.colors.gray[5]}44`}
            style={{ background: backgroundColor }}
          />
        )}
        <SelectionChangeLogger />
        <FocusNode id={selectedNode} />
        <Background
          gap={50}
          size={2}
          color={theme.colorScheme == 'dark' ? theme.colors.gray[6] : theme.colors.gray[6]}
          style={{ backgroundColor: backgroundColor }}
        />
      </ReactFlow>
    </Box>
    // </Box>
  );
};

export default FamilyTree;
