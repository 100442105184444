import { useState } from 'react';

import {
  ActionIcon,
  AspectRatio,
  Blockquote,
  Box,
  Button,
  Card,
  Center,
  Code,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  List,
  NumberInput,
  Paper,
  Select,
  Space,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import PhotoCarousel from '../../../Components/Carousel/PhotoCarousel';
import { IconMoodSmile, IconHelp } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: '#11284b',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%)',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },

  image: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  content: {
    marginLeft: theme.spacing.xl,
    backgroundColor: theme.colors.gray[8] + 88,
    padding: 15,
    display: 'block',
    marginRight: '50%',
    borderRadius: 10,

    [theme.fn.smallerThan('md')]: {
      marginRight: 0,
    },
  },

  title: {
    color: theme.white,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: 500,
    fontSize: 36,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      fontSize: 34,
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.white,
    opacity: 0.75,
    maxWidth: 500,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },
}));

export default function PreviewForm({ form }) {
  const theme = useMantineTheme();
  const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const { classes } = useStyles();

  return (
    <Container size="md" p={xsBreak ? 5 : 25} mb={25}>
      <Paper
        className={classes.root}
        sx={{ backgroundImage: `url(${form.values.files.map((file) => file.croppedImage ?? file.image)})` }}
      >
        <Container size="lg">
          <Box className={classes.content}>
            <Title className={classes.title}>{form.values.title ?? 'My Famous Cookbook'}</Title>

            <Text className={classes.description} mt={30}>
              {form.values.description ??
                'Cook these recipes to make friends, become successful, and discover lifelong happiness.'}
            </Text>
            <Space h="sm" />
            <Flex align="flex-end">
              <Button
                size="sm"
                pl={0}
                leftIcon={<IconMoodSmile size={36} />}
                radius="xl"
                variant="outline"
                color="blue.7"
              >
                Harriette Spoonlicker
              </Button>
            </Flex>
          </Box>
        </Container>
      </Paper>

      <Space h="md" />

      {/* <Space h="md" />
      <Container fluid={xsBreak} my={15} size="sm">
        <PhotoCarousel images={form.values.files.map((file) => file.croppedImage ?? file.image)} />
      </Container> */}

      <Grid>
        <Grid.Col xs={12}>
          <Container size="lg" sx={{}}>
            <Tabs
              defaultValue={form.values.chapters[0].value}
              aria-label="navigate actions"
              orientation="vertical"
              sx={{ overflow: 'auto' }}
            >
              <Tabs.List>
                {form.values.chapters.map((item, index) => (
                  <>
                    {index == 0 && (
                      <Title size="h3" mb={theme.spacing.xs}>
                        Chapters
                      </Title>
                    )}
                    <Tabs.Tab value={item.value}>
                      {index + 1}. {item.value}
                    </Tabs.Tab>
                  </>
                ))}
              </Tabs.List>
              {form.values.chapters.map((item, index) => (
                <Tabs.Panel value={item.value} pt="xs">
                  <Container fluid>{item.value}</Container>
                </Tabs.Panel>
              ))}
            </Tabs>
          </Container>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
