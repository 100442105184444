import { useEffect, useState, ReactElement, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  Center,
  Flex,
  Divider,
  createStyles,
  Modal,
  Navbar,
  UnstyledButton,
  Space,
  Tooltip,
  Title,
  useMantineTheme,
} from '@mantine/core';
import {
  IconHome2,
  IconActivityHeartbeat,
  IconDeviceDesktopAnalytics,
  IconPlus,
  IconCalendarStats,
  IconUser,
  IconMessageCircle2,
  IconCloud,
  IconPhoto,
  IconToolsKitchen2,
  IconFriends,
  IconCalendar,
} from '@tabler/icons';
import { useMediaQuery } from '@mantine/hooks';
import { useAuthenticator, Loader } from '@aws-amplify/ui-react';
import { useStore } from '../../index';
import Welcome from '../../Views/Auth/Welcome';
import ChannelsList from '../../Views/Chat/ChatNavigation/ChatList';
import { listTenants, getTenant } from '../../api/tenant-service';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },

  aside: {
    flex: '0 0 60px',
    paddingTop: 2,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  mainLink: {
    margin: 2,
    width: 44,
    height: 44,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  addTenant: {
    width: 44,
    height: 44,
    display: 'flex',
    borderRadius: theme.radius.md,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
      cursor: 'pointer',
    },
  },

  title: {
    boxSizing: 'border-box',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.sm,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: 18,
    height: 60,
    textTransform: 'capitalize',
    borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
  },

  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 60,
    paddingTop: theme.spacing.md,
    borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: 'border-box',
    display: 'block',
    textDecoration: 'none',
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: 32,
    lineHeight: '32px',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  linkActive: {
    '&, &:hover': {
      borderLeftColor: theme.fn.gradient({ from: theme.primaryColor, to: theme.other.secondaryColor, deg: 45 }),
      backgroundImage: theme.fn.gradient({ from: theme.primaryColor, to: theme.other.secondaryColor, deg: 45 }),
      color: theme.white,
    },
  },
}));

const fetchTenants = async () => {
  const tenants = await listTenants();
  const update = await tenants.map(async (tenant: any) => {
    return await getTenant(tenant.tenantId);
  });
  return Promise.all(update);
};

interface PageProps {
  label: string;
  location: string;
  icon?: ReactElement;
  variant?: 'icon-only' | 'no-icon' | 'default';
}

const pages: PageProps[] = [
  { label: 'Feed', location: '/feed', icon: <IconActivityHeartbeat /> },
  { label: 'Calendar', location: '/calendar', icon: <IconCalendar /> },
  { label: 'Photos', location: '/photos', icon: <IconPhoto /> },
  { label: 'Recipes', location: '/recipes', icon: <IconToolsKitchen2 /> },
  { label: 'Family', location: '/family', icon: <IconFriends /> },
];

export function Sidebar({ handleClick, width, hidden }) {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const currentLocation = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loading, setLoading] = useState(true);
  // const { currentTenant, changeTenant } = useContext(TenantContext);
  const [currentTenant, changeTenant] = useStore((state) => [state.tenant, state.setTenant]);
  const [tenants, setTenants] = useState([]);
  const [opened, setOpened] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const firstPath = '/' + currentLocation.pathname.split('/')[1] ?? '/';
  // const currentPage = pages.find((page) => page.location === firstPath);
  const pageTitle = currentLocation.pathname.split('/')[1] ?? 'Home';
  // const xsBreak = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const hide = currentLocation.pathname == '/new' || currentLocation.pathname == '/login';

  useEffect(() => {
    authStatus === 'authenticated' && setLoading(false);
  }, [authStatus]);

  useEffect(() => {
    !loading &&
      fetchTenants().then((tenantList) => {
        if (tenantList?.length > 0) {
          setTenants(tenantList);
          if (!currentTenant.tenantId) {
            changeTenant(tenantList[0]);
          } else if (!currentTenant.tenantName) {
            changeTenant(tenantList.find((t) => t.tenantId === currentTenant.tenantId));
          }
        } else if (!currentTenant) {
          setLockModal(true);
          setOpened(true);
        }

        if (typeof currentTenant === 'string') {
          const foundTenant = tenantList.find((t) => t.tenantId === currentTenant);
          foundTenant && changeTenant(foundTenant);
        }
      });
  }, [loading]);

  useEffect(() => {
    currentTenant === undefined && setLoading(false);
  }, [authStatus]);

  const handleChangeTenant = (tenant) => {
    localStorage.setItem('CurrentTenantId', tenant.tenantId);
    changeTenant(tenant);
  };

  const tenantButtons = tenants.map((tenant) => (
    <Tooltip label={tenant.tenantName} position="right" withArrow transitionDuration={0} key={tenant.tenantName}>
      <Box component="a" onClick={() => handleChangeTenant(tenant)} className={cx(classes.mainLink)}>
        <Avatar
          src={null}
          alt="M"
          color={tenant.color}
          variant={tenant.tenantId === currentTenant?.tenantId ? 'filled' : 'light'}
          radius="sm"
        >
          {tenant.tenantName.slice(0, 4) == 'The '
            ? tenant.tenantName.replace(/ /g, '')[3] ?? 'F'
            : tenant.tenantName.replace(/ /g, '')[0] ?? 'F'}
        </Avatar>
      </Box>
    </Tooltip>
  ));

  const links = pages.map((link) => (
    <Link
      onClick={handleClick}
      className={cx(classes.link, {
        [classes.linkActive]: link.location === firstPath,
      })}
      to={link.location}
      key={link.label}
    >
      <Center sx={{ justifyContent: 'flex-start' }}>
        {link.icon}
        <Space w="md" />
        {link.label}
      </Center>
    </Link>
  ));

  return hide ? (
    <></>
  ) : (
    <Box>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        trapFocus={lockModal}
        withCloseButton={!lockModal}
        closeOnEscape={!lockModal}
        closeOnClickOutside={!lockModal}
        size="lg"
      >
        <Welcome />
      </Modal>
      <Navbar hidden={hidden} height={`calc(100vh - 56px)`} width={width} hiddenBreakpoint="sm">
        <Navbar.Section grow className={classes.wrapper}>
          <div className={classes.aside}>
            {tenantButtons}
            <Tooltip label="Create Family" position="right" withArrow transitionDuration={0} key="Create Family">
              <UnstyledButton onClick={() => setOpened(true)} className={cx(classes.addTenant)}>
                <IconPlus stroke={1.5} />
              </UnstyledButton>
            </Tooltip>
          </div>
          <div className={classes.main}>
            <Title order={4} className={classes.title}>
              {pageTitle ? pageTitle : 'Home'}
            </Title>
            {links}
            <Space h={theme.spacing.sm} />
            <Divider />
            <Space h={theme.spacing.sm} />
            {currentTenant.appInstanceArn && <ChannelsList currentTenant={currentTenant} />}
          </div>
        </Navbar.Section>
      </Navbar>
    </Box>
  );
}

export default Sidebar;
