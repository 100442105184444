import { useEffect, useState } from 'react';
import { MantineProvider, MantineThemeOverride, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { useStore } from './index';
import { Auth } from 'aws-amplify';

declare module '@mantine/core' {
  export interface MantineThemeOther {
    updateThemeColors?: Function;
    secondaryColor: string;
  }
}

export function MantineThemeProvider({ children }) {
  const theme = useStore((state) => state.theme);
  const darkMode = theme.darkMode as ColorScheme;
  const colors = theme.colors;
  const [colorScheme, setColorScheme] = useState<ColorScheme>(darkMode);
  const [newPrimaryColor, setPrimaryColor] = useState<string>(colors.primaryColor);
  const [newSecondaryColor, setSecondaryColor] = useState<string>(colors.secondaryColor);
  const [newTheme, setNewTheme] = useState<MantineThemeOverride>({
    primaryColor: colors.primaryColor,
    other: {
      secondaryColor: newSecondaryColor,
    },
    defaultGradient: { from: colors.primaryColor, to: newSecondaryColor, deg: 30 },
    cursorType: 'pointer',
    globalStyles: (theme) => ({
      '*, *::before, *::after': {
        boxSizing: 'border-box',
      },
      colors: {},
      cursorType: 'pointer',
      body: {
        ...theme.fn.fontStyles(),
        background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        lineHeight: theme.lineHeight,
      },
    }),
  });

  const updateDarkModeAttribute = async (newValue) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:darkMode': newValue,
    });
  };

  const updateColorAttributes = async (newValues) => {
    newValues.primaryColor && setPrimaryColor(newValues.primaryColor);
    newValues.secondaryColor && setSecondaryColor(newValues.secondaryColor);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      ...(newValues.primaryColor && { 'custom:primaryColor': newValues.primaryColor }),
      ...(newValues.secondaryColor && { 'custom:secondaryColor': newValues.secondaryColor }),
    });
  };

  //TODO: use zustand action here
  const toggleColorScheme = (value?: ColorScheme) => {
    const newValue = value || (colorScheme === 'dark' ? 'light' : 'dark');
    setColorScheme(newValue);
    updateDarkModeAttribute(newValue);
  };

  useEffect(() => {
    setNewTheme((prevTheme) => {
      return {
        ...prevTheme,
        colorScheme: colorScheme,
        primaryColor: newPrimaryColor,
        primaryShade: { light: 6, dark: 8 },

        other: {
          updateThemeColors: updateColorAttributes,
          secondaryColor: newSecondaryColor,
        },
      };
    });
  }, [colors, colorScheme, newPrimaryColor, newSecondaryColor]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={newTheme}>
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
