import { useEffect, useState, ReactElement, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Center,
  Flex,
  Divider,
  createStyles,
  Modal,
  Navbar,
  UnstyledButton,
  Space,
  Tooltip,
  Title,
  Text,
  useMantineTheme,
  Indicator,
} from '@mantine/core';
import {
  IconHome2,
  IconActivityHeartbeat,
  IconDeviceDesktopAnalytics,
  IconPlus,
  IconCalendarStats,
  IconUser,
  IconMessageCircle2,
  IconCloud,
  IconPhoto,
  IconToolsKitchen2,
  IconFriends,
  IconCalendar,
} from '@tabler/icons';
import { useMediaQuery } from '@mantine/hooks';
import { useAuthenticator, Loader } from '@aws-amplify/ui-react';
import { useStore } from '../../../index';
import { useChatChannelState, useChatMessagingState } from '../providers/MessagingProvider';
import { useUserPermission } from '../providers/UserPermissionProvider';
import { mergeArrayOfObjects } from '../utilities/mergeArrays';
import { CreateChannelModal } from './components/CreateChannelModal';
import Welcome from '../../Auth/Welcome';
import { listTenants, getTenant } from '../../../api/tenant-service';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },

  aside: {
    flex: '0 0 60px',
    paddingTop: 2,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },

  mainLink: {
    margin: 2,
    width: 44,
    height: 44,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  addTenant: {
    width: 44,
    height: 44,
    display: 'flex',
    borderRadius: theme.radius.md,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2],
      cursor: 'pointer',
    },
  },

  title: {
    boxSizing: 'border-box',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.sm,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: 18,
    height: 60,
    textTransform: 'capitalize',
    borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
  },

  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 60,
    paddingTop: theme.spacing.md,
    borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]}`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: 'border-box',
    display: 'block',
    textDecoration: 'none',
    borderTopRightRadius: theme.radius.sm,
    borderBottomRightRadius: theme.radius.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: 32,
    lineHeight: '32px',
    overflow: 'hidden',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  linkActive: {
    '&, &:hover': {
      borderLeftColor: theme.fn.gradient({ from: theme.primaryColor, to: theme.other.secondaryColor, deg: 45 }),
      backgroundImage: theme.fn.gradient({ from: theme.primaryColor, to: theme.other.secondaryColor, deg: 45 }),
      color: theme.white,
    },
  },
}));

export default function ChatList({ currentTenant }) {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const currentLocation = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [user] = useStore((state) => [state.user]);
  const [activeChannelModerators, setActiveChannelModerators] = useState([]);

  // const [channels, setChannels] = useState([]);

  const {
    chime,
    channelIdChangeHandler,
    activeChannelRef,
    activeSubChannelRef,
    channelList,
    channelListRef,
    channelListModerator,
    setChannelListModerator,
    subChannelList,
    setSubChannelList,
    setChannelList,
    setActiveChannel,
    setActiveSubChannel,
    activeChannel,
    activeSubChannel,
    activeChannelMemberships,
    setActiveChannelMemberships,
    setChannelMessageToken,
    unreadChannels,
    setUnreadChannels,
    hasMembership,
    meetingInfo,
    setMeetingInfo,
    activeChannelFlow,
    setActiveChannelFlow,
    activeView,
    setActiveView,
    moderatedChannel,
    setModeratedChannel,
    subChannelIds,
    setSubChannelIds,
  } = useChatChannelState();
  const { setMessages } = useChatMessagingState();

  const handleSwichViewClick = (e) => {
    if (activeView === 'Moderator') {
      setActiveView('User');
      setActiveChannel('');
    } else {
      setActiveView('Moderator');
    }
  };

  const formatMemberships = (memArr) =>
    memArr.flatMap((m) => (m.Member.Arn !== chime.memberArn ? [{ value: m.Member.Arn, label: m.Member.Name }] : []));

  const fetchMemberships = async () => {
    const memberships = await chime.listChannelMemberships(activeChannel.ChannelArn);
    setActiveChannelMemberships(memberships);
  };

  // async function publishStatusToAllChannels() {
  //   const servicePromises = [];
  //   for (const channel of channelListRef.current) {
  //     const channelType = JSON.parse(channel.Metadata || '{}').ChannelType;
  //     if (
  //       !channel.SubChannelId &&
  //       channelType != 'PUBLIC_ELASTIC'
  //     ) {
  //       //Elastic channels doesnt support presence
  //       const status = computeAutoStatusForAChannel(channel);
  //       if (status) {
  //         servicePromises.push(
  //           chime.sendChannelMessage(
  //             channel.ChannelArn,
  //             toPresenceMessage(PresenceMode.Auto, status, true),
  //             Persistence.NON_PERSISTENT,
  //             MessageType.CONTROL,
  //             member,
  //             channel.SubChannelId
  //           )
  //         );
  //       }
  //     }
  //   }
  //   return await Promise.all(servicePromises);
  // }

  // get all channels
  useEffect(() => {
    if (!user.userId) return;
    console.log('LISTING ALL CHANNELS');
    const fetchChannels = async () => {
      if (activeView === 'User') {
        const userChannelMemberships = await chime.listChannelMembershipsForAppInstanceUser();
        const userChannelList = userChannelMemberships.map((channelMembership) => {
          console.log(channelMembership);
          const channelSummary = {
            ...channelMembership.ChannelSummary,
            ReadMarkerTimestamp: channelMembership.AppInstanceUserMembershipSummary.ReadMarkerTimestamp,
          };
          return channelSummary;
        });

        // const publicChannels = await chime.listChannels();

        // const moderatorChannels = await chime.listChannelsModeratedByAppInstanceUser();
        // const moderatorChannelList = moderatorChannels.map((channelMembership) => channelMembership.ChannelSummary);

        // const tempModeratorChannelList = [...moderatorChannelList];
        // for (const moderatorChannel of tempModeratorChannelList) {
        //   const channel = await chime.describeChannel(moderatorChannel.ChannelArn);
        //   // console.log('describing', channel.ChannelArn);
        // }

        // setChannelList(mergeArrayOfObjects(userChannelList, moderatorChannelList, 'ChannelArn'));
        setChannelList(userChannelList);
      } else {
        setModeratedChannel(activeChannel);
      }
      // await publishStatusToAllChannels();
    };
    fetchChannels();
  }, [activeView]);

  // useEffect(() => {
  //   if (authStatus !== 'authenticated') {
  //     // chime.resetAWSClients();
  //     console.clear();
  //     setActiveView('User');
  //     setActiveChannel('');
  //     setChannelList([]);
  //   }
  // }, [authStatus]);

  // get channel memberships
  useEffect(() => {
    if (activeChannel.ChannelArn) {
      activeChannelRef.current = activeChannel;
      fetchMemberships();
      // publishStatusToAllChannels();
    }
  }, [activeChannel.ChannelArn]);

  // const channelIdChangeHandler = async (channel) => {
  //   if (activeChannel.ChannelArn === channel.ChannelArn ) return;
  //   let mods = [];
  //   setActiveChannelModerators([]);

  //   var isModerator = false;
  //   const channelType = JSON.parse(channel.Metadata || '{}').ChannelType;
  //   // Moderator is for channel only, not subChannel
  //   if (!channel.SubChannelId) {
  //     try {
  //       mods = await chime.listChannelModerators(channel.ChannelArn);
  //       setActiveChannelModerators(mods);
  //     } catch (err) {
  //       if (channel.Privacy != 'PUBLIC') console.error('ERROR', err);
  //     }

  //     isModerator = mods?.find((moderator) => moderator.Moderator.Arn === chime.memberArn) || false;
  //   }
  //   // Assessing user role for given channel
  //   userPermission.setRole(isModerator ? 'moderator' : 'user');

  //   const newChannel = await chime.describeChannel(channel.ChannelArn);
  //   newChannel.SubChannelId = channel.SubChannelId;
  //   channel = newChannel;
  //   // listChannelMessages is available to regular channels and subChannels
  //   if ((!channel.ElasticChannelConfiguration && channelType != 'PUBLIC_ELASTIC') || channel.SubChannelId) {
  //     const newMessages = await chime.listChannelMessages(channel.ChannelArn);
  //     setMessages(newMessages.Messages);
  //     setChannelMessageToken(newMessages.NextToken);
  //   } else if (channelType == 'PUBLIC_ELASTIC' && !channel.SubChannelId) {
  //     setMessages([]);
  //   }
  //   setActiveChannel(channel);
  //   // await loadChannelFlow(channel);
  //   setUnreadChannels(unreadChannels.filter((c) => c !== channel.ChannelArn));
  // };

  // // track channel presence
  // useEffect(() => {
  //   if (channelList.length > 0) {
  //     channelListRef.current = channelList;
  //     startPublishStatusWithInterval();
  //   }
  // }, [channelList]);

  // useEffect(() => {
  //   if (refresh) {
  //     console.log('listing channels');
  //     chime.listChannels().then((channels) => {
  //       setRefresh(false);
  //       setChannels(channels);
  //     });
  //   }
  // }, [refresh]);

  const handleCreateChannel = () => {
    const test = chime.createChannel({ name: 'test-channel' });
    console.log(test);
  };

  const handleListChannels = () => {
    const test = chime.listChannels();
    console.log(test);
  };

  const handleChannelClick = () => {};

  const links = channelList.map((channel) => (
    <Link
      onClick={() => channelIdChangeHandler(channel)}
      className={cx(classes.link, {
        [classes.linkActive]:
          activeChannel.ChannelArn === channel.ChannelArn && window.location.pathname.startsWith('/channel/'),
      })}
      to={`/channel/${channel.ChannelArn?.split('/').slice(-1)[0]}`}
      key={channel.ChannelArn?.split('/').slice(-1)[0]}
    >
      <Flex justify="space-between" align="center" pr={theme.spacing.xs}>
        <Text inherit={true} truncate={true}>
          {channel.Name}
        </Text>

        {unreadChannels && unreadChannels.some((c) => c == channel.ChannelArn) && (
          <Indicator position="middle-end" offset={-10}>
            {' '}
          </Indicator>
        )}
      </Flex>
    </Link>
  ));

  return (
    <>
      <Flex justify="space-between" align="center" px={theme.spacing.xs}>
        <Text color="dimmed" size="sm">
          Channels
        </Text>
        <CreateChannelModal chime={chime} setRefresh={setRefresh} />
      </Flex>
      <Box maw={235}>{links}</Box>
    </>
  );
}
