import { v4 as uuid } from 'uuid';
import { ChimeSDKMessagingClient } from '@aws-sdk/client-chime-sdk-messaging';
import {
  LogLevel,
  ConsoleLogger,
  DefaultMessagingSession,
  MessagingSessionConfiguration,
  PrefetchOn,
  PrefetchSortBy,
} from 'amazon-chime-sdk-js';

import { ChimeApi } from '../../../api/chime-service';

class MessagingService {
  protected _endpoint: string;
  protected _session: DefaultMessagingSession;
  protected sessionId: string;
  protected _logger: ConsoleLogger;
  protected _messageUpdateCallbacks: Function[];
  protected chime: ChimeApi;
  protected _client: Promise<ChimeSDKMessagingClient>;

  constructor(chime: ChimeApi) {
    this.sessionId = uuid();
    this.chime = chime;
    this._logger = new ConsoleLogger('SDK Chat Demo', LogLevel.INFO);
    this._messageUpdateCallbacks = [];
    this._client = chime.appInstanceArn ? chime.chimeMessagingClient() : undefined;
  }

  messageObserver = {
    messagingSessionDidStart: () => {
      console.log('Messaging Connection started!');
    },
    messagingSessionDidStartConnecting: (reconnecting) => {
      console.log('Messaging Connection connecting');
    },
    messagingSessionDidStop: (event) => {
      console.log('Messaging Connection received DidStop event');
    },
    messagingSessionDidReceiveMessage: (message) => {
      console.log('Messaging Connection received message');
      this.publishMessageUpdate(message);
    },
  };

  setMessagingEndpoint(member) {
    this.chime
      .getMessagingSessionEndpoint()
      .then(async (response) => {
        console.log(response);
        this._endpoint = response?.Endpoint?.Url;

        const sessionConfig = new MessagingSessionConfiguration(
          member,
          this.sessionId,
          this._endpoint,
          await this._client
        );
        sessionConfig.prefetchOn = PrefetchOn.Connect;
        console.log('session', sessionConfig);

        this._session = new DefaultMessagingSession(sessionConfig, this._logger);

        this._session.addObserver(this.messageObserver);
        try {
          console.log('starting session');
          this._session.start();
          console.log('session started');
        } catch (error) {
          console.log("couldn't start session");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  connect(member) {
    console.log('connecting');
    this.setMessagingEndpoint(member);
  }

  close() {
    try {
      this._session.stop();
    } catch (err) {
      console.error('Failed to stop Messaging Session.');
    }
  }

  subscribeToMessageUpdate(callback) {
    console.log('Message listener subscribed!');
    this._messageUpdateCallbacks.push(callback);
  }

  unsubscribeFromMessageUpdate(callback) {
    const index = this._messageUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      this._messageUpdateCallbacks.splice(index, 1);
    }
  }

  publishMessageUpdate(message) {
    console.log('Sending message update to listeners!');
    for (let i = 0; i < this._messageUpdateCallbacks.length; i += 1) {
      const callback = this._messageUpdateCallbacks[i];
      callback(message);
    }
  }
}

export default MessagingService;
