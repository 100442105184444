import { useAuthenticator, Loader } from '@aws-amplify/ui-react';
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// TODO: Update auth guarding to be better (weird routing stuff)

const AuthGuard: React.FC = () => {
  const location = useLocation();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  return (
    <>
      {authStatus === 'configuring' && <Loader />}
      {authStatus !== 'authenticated' ? <Navigate to="/login" state={{ from: location }} replace /> : <Outlet />}
    </>
  );
};

export default AuthGuard;
