import { API } from 'aws-amplify';
import { apiName, headers } from './api-config';
import axios from 'axios';

export async function uploadToS3({
  tenantId,
  fileType,
  fileContents,
  profile = false,
}: {
  tenantId: string;
  fileType: string;
  fileContents: File;
  profile: boolean;
}) {
  const presignedPostUrl = await getPresignedPostUrl(tenantId, fileType, profile);

  const formData = new FormData();
  formData.append('Content-Type', fileType);
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v as string);
  });
  formData.append('file', fileContents); // The file has be the last element

  const response = await axios.post<PresignedPostUrlResponse>(presignedPostUrl.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return presignedPostUrl.filePath;
}

async function getPresignedPostUrl(tenantId: string, fileType: string, profile: boolean) {
  const presignedPostUrl = await API.get(
    apiName,
    `get-presigned-url-s3?tenantId=${tenantId}&fileType=${fileType}&profile=${profile}`,
    {}
  );
  return presignedPostUrl;
}

interface PresignedPostUrlResponse {
  url: string;
  fields: PresignedPostFields;
  filePath: string;
}

interface PresignedPostFields {
  key: string;
  acl: string;
  bucket: string;
}
